import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { Button } from '@mui/material';
import ArgonBox from 'components/ArgonBox';
import Grid from "@mui/material/Grid";
import "../../style.css"



const AudioPlayer = ({ song, handlePlayPause, isPlaying, id }) => {
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        const audioElement = audioRef.current;

        const updateTime = () => {
            setCurrentTime(audioElement.currentTime);
        };

        const updateDuration = () => {
            setDuration(audioElement.duration);
        };

        audioElement.addEventListener("timeupdate", updateTime);
        audioElement.addEventListener("loadedmetadata", updateDuration);

        return () => {
            audioElement.removeEventListener("timeupdate", updateTime);
            audioElement.removeEventListener("loadedmetadata", updateDuration);
        };
    }, []);

    const handlePlayPauseWrapper = () => {
        handlePlayPause(id);
    };

    const handleTimeChange = (e) => {
        const newTime = e.target.value;
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying]);

    return (
        <Grid container spacing={2} alignItems="center">
            <audio ref={audioRef}>
                <source src={song} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>


            <Grid item>
                <Button
                    sx={{
                        "& .MuiSvgIcon-root": {
                            fontSize: "1.5rem !important",
                        },
                    }}
                    onClick={handlePlayPauseWrapper}
                >
                    {isPlaying ? <PauseCircleIcon /> : <PlayCircleIcon />}
                </Button>
            </Grid>
            <Grid item>
                <span>{formatTime(currentTime)}</span>
            </Grid>
            <Grid item xs>
                <input
                    type="range"
                    value={currentTime}
                    max={duration}
                    onChange={handleTimeChange}

                />
            </Grid>
            <Grid item>
                <span>{formatTime(duration)}</span>
            </Grid>
        </Grid>

    );
};

AudioPlayer.propTypes = {
    song: PropTypes.any,
    handlePlayPause: PropTypes.func,
    isPlaying: PropTypes.bool,
    id: PropTypes.any

};

const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

export default AudioPlayer;