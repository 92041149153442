import { useDispatch } from "react-redux";
import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useNoteService = () => {
  const axiosRequest = useBaseService().axiosRequest();

  const create = async (data, id) => {
    try {
      const response = await axiosRequest.post(`administrator_apis/clinic_notes?clinic_id=${id}&note=${data}`);
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const destroy = async (id) => {
    try {
      const response = await axiosRequest.delete(`administrator_apis/clinic_notes/${id}`);
      return response;
    } catch (error) {
      return error.response.data.error;
    }
  };

  const update = async (data, id) => {
    try {
      const response = await axiosRequest.put(`administrator_apis/clinic_notes/${id}`, data);
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false; // You can handle the error as needed in the calling code.
    }
  };

  return {
    destroy,
    update,
    create,
  };
};

export default useNoteService;
