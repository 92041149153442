import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";

import PatientForm from "blikol-layouts/clinic/patients/components/Form/patientForm";


function AddPatient() {
    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar customRoutePath={"Patient / Add New Patient"} />
            <PatientForm />
        </DashboardLayout>
    )
}

export default AddPatient