import PropTypes from "prop-types";

import ArgonTypography from "components/ArgonTypography";
import React, { useState, useEffect } from "react";
import { DEFAULT_FREQUENCY_TIMER } from "constants";
const CountdownTimer = ({ frequencyDuration }) => {
  const [timeRemaining, setTimeRemaining] = useState(frequencyDuration);

  useEffect(() => {
    if (timeRemaining) {
      const intervalId = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(intervalId);
            return 0;
          }
          return prevTime - 10; // decrease by 10 milliseconds
        });
      }, 10);

      return () => clearInterval(intervalId);
    }
  }, []);

  const formattedTime = (time) => {
    const seconds = Math.floor(time / 1000);
    const milliseconds = time % 1000;
    return `${!seconds ? "0" : seconds}.${!milliseconds ? "0" : milliseconds.toString().charAt(0)}`;
  };

  return <ArgonTypography fontWeight="bold">{formattedTime(timeRemaining)}</ArgonTypography>;
};

export default CountdownTimer;

CountdownTimer.defaultProps = {
  timeRemaining: 0,
  frequencyDuration: DEFAULT_FREQUENCY_TIMER,
  resetTimer: false,
};

CountdownTimer.propTypes = {
  resetTimer: PropTypes.bool,
  timeRemaining: PropTypes.number,
  frequencyDuration: PropTypes.frequencyDuration,

  setTimeRemaining: PropTypes.func,
  resetTimerHandler: PropTypes.func,
};
