import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import ProgressBar from "components/ProgressBar/ProgressBar";
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";
import ArgonSelect from "components/ArgonSelect";
import ArgonTypography from "components/ArgonTypography";
import useMusicService from "service-hooks/useMusicService";
import DeleteIcon from "@mui/icons-material/Delete";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import "../style.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const style = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "575px",
  height: "365px",
  padding: "24px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  margin: "auto",
  borderRadius: "15px",
  background: "#FFF",
};

export default function ConfirmModal({ open, handleConfirmClose, audio, setAudio, data, setData }) {
  const [typesList, setTypesList] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [uploadData, setUploadData] = useState({ _type: "", file: null });
  const [selectError, setSelectError] = useState(false);
  const musicHookService = useMusicService();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth?.user?.role);

  const handleUpload = () => {
    if (!uploadData._type || !uploadData.file) {
      toast.error("Please select music type");
      setSelectError(true);
      return;
    }
    setSelectError(false);
    setSpinner(true);
    const formData = new FormData();
    formData.append("_type", uploadData._type);
    formData.append("file", uploadData.file);

    musicHookService
      .create(formData)
      .then((response) => {
        const musicObject = response?.data;
        setData((prevData) => {
          return {
            musicData: [musicObject, ...prevData.musicData],
            musicMeta: { ...prevData.musciMeta },
          };
        });
        toast.success("Music uploaded successfully");

      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
        navigate(`/${userRole}/musics`);
      })
      .finally(() => {
        setSpinner(false);
        handleConfirmClose();
        setAudio();
        setUploadData({});
      });
  };

  useEffect(() => {
    musicHookService
      .types()
      .then((result) => {
        if (result) {
          setTypesList(result.types.data);
        } else {
          console.log("Data is undefined or incomplete.");
        }
      })
      .catch((error) => {
        console.error("Error fetching music type data:", error);
      });
  }, [open]);

  useEffect(() => {
    setUploadData((prevData) => ({ ...prevData, file: audio }));
  }, [audio]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleConfirmClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {spinner && <ProgressBar />}
          <ArgonBox
            sx={{
              marginBottom: "25px",
            }}
          >
            <ArgonTypography
              component="label"
              variant="caption"
              sx={{
                fontSize: "20px",
                lineHeight: "28px",
                color: "var(--Wireframes-Primary, var(--Body, #373D3F)) !important",
                fontStyle: "normal",
                fontWeight: "600 !important",
              }}
            >
              Upload Music
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox
            sx={{
              width: "382px",
            }}
          >
            <ArgonTypography
              sx={{
                color: "#172B4D",
                fontSize: "small",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "150%",
              }}
            >
              Music Type
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox my={2} sx={{ width: "382px" }}>
            <ArgonSelect
              id={"musicTypes"}
              size="medium"
              sx={{
                justifyContent: "center",
                borderRadius: "6px !important",
                flexDirection: "column",
                alignContent: "center",
              }}
              error={selectError}
              options={typesList.map((option) => ({ label: option, value: option }))}
              placeholder={"Select Type"}
              onChange={({ value }) => {
                setSelectedValue(value);
                setUploadData((prevData) => ({ ...prevData, _type: value }));
                setSelectError(false);
              }}
            />
            {selectError && (
              <Typography variant="caption" color="error">
                Please select a music type
              </Typography>
            )}
          </ArgonBox>
          <ArgonBox
            sx={{
              width: "391px",
              backgroundColor: "#F4F2F2;",
              borderRadius: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "25px",
            }}
          >
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <AudioFileIcon color="info" sx={{ marginLeft: "3px", marginTop: "5px" }} />
                  </td>
                  <td>
                    <ArgonBox
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "40px",
                        marginRight: "6.5px",
                        marginLeft: "2.5px",
                        marginBottom: "3px",
                      }}
                    >
                      <ArgonTypography
                        sx={{
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400 !important",
                          lineHeight: "normal",
                          color: "#141416",
                        }}
                      >
                        {audio?.name}
                      </ArgonTypography>
                    </ArgonBox>
                  </td>
                  <td>
                    <div className="progress-bar-container"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </ArgonBox>

          <ArgonBox
            sx={{
              marginTop: "35px",
              marginBottom: spinner ? "20px" : "0px", // Add margin-bottom if spinner is true
            }}
          >
            <ArgonButton
              variant="outlined"
              color="info"
              sx={{
                borderRadius: "4px",
                margin: "0px 16px 0px 16px",
                width: "124px",
                fontSize: "small",
              }}
              onClick={() => {
                handleConfirmClose();
                setAudio();
                setSelectError(false)
              }}
            >
              Cancel
            </ArgonButton>

            <ArgonButton
              variant="contained"
              color="info"
              sx={{
                borderRadius: "4px",
                width: "124px",
                fontSize: "small",
              }}
              onClick={handleUpload}
            >
              Upload
            </ArgonButton>
          </ArgonBox>
        </Box>
      </Modal>
    </div>
  );
}
ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleConfirmClose: PropTypes.func.isRequired,
  audio: PropTypes.any.isRequired,
  setAudio: PropTypes.any.isRequired,
  data: PropTypes.any,
  setData: PropTypes.any,
};
