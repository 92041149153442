import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PersonIcon from "assets/images/icons/patients/person-icon.svg"
import usePatientsService from "service-hooks/usePatientsService";
import Spinner from "../../../components/Spinner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import ArgonButton from "components/ArgonButton";
import "../../style.css"
import PersonalInfo from "blikol-layouts/components/PatientDetail/PersonalInfo";
import Header from "../Header"
import PatientDiagnosis from "blikol-layouts/components/PatientDetail/PatientDiagnosis";
import SessionHistory from "blikol-layouts/components/PatientDetail/SessionHistory";
import useFeedbackService from 'service-hooks/useFeedbackService';


function ViewPatient() {
    const navigate = useNavigate();
    const patientHookService = usePatientsService();
    const [loading, setLoading] = useState(true);
    const userRole = useSelector((state) => state.auth?.user?.role);
    const [patientId, setPatientId] = useState(useParams().id);
    const [currentFrequency, setCurrentFrequency] = useState()
    const [tabVal, setTabVal] = useState("Patient Diagnosis")
    const [sessionClick, setSessionClick] = useState(false);
    const [viewPatientDignosis, setviewPatientDignosis] = useState(true);
    const [sessionFrequency, setSessionFrequency] = useState()
    const [sessionDuration, setSessionDuration] = useState()
    const [patientData, setPatientData] = useState()
    const feedbackHookService = useFeedbackService()
    const [feedbacks, setFeedbacks] = useState()


    useEffect(() => {
        patientHookService
            .patient(patientId)
            .then((result) => {
                if (result) {
                    const patient = result.data;
                    setLoading(false);
                    setPatientId(patient?.id);
                    setPatientData(patient)
                    setCurrentFrequency(patient?.current_frequency)
                } else {
                    toast.error("No Patient with this id");
                    navigate(`/${userRole}/patients`);
                }
            })
            .catch((error) => {
                console.error("Error fetching user data and attributes:", error);
            });
    }, []);

    const handleTabChange = (selectedTab) => {
        setTabVal(selectedTab);
    };

    const fieldNameStyle = {
        color: 'var(--Label, #8392AB)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }

    const fieldValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        textTransform: "capitalize",

    }

    const fieldEmailValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }

    const tabStyle = {
        fontSize: "14px !important",
        borderRadius: "4px",
        padding: "0px 8px",
        height: "35px"
    }

    function frequencyFormat(value) {
        return value !== null ? `${value} Hz` : "Pending Diagnosis";
    }


    const handleSessionHistoryClick = (id, duration, frequency) => {
        setviewPatientDignosis(false)
        setSessionClick(true);
        setSessionDuration(duration)
        setSessionFrequency(frequency)
        feedbackHookService
            .feedbacks(id)
            .then((result) => {
                if (result) {
                    setFeedbacks(result)
                } else {
                    toast.error("No feedbacks found with this id");
                    navigate(`/${userRole}/patients`);
                }
            })
            .catch((error) => {
                console.error("Error fetching user data and attributes:", error);
            });

    };

    const handleBackToPatientDiagnosisClick = () => {
        setviewPatientDignosis(true)
        setSessionClick(false);
    };



    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar customRoutePath={"Patients / Patient Detail"} />
            <ArgonBox px={3} pt={3}>
                <ArgonBox
                    sx={{ display: "flex", justifyContent: "space-between" }}
                >
                    <ArgonTypography
                        mb={3}
                        mt={4}
                        sx={{
                            color: "var(--Default, #172B4D)",
                            fontSize: "25px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "137%",
                            letterSpacing: "-0.8px",
                        }}
                    >
                        Patient Details
                    </ArgonTypography>
                    <ArgonTypography
                        mb={3}
                        mt={4}
                        sx={{
                            color: 'var(--Label, #8392AB)',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                        }}
                    >
                        Current Frequency:
                        <span className="current-frequency-value">
                            {frequencyFormat(currentFrequency)}
                        </span>
                    </ArgonTypography>
                </ArgonBox>
                <Grid container spacing={3}>
                    <PersonalInfo patientData={patientData} />
                    <Grid item xs={12} sm={8}>
                        <Card>
                            {viewPatientDignosis && <ArgonBox sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                                py={5}
                                px={4}
                                className="parent"
                            >
                                <ArgonTypography
                                    sx={{
                                        color: 'var(--Default, #172B4D)',
                                        fontFamily: 'Open Sans',
                                        fontSize: '25px',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        lineHeight: '137%', // Converted from "137%"
                                        letterSpacing: '-0.8px',
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                    className="header"
                                >
                                    Patient Diagnosis
                                </ArgonTypography>
                            </ArgonBox>}
                            {viewPatientDignosis && (<PatientDiagnosis
                                sessionClick={sessionClick}
                                onSessionHistoryClick={handleSessionHistoryClick}
                                patientData={patientData}
                            />)}
                            {sessionClick && (<SessionHistory
                                onBackButtonClick={handleBackToPatientDiagnosisClick}
                                duration={sessionDuration}
                                frequency={sessionFrequency}
                                feedbacks={feedbacks}
                            />)}
                        </Card>
                    </Grid>

                </Grid>

            </ArgonBox>

        </DashboardLayout >
    )
}

export default ViewPatient