export const CREDIT_CARD_FORMAT = ".... .... ....";

export const FREQUENCY_TEST = {
  initial: "INITIAL_TEST",
  zooming: "ZOOMING_TEST",
  fineTuning: "FINE_TUNING_TEST",
  secondTest: "SECOND_TEST",
};

export const FREQUENCY_CONTROLS_TYPE = {
  lower: "LOWER",
  higher: "HIGHER",
};

export const FREQUENCY_BAR_COLORS = {
  default: "#dff5fb",
  initialTest: "#dff5fb",
  zoomingTest: "#f9dfda",
  fineTuningTest: "#fdecd6",
  secondTest: "#dff5fb",
};

export const DEFAULT_FREQUENCY_TIMER = 3 * 1000;

export const DEFAULT_PERCENTAGE_ERROR = 15;
