import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ArgonBox from "components/ArgonBox";
import ArgonInput from "components/ArgonInput";

import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";

const style = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
  padding: "30px 100px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  margin: "auto",
  borderRadius: "15px",
  background: "#FFF",
};

export default function TextAreaModal({
  modalOpen,
  setModalOpen,
  headerContent,
  subHeaderContent,
  inputLabel,
  placeholder,
  confirmationColor,
  confirmationBtnText,
  onConfirm,
  inputRequiredText,
  inputRequiredTextDisplay,
  setInputValue,
}) {
  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={modalOpen}>
        <Box sx={style}>
          <ArgonBox
            sx={{
              width: "100%",
            }}
          >
            <ArgonBox
              sx={{
                textAlign: "center",
              }}
            >
              <ArgonTypography fontWeight={"medium"}>{headerContent}</ArgonTypography>
              <ArgonTypography
                sx={{
                  fontSize: "15px",
                  marginTop: "15px",
                }}
                fontWeight={"Light"}
              >
                {subHeaderContent}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox>
              <ArgonTypography
                sx={{
                  fontSize: "14px",
                  marginTop: "20px",
                }}
              >
                {inputLabel}
              </ArgonTypography>
              <ArgonInput
                multiline
                rows={5}
                placeholder={placeholder}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <ArgonTypography
                sx={{
                  fontSize: "14px",
                  marginTop: "10px",
                  color: "red",
                  display: inputRequiredTextDisplay,
                }}
              >
                {inputRequiredText}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>

          <ArgonBox
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }}
            gap="1rem"
          >
            <ArgonButton
              sx={{ padding: "10px 35px", borderRadius: "3px" }}
              color="info"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </ArgonButton>
            <ArgonButton
              sx={{ padding: "10px 35px", borderRadius: "3px" }}
              color={confirmationColor}
              onClick={onConfirm}
            >
              {confirmationBtnText}
            </ArgonButton>
          </ArgonBox>
        </Box>
      </Fade>
    </Modal>
  );
}

TextAreaModal.propTypes = {
  modalOpen: PropTypes.bool,
  subHeaderContent: PropTypes.string,
  headerContent: PropTypes.string,
  placeholder: PropTypes.string,
  inputLabel: PropTypes.string,
  setModalOpen: PropTypes.func,
  confirmationColor: PropTypes.string,
  inputRequiredText: PropTypes.string,
  confirmationBtnText: PropTypes.string,
  onConfirm: PropTypes.func,
  setInputValue: PropTypes.func,
  inputRequiredTextDisplay: PropTypes.string,
};
