import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import ProgressBar from "components/ProgressBar/ProgressBar";
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ArgonInput from "components/ArgonInput";
import "../../style.css"
import useClinicService from "service-hooks/useClinicService";
import { Grid } from "swiper";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "blikol-layouts/components/Spinner";
import { formatTimestamp } from "parsers/formatParser";
const style = {
    display: "flex",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    padding: "35px 35px 23px 35px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "20px",
    background: "#FFF",
};

// Import statements remain unchanged...

export default function ViewNotes({ open, data, onClose, handleEditNote }) {
    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate();
    const userRole = useSelector((state) => state.auth?.user?.role);
    const clinicHookService = useClinicService();

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            color: "#172B4D",
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon sx={{ fontSize: "18px !important" }} />
                    </IconButton>
                    {spinner && <ProgressBar />}

                    {
                        data?.map((note, index) => (
                            <ArgonBox key={index}
                                sx={{
                                    borderBottom: index !== (data.length - 1) ? "1px solid #E7EAEE" : "",
                                    width: "391px",

                                }}
                                mb={1.5}
                                pb={1.5}
                            >
                                <ArgonBox
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <ArgonTypography
                                        sx={{
                                            color: "#172B4D",
                                            fontSize: 14,
                                            fontWeight: 700,
                                            textTransform: "capitalize"
                                        }}
                                        py={1}
                                    >
                                        {note?._type}
                                    </ArgonTypography>
                                    {(userRole === "admin" || userRole === "super_admin") && (
                                        note._type === "admin" && (
                                            <ArgonButton variant="text" onClick={handleEditNote}>
                                                <ArgonBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <EditIcon sx={{ color: "black" }} />
                                                    <ArgonTypography
                                                        sx={{
                                                            color: "var(--Default, #172B4D)",
                                                            fontFamily: "Roboto",
                                                            fontSize: 12,
                                                            fontWeight: 700,
                                                        }}
                                                        px={0.8}
                                                    >
                                                        EDIT
                                                    </ArgonTypography>
                                                </ArgonBox>
                                            </ArgonButton>
                                        )
                                    )}
                                </ArgonBox>
                                <ArgonTypography
                                    sx={{
                                        color: "var(--Default, #172B4D)",
                                        fontSize: 12,
                                        fontWeight: 400,
                                    }}
                                    pb={1.5}

                                >
                                    {formatTimestamp(note?.created_at)}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={{
                                        color: "var(--Text, #1A1D1F)",
                                        fontSize: 12,
                                        fontWeight: 400,
                                    }}
                                >
                                    {note?.note}
                                </ArgonTypography>
                            </ArgonBox>

                        ))}

                </Box>

            </Modal>
        </div>
    );
}

ViewNotes.defaultProps = {
    open: false,
};
ViewNotes.propTypes = {
    open: PropTypes.bool.isRequired,
    data: PropTypes.any,
    onClose: PropTypes.func,
    handleEditNote: PropTypes.func
};
