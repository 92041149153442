import { useEffect, useState } from "react";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import Grid from "@mui/material/Grid";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import usePaymentMethodService from "service-hooks/clinic/usePaymentMethodService";
import Spinner from "blikol-layouts/components/Spinner";
import PaymentSetupForm from "../../components/StripePaymentMethodModal/components/paymentSetupForm";
import "utils/i18n"
import { useTranslation } from "react-i18next";

function AddPaymentMethod() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [publishableKey, setPublishableKey] = useState("");
  const paymentMethodHookService = usePaymentMethodService();
  const stripePromise = publishableKey ? loadStripe(publishableKey) : "";
  const { t } = useTranslation()

  useEffect(() => {
    paymentMethodHookService
      .setupIntent()
      .then((result) => {
        if (result) {
          setLoading(false);
          setPublishableKey(result.publishable_key);
          setClientSecret(result.client_secret);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <DashboardLayout colorBgHeight={"110px"}>
      <DashboardNavbar customRoutePath={"New Payment Method"} />
      <ArgonTypography
        mb={3}
        mt={4}
        sx={{
          color: "var(--Default, #172B4D)",
          fontSize: "25px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "137%",
          letterSpacing: "-0.8px",
        }}
      >
        {t("Payment Method")}
      </ArgonTypography>
      <ArgonTypography
        sx={{
          color: "#000",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "150%",
        }}
        mb={4}
      >
        {t("Manage your stored cards for a more seamless shopping experience. Your card details are secured and encrypted by Stripe")}
      </ArgonTypography>
      <Card sx={{ padding: "20px" }}>
        {loading && <Spinner />}
        {processing && <Spinner height={"7vh"} />}
        {!loading && (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret,
              appearance: {
                theme: "minimal",
                variables: {
                  fontFamily: "Open Sans, system-ui, sans-serif",
                },
              },
            }}
          >
            <PaymentSetupForm
              setProcessing={setProcessing}
              redirectionUrl={window.location.origin + "/clinic/payment-methods"}
              saveButtonText={t("Add")}
            />
          </Elements>
        )}
      </Card>
    </DashboardLayout>
  );
}

export default AddPaymentMethod;
