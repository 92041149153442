// Settings page components
// import BaseLayout from "layouts/pages/account/components/BaseLayout";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ClinicForm from "blikol-layouts/clinics/components/clinicForm";
import useClinicService from "service-hooks/useClinicService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";

function AddClinic() {
  const navigate = useNavigate();
  const clinicHookService = useClinicService();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.auth?.user?.role);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  const handleSubmit = (values) => {
    clinicHookService
      .create(values)
      .then((result) => {
        if (result) {
          setIsAddButtonDisabled(true);
          toast.success("Clinic created successfully.");
          navigate(`/${userRole}/clinics`);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.error);
        navigate(`/${userRole}/clinics`);
      });
  };

  return (
    <DashboardLayout colorBgHeight={"110px"}>
      <DashboardNavbar customRoutePath={"Clinics / Add New Clinic"} />
      <ClinicForm handleSubmit={handleSubmit} heading={"Add New Clinic"} buttonText={"Add"} buttonDisabled={isAddButtonDisabled} />
    </DashboardLayout>
  );
}

export default AddClinic;
