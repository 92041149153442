import { useDispatch } from "react-redux";
import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useSettingService = () => {
  const dispatch = useDispatch();
  const axiosRequest = useBaseService().axiosRequest();

  const settings = async () => {
    try {
      const response = await axiosRequest.get("/administrator_apis/settings/data");
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const update = async (settingsData) => {
    try {
      const response = await axiosRequest.patch(
        "/administrator_apis/settings/change",
        settingsData
      );
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  return {
    settings,
    update,
  };
};

export default useSettingService;
