// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

function Rating({ points, color }) {
    const starColor = color;
    const ratings = {
        0: [
            <Icon key={1} style={{ color: starColor }}>star_outline</Icon>,
            <Icon key={2} style={{ color: starColor }}>star_outline</Icon>,
            <Icon key={3} style={{ color: starColor }}>star_outline</Icon>,
            <Icon key={4} style={{ color: starColor }}>star_outline</Icon>,
            <Icon key={5} style={{ color: starColor }}>star_outline</Icon>,
        ],
        1: [
            <Icon key={1} style={{ color: starColor }}>star</Icon>,
            <Icon key={2} style={{ color: starColor }}>star_outline</Icon>,
            <Icon key={3} style={{ color: starColor }}>star_outline</Icon>,
            <Icon key={4} style={{ color: starColor }}>star_outline</Icon>,
            <Icon key={5} style={{ color: starColor }}>star_outline</Icon>,
        ],
        2: [
            <Icon key={1} style={{ color: starColor }}>star</Icon>,
            <Icon key={2} style={{ color: starColor }}>star</Icon>,
            <Icon key={3} style={{ color: starColor }}>star_outline</Icon>,
            <Icon key={4} style={{ color: starColor }}>star_outline</Icon>,
            <Icon key={5} style={{ color: starColor }}>star_outline</Icon>,
        ],
        3: [
            <Icon key={1} style={{ color: starColor }}>star</Icon>,
            <Icon key={2} style={{ color: starColor }}>star</Icon>,
            <Icon key={3} style={{ color: starColor }}>star</Icon>,
            <Icon key={4} style={{ color: starColor }}>star_outline</Icon>,
            <Icon key={5} style={{ color: starColor }}>star_outline</Icon>,
        ],
        4: [
            <Icon key={1} style={{ color: starColor }}>star</Icon>,
            <Icon key={2} style={{ color: starColor }}>star</Icon>,
            <Icon key={3} style={{ color: starColor }}>star</Icon>,
            <Icon key={4} style={{ color: starColor }}>star</Icon>,
            <Icon key={5} style={{ color: starColor }}>star_outline</Icon>,
        ],
        5: [
            <Icon key={1} style={{ color: starColor }}>star</Icon>,
            <Icon key={2} style={{ color: starColor }}>star</Icon>,
            <Icon key={3} style={{ color: starColor }}>star</Icon>,
            <Icon key={4} style={{ color: starColor }}>star</Icon>,
            <Icon key={5} style={{ color: starColor }}>star</Icon>,
        ],
    };

    return (
        <ArgonBox>
            <ArgonBox component="li" display="flex" flexDirection="column" mt={0.5}>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
                    <ArgonBox
                        sx={({ functions: { pxToRem }, palette: { text } }) => ({
                            fontSize: pxToRem(24),
                            lineHeight: 0,
                            color: text.main,
                        })}
                    >
                        {ratings[points]}

                    </ArgonBox>
                </ArgonBox>
            </ArgonBox>
        </ArgonBox>

    );
}

// Setting default values for the props of TeamProfileCard
Rating.defaultProps = {
    points: 0,
    color: "#F2C62C"
};

// Typechecking props for the TeamProfileCard
Rating.propTypes = {
    points: PropTypes.number.isRequired,
    color: PropTypes.string
};

export default Rating;
