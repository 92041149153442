import React from "react";
//components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import DataTable from "examples/Tables/DataTable";
import Card from "@mui/material/Card";
import Header from "./components/Header";
import Spinner from "blikol-layouts/components/Spinner";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ActionMenu from "blikol-layouts/components/ActionMenu";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import usePatientsService from "../../../service-hooks/clinic/usePatientsService";
import { formatName, formatEmail } from "parsers/formatParser"
import { formatDate, countryToLocale } from "blikol-layouts/clinic/parsers/dateFormatParser"
import { useTranslation } from "react-i18next";
import "utils/i18n"

function patients() {
  const { user, token } = useSelector((state) => state.auth);
  const userRole = user?.role;
  const userCountry = user?.country
  const userCredits = user?.credits;
  const [data, setData] = useState({ patientsData: [] });
  const navigate = useNavigate();
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [search, setSearch] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [totalEntries, setTotalEntries] = useState();
  const [totalPages, setTotalPages] = useState();
  const [pageRefresh, setPageRefresh] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const patientsHookService = usePatientsService();
  const [loading, setLoading] = useState(true);
  const [listTab, setListTab] = useState();
  const [status, setStatus] = useState("active");
  const { t, i18n } = useTranslation()

  useEffect(() => {
    patientsHookService
      .patients(
        pageNo,
        entriesPerPage,
        selectedOptions["gender"],
        search,
        selectedOptions["tinnitus_type"],
        status,
      )
      .then((result) => {
        if (result && result.patientsData && result.patientsMeta) {
          setData(result);
          setLoading(false);
          setTotalEntries(result?.patientsMeta?.total_count);
          setTotalPages(result?.patientsMeta?.total_pages);
        } else {
          toast.error(t("Data is undefined or incomplete."));
        }
      })
      .catch((error) => {
        // Handle the error (e.g., display an error message)
        toast.error(t("Error fetching user data and attributes:"), error);
      });
  }, [
    search,
    entriesPerPage,
    pageNo,
    pageRefresh,
    selectedOptions["gender"],
    selectedOptions["tinnitus_type"],
    search,
    status,
  ]);

  function lowerFirstLetter(word) {
    // Check if the input is not an empty string
    if (typeof word !== "string" || word.length === 0) {
      return word; // Return the input unchanged
    }

    // Lowercase the first letter and concatenate it with the rest of the word
    return word.charAt(0).toLowerCase() + word.slice(1);
  }

  const handleTabChange = (selectedTab) => {
    selectedTab = lowerFirstLetter(selectedTab);
    setStatus(selectedTab);
    setPageNo(1)
  };

  const handleDeactivate = async (id) => {
    try {
      const existingPatientResponse = await patientsHookService.patient(id);
      if (existingPatientResponse && existingPatientResponse.data) {
        const existingPatient = existingPatientResponse.data;

        const updatedPatientData = { ...existingPatient, status: "inactive" };
        const updateResponse = await patientsHookService.update(updatedPatientData, id);
        if (updateResponse) {
          toast.success(t("Patient deactivated successfully"));

          setPageRefresh(!pageRefresh);
        } else {
          toast.error(t("Error deactivating patient"))
        }
      } else {
        toast.error(t("Patient not found"));
      }
    } catch (error) {
      toast.error(t("Error deactivating patient"));
    }
  };
  const handleReactivate = async (id) => {
    try {
      const existingPatientResponse = await patientsHookService.patient(id);
      if (existingPatientResponse && existingPatientResponse.data) {
        const existingPatient = existingPatientResponse.data;

        const updatedPatientData = { ...existingPatient, status: "active" };
        const updateResponse = await patientsHookService.update(updatedPatientData, id);
        if (updateResponse) {
          toast.success(t("Patient Re-activated successfully"));

          setPageRefresh(!pageRefresh);
        } else {
          toast.error(t("Error Re-activating patient"))
        }
      } else {
        toast.error(t("Patient not found"))
      }
    } catch (error) {
      toast.error(t("Error Reactivating patient"));
    }
  };

  const handleAddPatient = (id) => {
    if (userCredits > 0) {
      navigate(`/${userRole}/patients/new`);
    } else {
      navigate(`/${userRole}/patients/no_credits`);
    }
  };

  const handleEdit = (id) => navigate(`/${userRole}/patients/edit/${id}`);

  const handleView = (id) => {
    navigate(`/${userRole}/patients/view/${id}`);
  };

  const handleResendInvitation = (id) => {
    patientsHookService
      .resendInvitation(id)
      .then((result) => {
        toast.success(t("Credentials resent successfully."));
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const filters = [
    {
      name: "Gender",
      action: (selectedValue) => handleFilterChange("gender", selectedValue),
      listOfOptions: ["male", "female", "Other"],
    },
    {
      name: "Tinnitus Type",
      action: (selectedValue) => handleFilterChange("tinnitus_type", selectedValue),
      listOfOptions: ["tonal", "complex_noise", "other"],
    },
  ];

  const threeDotsPending = [
    {
      type: t("Resend Invitation"),
      action: handleResendInvitation,
    },
    {
      type: t("Edit"),
      action: handleEdit,
    },
  ];

  const threeDotsActive = [
    {
      type: t("Edit"),
      action: handleEdit,
    },
    {
      type: t("Deactivate"),
      action: handleDeactivate,
      style: {
        color: "red",
      },
    },
  ];

  const threeDotsInactive = [
    {
      type: t("Reactivate"),
      action: handleReactivate,
    },
  ];

  const handleFilterChange = (filterName, selectedValue) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [filterName]: selectedValue,
    }));
  };

  const tableCell = (option) => {
    // Remove underscores and dashes, capitalize each word, and join with space
    return option
      .replace(/[_-]/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const patientsRowsData = data.patientsData.map((patient, index) => ({
    ...patient,
    full_name: formatName(patient?.full_name),
    email: formatEmail(patient?.email),
    gender: patient?.gender ? tableCell(patient.gender) : "",
    tinnitus_type: patient?.tinnitus_type ? tableCell(patient?.tinnitus_type) : "",
    current_frequency: patient?.current_frequency ? patient.current_frequency : 0,
    tinnitus_start_date: formatDate(patient?.tinnitus_start_date, countryToLocale[userCountry]),
    action: (
      <ActionMenu
        threeDots={
          patient.status === "active"
            ? threeDotsActive
            : patient.status === "inactive"
              ? threeDotsInactive
              : threeDotsPending
        }
        row={patient}
        key={index + 1}
      />
    ),
  }));
  const tableData = {
    columns: [
      { Header: t("name"), accessor: "full_name" },
      { Header: t("email"), accessor: "email" },
      { Header: t("gender"), accessor: "gender" },
      { Header: t("tinnitus type"), accessor: "tinnitus_type" },
      { Header: t("start date"), accessor: "tinnitus_start_date" },
      { Header: t("frequency"), accessor: "current_frequency" },
      { Header: t("action"), accessor: "action" },
    ],
    rows: patientsRowsData,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar customRoutePath={"Patients"} />
      <Header onTabChange={handleTabChange} />
      {loading && <Spinner />}
      {!loading && (
        <Card p={1}>
          <ArgonBox>
            <DataTable
              canAdd={{
                name: "Add",
                action: handleAddPatient,
              }}
              canSearch
              canFilter
              filters={filters}
              dataTableName={"Patients"}
              entriesPerPage={entriesPerPage}
              setSearch={setSearch}
              search={search}
              totalPages={totalPages}
              setEntriesPerPage={setEntriesPerPage}
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalEntries={totalEntries}
              table={tableData}
              viewEntry={handleView}
              clickableRecords={true}
            />
          </ArgonBox>
        </Card>
      )}
    </DashboardLayout>
  );
}

export default patients;
