import Card from "@mui/material/Card";
import ArgonImage from "components/ArgonImage";
import Link from "@mui/material/Link";
import { useState } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Authentication layout components
import BasicLayout from "blikol-layouts/components/BasicLayout";
import resetLogo from "../../../../assets/images/icons/blikol/reset-password.png";
import useAuthService from "service-hooks/useAuthService";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function Cover() {
  const queryParams = new URLSearchParams(location.search);
  const [token, setEmail] = useState(queryParams.get("token"));
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const authHookService = useAuthService();
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    if (token) {
      const userResendPassword = await authHookService.resetPassword(
        token,
        password,
        confirmPassword
      );
    } else {
      toast.error("No token provided.");
      navigate("/user/login");
    }
  };
  return (
    <BasicLayout>
      <ArgonBox
        pt={3}
        px={3}
        mb={1}
        sx={{ width: "446px", display: "flex", justifyContent: "center" }}
      >
        <ArgonImage source="/BLIKOL_logo.png" width="163.746px" height="73.037px" mb="20px" />
      </ArgonBox>
      <Card
        sx={{
          width: "446px",
          height: "544px",
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "10px",
        }}
      >
        <ArgonBox
          mt={2}
          px={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <ArgonBox
            component="img"
            src={resetLogo}
            alt="user"
            mb={2}
            mt={5}
            height="48px"
            width="48px"
          />
          <ArgonBox>
            <ArgonTypography
              textAlign="center"
              variant="h3"
              color="dark"
              sx={{
                width: "auto",
                height: "auto",
                fontSize: "20px",
                letterSpacing: "-0.8px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "137%",
              }}
            >
              Create new Password
            </ArgonTypography>
            <ArgonTypography
              textAlign="center"
              variant="h3"
              mb={2}
              sx={{
                width: "auto",
                height: "auto",
                fontFamily: "Open Sans",
                fontSize: "14px",
                letterSpacing: "-0.8px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "22px",
                color: "#373D3F",
              }}
            >
              Restore access to your account
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>

        <ArgonBox p={3}>
          <ArgonBox component="form" role="form">
            <ArgonBox>
              <ArgonTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="dark"
                sx={{ ml: 0.5, mb: 1 }}
              >
                Password
              </ArgonTypography>
              <ArgonInput
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                size="large"
              />
            </ArgonBox>
            <ArgonBox>
              <ArgonTypography
                pt={1}
                display="block"
                variant="caption"
                fontWeight="regular"
                color="dark"
                sx={{ ml: 0.5, mb: 1, mt: 2 }}
              >
                Confirm Password
              </ArgonTypography>
              <ArgonInput
                type="password"
                placeholder="Re-enter Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                size="large"
              />
            </ArgonBox>
            <ArgonBox mt={3} mb={1} textAlign="center">
              <ArgonButton
                color="info"
                fullWidth
                onClick={handleResetPassword}
                sx={{
                  fontFamily: "Open Sans",
                  textAlign: "center",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "22px",
                }}
              >
                Update Password
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
