import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import ProgressBar from "components/ProgressBar/ProgressBar";
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ArgonInput from "components/ArgonInput";
import "../../style.css"
import FormHelperText from "@mui/material/FormHelperText";


const style = {
    display: "flex",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    height: "350px",
    padding: "24px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    margin: "auto",
    borderRadius: "15px",
    background: "#FFF",
};

// Import statements remain unchanged...

export default function AddModal({
    open,
    headerContent,
    subHeaderContent,
    filledBtnText,
    filledBtnAction,
    outlinedBtnText,
    inputValue,  // inputValue prop
    setInput,   // setInput prop
    hasSecondInput,
    secondSubHeaderContent,
    secondInputValue,
    setSecondInput,
    outlinedBtnAction,
    hasCheckbox,
    checkBoxText,
    setCheckboxState,
    initialCheckBoxState
}) {
    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate();
    const userRole = useSelector((state) => state.auth?.user?.role);
    const [isChecked, setIsChecked] = useState(initialCheckBoxState);
    const [error, setError] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInput(value);
        if (value <= 0) {
            setError('Value must be greater than 0');
        } else {
            setError('');
        }
    };

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {spinner && <ProgressBar />}
                    <ArgonBox
                        sx={{
                            marginBottom: "25px",
                        }}
                    >
                        <ArgonTypography
                            component="label"
                            variant="h5"
                            sx={{
                                fontSize: "20px",
                                lineHeight: "28px",
                                color: "var(--Wireframes-Primary, var(--Body, #373D3F)) !important",
                                fontStyle: "normal",
                                fontWeight: "600 !important",
                            }}
                        >
                            {headerContent}
                        </ArgonTypography>
                    </ArgonBox>

                    <ArgonBox
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            paddingLeft: "93px",
                            paddingRight: "96px"
                        }}
                    >
                        <ArgonTypography
                            sx={{
                                color: 'var(--Text, #1A1D1F)',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                fontFamily: 'Open Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                            }}
                            mb={0.8}
                        >
                            {subHeaderContent}
                        </ArgonTypography>
                        <ArgonInput
                            sx={{
                                display: 'flex',
                                width: '435px !important',
                                padding: '14px 14px 14px 20px',
                                alignItems: 'center',
                                borderRadius: '8px',
                                border: '1px solid var(--Gray-Gray-200, #E2E8F0)',
                                background: 'var(--black-amp-white-white, #FFF)',
                            }}
                            onChange={handleInputChange}  // Use setInput prop
                            value={inputValue}  // Use inputValue prop
                            disabled={isChecked}
                        />
                        {error && (
                            <FormHelperText
                                sx={{
                                    color: 'red',
                                    fontSize: '12px',
                                    marginTop: '4px',
                                }}
                            >
                                {error}
                            </FormHelperText>
                        )}
                        {hasSecondInput && (
                            <>
                                <ArgonTypography
                                    sx={{
                                        color: 'var(--Text, #1A1D1F)',
                                        fontFeatureSettings: "'clig' off, 'liga' off",
                                        fontFamily: 'Open Sans',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}
                                    my={0.8}
                                >
                                    {secondSubHeaderContent}
                                </ArgonTypography>
                                <ArgonInput
                                    sx={{
                                        display: 'flex',
                                        width: '435px !important',
                                        padding: '14px 14px 14px 20px',
                                        alignItems: 'center',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Gray-Gray-200, #E2E8F0)',
                                        background: 'var(--black-amp-white-white, #FFF)',
                                    }}
                                    onChange={(event) => setSecondInput(event.target.value)}  // Use setInput prop
                                    value={secondInputValue}  // Use inputValue prop
                                    disabled={true}
                                    type="number"
                                />

                            </>

                        )}
                        {hasCheckbox &&
                            (<ArgonBox sx={{ display: "flex", flexDirection: "row", gap: "6px" }} mt={2}>
                                <input
                                    type="checkbox"
                                    onChange={() => {
                                        setCheckboxState(!isChecked);
                                        setIsChecked(!isChecked);
                                    }
                                    }
                                    checked={isChecked}
                                />
                                <ArgonTypography
                                    sx={{
                                        color: 'var(--Text, #1A1D1F)',
                                        fontFeatureSettings: "'clig' off, 'liga' off",
                                        fontFamily: "Open Sans",
                                        fontSize: 14,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: 'normal',

                                    }}
                                >
                                    {checkBoxText}
                                </ArgonTypography>
                            </ArgonBox>
                            )}

                    </ArgonBox>

                    <ArgonBox sx={{ width: "435px !important" }}>
                        <ArgonBox
                            sx={{
                                display: "flex",
                                justifyContent: "right",
                                marginTop: "35px",
                                marginBottom: spinner ? "20px" : "0px",
                            }}
                        >
                            <ArgonButton
                                variant="outlined"
                                color="info"
                                sx={{
                                    borderRadius: "4px",
                                    margin: "0px 16px 0px 16px",
                                    width: "124px",
                                    fontSize: "small",
                                }}
                                onClick={outlinedBtnAction}
                            >
                                {outlinedBtnText}
                            </ArgonButton>

                            <ArgonButton
                                variant="contained"
                                color="info"
                                sx={{
                                    borderRadius: "4px",
                                    width: "124px",
                                    fontSize: "small",
                                }}
                                disabled={(inputValue.length !== 0 || (!isChecked)) ? false : true}
                                onClick={filledBtnAction}
                            >
                                {filledBtnText}
                            </ArgonButton>
                        </ArgonBox>
                    </ArgonBox>
                </Box>

            </Modal>
        </div>
    );
}

AddModal.defaultProps = {
    open: false,
    headerContent: "",
    subHeaderContent: "",
    filledBtnText: "",
    outlinedBtnText: "",
    inputValue: "",
    setInput: () => { },  // Default setInput prop as a no-op function
    hasSecondInput: false,
    secondSubHeaderContent: "",
    secondInputValue: "",
    setSecondInput: () => { },
    hasCheckbox: false,
    checkBoxText: "",
    setCheckboxState: () => { },
};
AddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    headerContent: PropTypes.string,
    subHeaderContent: PropTypes.string,
    filledBtnText: PropTypes.string,
    filledBtnAction: PropTypes.func,
    outlinedBtnText: PropTypes.string,
    outlinedBtnAction: PropTypes.func,
    inputValue: PropTypes.any,
    setInput: PropTypes.func,  // Add prop type for setInput
    hasSecondInput: PropTypes.bool,
    secondSubHeaderContent: PropTypes.string,
    secondInputValue: PropTypes.string,
    setSecondInput: PropTypes.func,
    hasCheckbox: PropTypes.bool,
    checkBoxText: PropTypes.string,
    setCheckboxState: PropTypes.func,
    initialCheckBoxState: PropTypes.bool
};
