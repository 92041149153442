import useBaseService from "../useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const usePaymentMethodService = () => {
  const axiosRequest = useBaseService().axiosRequest();

  const paymentMethods = async () => {
    try {
      const response = await axiosRequest.get(`clinic_apis/payment_methods`);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);

      return false;
    }
  };

  const setupIntent = async () => {
    try {
      const response = await axiosRequest.get("/clinic_apis/payment_methods/intent");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const destroy = async (id) => {
    try {
      const response = await axiosRequest.delete(`clinic_apis/payment_methods/${id}`);
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const update = async (id) => {
    try {
      const response = await axiosRequest.put(`clinic_apis/payment_methods/${id}`);
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false; // You can handle the error as needed in the calling code.
    }
  };
  return {
    paymentMethods,
    setupIntent,
    destroy,
    update,
  };
};

export default usePaymentMethodService;
