import { sidebarRoutes } from "routes";
import { routes } from "routes";
import { Route, Navigate } from "react-router-dom";

export const applicationMappedRoutes = (userRole) => {
  let roleBasedRoutes = null;
  if (userRole) {
    const sidebarUserRoutes = sidebarMappedRoutes(userRole);
    roleBasedRoutes = routes[userRole];
    roleBasedRoutes.concat(sidebarUserRoutes);
    roleBasedRoutes.push({
      key: "default-home",
      route: "*",
      component: <Navigate to={`${sidebarUserRoutes[0].route}`} />,
    });
  } else {
    roleBasedRoutes = routes["authentication"];
    roleBasedRoutes.push({
      key: "default-unauthenticated",
      route: "*",
      component: <Navigate to="/user/login" />,
    });
  }
  return roleBasedRoutes;
};

export const sidebarMappedRoutes = (userRole) => (userRole ? sidebarRoutes[userRole] : []);

export const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    }

    return null;
  });
