import { useEffect, useState } from "react";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import Grid from "@mui/material/Grid";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import Card from "@mui/material/Card";
import borders from "assets/theme/base/borders";
import usePaymentMethodService from "service-hooks/clinic/usePaymentMethodService";
import { useNavigate } from "react-router-dom";
import masterCardIcon from "../../../assets/images/icons/blikol/mastercard-icon.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "examples/ConfirmationModal";
import BrandIcon from "components/BrandIcon";
import "utils/i18n"
import { useTranslation } from "react-i18next";

function PaymentMethods() {
  const { borderWidth, borderColor } = borders;
  const navigate = useNavigate();
  const paymentMethodHookService = usePaymentMethodService();
  const [pageRefresh, setPageRefresh] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [paymentId, setPaymentId] = useState();
  const { t } = useTranslation()

  useEffect(() => {
    paymentMethodHookService
      .paymentMethods()
      .then((result) => {
        if (result) {
          setPaymentMethods(result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [pageRefresh]);
  const handleDelete = (id) => {
    paymentMethodHookService
      .destroy(id)
      .then(() => {
        toast.success(t("Payment method deleted successfully"));
        setPageRefresh(!pageRefresh);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleSetDefault = (id) => {
    paymentMethodHookService
      .update(id)
      .then(() => {
        toast.success(t("Default changed successfully"));
        setPageRefresh(!pageRefresh);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <DashboardLayout colorBgHeight={"110px"}>
      <DashboardNavbar customRoutePath={"Payment Methods"} />
      <ArgonBox px={3} pt={3}>
        <ArgonBox sx={{ display: "flex", justifyContent: "space-between" }}>
          <ArgonTypography
            mb={3}
            mt={4}
            sx={{
              color: "var(--Default, #172B4D)",
              fontSize: "25px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "137%",
              letterSpacing: "-0.8px",
            }}
          >
            {t("Payment Method")}
          </ArgonTypography>
          <ArgonBox mb={3} mt={4}>
            <ArgonButton
              variant="contained"
              color="info"
              sx={{
                borderRadius: "4px",
                padding: "10px 20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFF",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "22px",
              }}
              onClick={() => {
                navigate("/clinic/payment-methods/new");
              }}
            >
              {t("Add Card")}
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
        <ArgonTypography
          sx={{
            color: "#000",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "150%",
          }}
          mb={4}
        >
          {t("Manage your stored cards for a more seamless shopping experience. Your card details are secured and encrypted by Stripe")}
        </ArgonTypography>
        <ArgonBox >
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} sm={7}>
              {paymentMethods.map((paymentMethod, index) => (
                <Card
                  key={index}
                  sx={{
                    flexShrink: 0,
                    borderRadius: "8px",
                    background: "#FFF",
                    boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
                    padding: "20px 20px 10px 20px",
                    marginBottom: "20px", // Add margin between each card
                  }}
                >
                  <ArgonBox
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    mb={1}
                  >
                    {paymentMethod.default ? (
                      <ArgonBox
                        sx={{
                          display: "flex",
                          height: "24px",
                          padding: "10px 20px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                          flexShrink: 0,
                          borderRadius: "8px",
                          border: "1px solid var(--Primary-Color, #0A9CC4)",
                          background: "rgba(10, 156, 196, 0.20)",
                          color: "var(--Primary-Color, #0A9CC4)",
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {t("Default")}
                      </ArgonBox>
                    ) : (
                      <>
                        {paymentMethods.length > 1 ? (
                          <ArgonButton
                            variant="text"
                            color="info"
                            sx={{
                              fontSize: 14,
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "137%",
                              letterSpacing: "-0.8px",
                            }}
                            onClick={() => {
                              setShowConfirmationModal(true);
                              setPaymentId(paymentMethod.id);
                            }}
                          >
                            {t("Set as default")}
                          </ArgonButton>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </ArgonBox>
                  <ArgonTypography
                    sx={{
                      color: "var(--Default, #172B4D)",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "150%",
                    }}
                    mb={1}
                    ml={1}
                  >
                    {t("Card Number")}
                  </ArgonTypography>
                  <ArgonBox
                    border={`${borderWidth[1]} solid ${borderColor}`}
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={1}
                  >
                    <ArgonTypography variant="h6" fontWeight="medium">
                      {Array.from({ length: 12 }, (_, index) =>
                        index % 4 === 0 && index !== 0 ? " *" : "*"
                      ).join("")}
                      {paymentMethod.last4_digits}
                    </ArgonTypography>
                    <BrandIcon brandName={paymentMethod.brand} />
                  </ArgonBox>

                  <ArgonBox
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    mt={1.5}
                  >
                    <ArgonButton
                      variant="text"
                      color="warning"
                      onClick={() => {
                        setShowConfirmationModal(true);
                        setIsDelete(true);
                        setPaymentId(paymentMethod.id);
                      }}
                    >
                      {t("Delete")}
                    </ArgonButton>
                  </ArgonBox>
                  {!isDelete ? (
                    <ConfirmationModal
                      open={showConfirmationModal}
                      onClose={() => setShowConfirmationModal(false)}
                      onConfirm={() => {
                        handleSetDefault(paymentId);
                        setShowConfirmationModal(false);
                      }}
                      cancelButtonText={t("Cancel")}
                      confirmButtonText={t("Confirm")}
                      modalHeadingText={t("Set as Default")}
                      confirmationText={
                        t("Are you sure you want to make this the default payment method?")
                      }
                    />
                  ) : (
                    <ConfirmationModal
                      open={showConfirmationModal}
                      onClose={() => setShowConfirmationModal(false)}
                      onConfirm={() => {
                        handleDelete(paymentId);
                        setShowConfirmationModal(false);
                        setIsDelete(false);
                      }}
                      cancelButtonText={t("Cancel")}
                      confirmButtonText={t("Confirm")}
                      modalHeadingText={t("Delete")}
                      confirmationText={t("Are you sure you want to delete this card")}
                    />
                  )}
                </Card>
              ))}
            </Grid>
          </Grid>
        </ArgonBox>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default PaymentMethods;
