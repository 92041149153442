import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import PatientForm from "blikol-layouts/clinic/patients/components/Form/patientForm";
import usePatientsService from "service-hooks/clinic/usePatientsService";
import Spinner from "../../../../components/Spinner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import CreditsIcon from "../../../../../assets/images/icons/patients/Fb.svg"
import ArgonTypography from "components/ArgonTypography";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArgonButton from "components/ArgonButton";
import "utils/i18n"
import { useTranslation } from "react-i18next";

function NoCredits() {
    const navigate = useNavigate();
    const { t } = useTranslation()
    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar customRoutePath={"Patients"} />
            <ArgonBox
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh',
                }}
            >
                <Card
                    sx={{
                        margin: 'auto', // Center the card horizontally and vertically
                        flexDirection: 'column',
                        gap: '24px',
                        flexShrink: 0,
                        borderRadius: '15px',
                        background: '#FFF',
                        padding: "40px"
                    }}
                >
                    <ArgonBox
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                            padding: "10px",
                            justifyContent: "center",
                            alignItems: "center"


                        }}
                    >
                        <ArgonBox
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "20px 0px 0px 0px"
                            }}

                        >
                            <img src={CreditsIcon} />
                        </ArgonBox>

                        <ArgonBox>
                            <ArgonTypography variant="h5" sx={{
                                padding: "10px",
                                color: "var(--Text, #1A1D1F)"
                            }}
                            >
                                No Credits
                            </ArgonTypography>

                        </ArgonBox>

                    </ArgonBox>
                    <ArgonTypography
                        sx={{
                            color: 'var(--Body, #373D3F)',
                            textAlign: 'center',
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '22px',

                        }}
                    >
                        To add new patients, please complete the process <br />
                        of adding credits to your account first.
                    </ArgonTypography>
                    <ArgonButton
                        variant="contained"
                        color="info"
                        sx={{
                            display: 'inline-flex',
                            padding: '10px 20px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px',
                            borderRadius: '8px',
                            boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                        }}
                        onClick={() => navigate("/clinic/plans")}

                    >
                        Purchase Credits
                    </ArgonButton>
                </Card>
            </ArgonBox>

        </DashboardLayout>
    );
}

export default NoCredits;
