import useBaseService from "../useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const usePatientSessionService = () => {
  const axiosRequest = useBaseService().axiosRequest();

  const getFrequencyTestPercentageError = async () => {
    try {
      const response = await axiosRequest.get(`/clinic_apis/settings/diagnostic_setting`);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error[0]);
      return false;
    }
  };

  const createPatientSession = async (body) => {
    try {
      const response = await axiosRequest.post(`/clinic_apis/sessions`, body);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error[0]);
      return false;
    }
  };

  return {
    createPatientSession,
    getFrequencyTestPercentageError,
  };
};

export default usePatientSessionService;
