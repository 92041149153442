import "./style.css";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import PlanCard from "../components/PlanCard";
import PlanCardVat from "../components/PlanCardVat";
import PlanCardSingleCredit from "../components/PlanCardSingleCredit";
import { useEffect, useState } from "react";
import useCreditPackageService from "service-hooks/clinic/useCreditPackageService";
import Spinner from "../../components/Spinner";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import Grid from "@mui/material/Grid";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import useClinicService from "service-hooks/clinic/useClinicService";
import { setUser } from "slices/authSlice";
import "utils/i18n"
import { useTranslation } from "react-i18next";

function Plans() {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.auth?.user);
  const dispatchUser = useDispatch()
  const creditPackageHookService = useCreditPackageService();
  const clinicHookService = useClinicService()
  const { t } = useTranslation()

  useEffect(() => {
    creditPackageHookService
      .packages()
      .then((result) => {
        setPlans(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    clinicHookService
      .clinic(user?.id)
      .then((result) => {
        if (result) {
          if (result.data.vat_payer !== user.vat_payer) {
            dispatchUser(setUser(result?.data))
          }
        } else {
          console.log("Data is undefined or incomplete.");
        }
      });
  }, []);

  return (
    <DashboardLayout colorBgHeight={"110px"}>
      <DashboardNavbar customRoutePath={"Credit Plans"} />
      <ArgonBox px={3} pt={3}>
        <ArgonTypography
          sx={{
            color: "var(--Default, #172B4D)",
            fontSize: "25px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "137%",
            letterSpacing: "-0.8px",
          }}
        >
          {t("Purchase Credits")}
        </ArgonTypography>
      </ArgonBox>
      <Grid mt={3} container spacing={3}>
        {loading && (
          <ArgonBox m={"auto"}>
            <Spinner />
          </ArgonBox>
        )}
        {!loading &&
          plans?.map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan.id}>
              {
                user?.vat_payer === true
                  ? (plan.credits === 1
                    ? <PlanCardSingleCredit plan={plan} isVatPayer={true} />
                    : <PlanCardVat plan={plan} />)
                  : (plan.credits === 1
                    ? <PlanCardSingleCredit plan={plan} />
                    : <PlanCard plan={plan} />)
              }

            </Grid>
          ))}
        {!loading && plans.length == 0 && (
          <ArgonBox m={"auto"} color={"red"}>
            {t("No plans added by admin right now.")}
          </ArgonBox>
        )}
      </Grid>
    </DashboardLayout>
  );
}

export default Plans;
