// Settings page components
// import BaseLayout from "layouts/pages/account/components/BaseLayout";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import useUserService from "service-hooks/useUserService";
import UserForm from "blikol-layouts/users/components/userForm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

function AddUser() {
    const navigate = useNavigate();
    const userHookService = useUserService()
    const userRole = useSelector((state) => state.auth?.user?.role);

    const handleSubmit = (values) => {
        userHookService
            .create(values)
            .then((result) => {
                if (result) {
                    toast.success("User created successfully.");
                    navigate(`/${userRole}/users`);
                }
            })
            .catch((error) => {
                toast.error(error.response.data.error);
                navigate(`/${userRole}/users`);
            });
    };
    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar customRoutePath={"Users / Add New User"} />
            <UserForm handleSubmit={handleSubmit} />
        </DashboardLayout>
    );
}

export default AddUser;
