import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useDashboardService = () => {
    const axiosRequest = useBaseService().axiosRequest();

    const graphStatistics = async (filter = "") => {
        try {
            const response = await axiosRequest.get(`/administrator_apis/dashboards/graph_stats?filter=${filter}`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    const cardStatistics = async (dateRange) => {
        try {
            const response = await axiosRequest.get(`/administrator_apis/dashboards/stats?date_range=${dateRange}`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };


    return {
        graphStatistics,
        cardStatistics
    };
};

export default useDashboardService;
