import React from 'react'
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ResetPassword from 'blikol-layouts/components/ResetPassword';
import Grid from "@mui/material/Grid";
import "utils/i18n"
import { useTranslation } from "react-i18next";
import { languageOptions } from "parsers/formatParser";
import useClinicService from 'service-hooks/clinic/useClinicService';
import ArgonSelect from 'components/ArgonSelect';
import ArgonButton from 'components/ArgonButton';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from 'react';
import Card from "@mui/material/Card";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setUser } from 'slices/authSlice';
function index() {
    const { t, i18n } = useTranslation()
    const clinicHookService = useClinicService();
    const userRole = useSelector((state) => state.auth?.user?.role);
    const user = useSelector((state) => state.auth?.user);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [clinicData, setClinicData] = useState(user)
    const [languageMenuOpen, setLanguageMenuOpen] = useState(false)
    const handleSubmit = () => {
        if (clinicData) {
            clinicHookService.update(clinicData, user?.id).then((result) => {
                if (result) {
                    dispatch(setUser(result.data));
                    i18n.changeLanguage(result?.data?._language)
                    toast.success(t("Clinic updated successfully"));
                    navigate(`/${userRole}/dashboard`);
                }
            });
        }
    };

    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar />
            <Grid container>
                <Grid item xs={12}>
                    <ArgonBox px={3} pt={4} sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                        <ArgonTypography
                            sx={{
                                color: "var(--Default, #172B4D)",
                                fontSize: "25px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "137%",
                                letterSpacing: "-0.8px",
                            }}
                        >
                            {t("Update Language")}
                        </ArgonTypography>
                        <Card sx={{ overflow: "hidden" }}>
                            <ArgonBox px={3} py={4}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "15px",
                                    height: languageMenuOpen ? "400px" : "auto"

                                }}>
                                <ArgonTypography
                                    variant="h5"
                                >
                                    {t("Change Language")}
                                </ArgonTypography>
                                <ArgonBox>
                                    <Grid item xs={4}>
                                        <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                            mb={1}
                                            ml={0.5}
                                        >
                                            {t("Language")}  <span style={{ color: "red" }}>*</span>
                                        </ArgonTypography>
                                        <ArgonSelect
                                            placeholder="e.g English"
                                            options={languageOptions}
                                            onMenuOpen={() => setLanguageMenuOpen(true)}
                                            onMenuClose={() => setLanguageMenuOpen(false)}
                                            onChange={(selectedOption) => {
                                                setClinicData({
                                                    ...clinicData,
                                                    _language: selectedOption?.value
                                                })

                                            }}
                                            defaultValue={
                                                user?._language
                                                    ? {
                                                        label: languageOptions.find(option => option.value === user?._language)?.label || '',
                                                        value: user?._language,
                                                    }
                                                    : null
                                            }
                                        />
                                    </Grid>
                                </ArgonBox>
                            </ArgonBox>
                        </Card>
                        <ArgonBox mt={3}>
                            <ArgonButton
                                onClick={handleSubmit}
                                color="info"
                            >
                                {t("Update")}
                            </ArgonButton>
                        </ArgonBox>

                    </ArgonBox>
                </Grid>
            </Grid>
        </DashboardLayout>

    )
}

export default index