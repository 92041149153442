import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import "./CustomStepper.css";

function CustomStepper({ data, stepperStyles }) {
  const { activeStep } = useSelector((state) => state.general.stepper);
  const calculatedWidth = 100 / data?.length;
  return (
    <div className="stepper-container">
      <div
        className="stepper"
        style={{
          position: "relative",
          display: "grid",
          gridTemplateColumns: `repeat(${data?.length}, 1fr)`,
        }}
      >
        {data?.map((item, index) => (
          <>
            <div
              className="parent"
              style={{ display: "grid", gridTemplateColumns: "1fr", justifySelf: "center" }}
              key={index}
            >
              <div
                className={`dot ${index <= activeStep ? "filled" : ""}`}
                style={{
                  justifySelf: "center",
                  ...(index <= activeStep
                    ? stepperStyles?.circle?.active
                    : stepperStyles?.circle?.inactive),
                }}
              >
                {" "}
              </div>

              <div>{item?.label}</div>
            </div>

            {index !== data?.length - 1 && (
              <div
                className="stepper-connector"
                style={{
                  width: `${calculatedWidth}%`,
                  height: "3px",
                  backgroundColor: activeStep > index ? "#172B4D" : "#CED4DA",
                  position: "absolute",
                  top: "16%",
                  left: `${index * calculatedWidth + calculatedWidth / 2}%`,
                  ...(activeStep > index
                    ? stepperStyles?.connector?.active
                    : stepperStyles?.connector?.inactive),
                }}
              ></div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}

export default CustomStepper;

CustomStepper.defaultProps = {
  data: [],
};

CustomStepper.propTypes = {
  data: PropTypes.array,
  stepperStyles: PropTypes.object,
};
