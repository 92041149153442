import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useDownloadReceipt = () => {
    const axiosRequest = useBaseService().axiosRequest();

    const downloadReceipt = async (id) => {
        try {
            const response = await axiosRequest.get(`/users/${id}/receipt_url`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    return {
        downloadReceipt
    };
};

export default useDownloadReceipt;
