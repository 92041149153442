import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useFinancialsService = () => {
    const axiosRequest = useBaseService().axiosRequest();

    const financials = async (currentPage, itemsPerPage, date_range = "", query = "", country = "", city = "", packageType = "") => {
        try {
            const encodedQuery = encodeURIComponent(query)
            const response = await axiosRequest.get(
                `administrator_apis/financials?page=${currentPage}&items_per_page=${itemsPerPage}&query=${encodedQuery}&country=${country}&city=${city}&date_range=${date_range}&package=${packageType}`
            );
            const financialData = response.data.payments;
            const financialMeta = response.data.meta;
            return { financialData, financialMeta };
        } catch (error) {
            toast.error(error.response.data.error);

            return false;
        }
    };


    const stats = async (date_range, country, city, packageType) => {
        try {
            const response = await axiosRequest.get(`/administrator_apis/financials/stats?date_range=${date_range}&country=${country}&city=${city}&package=${packageType}`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };


    return {
        financials,
        stats
    };
};

export default useFinancialsService;
