import { useState, useEffect, useMemo } from "react";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTransactionHistoryService from '../../../../../service-hooks/clinic/useTransactionHistoryService'
import DownloadIcon from "assets/images/icons/clinic/download.svg"
import ActionMenu from "blikol-layouts/components/ActionMenu";
import React from 'react'
import useExportCSVService from "../../../../../service-hooks/useExportCSVService";
import { formatName } from "parsers/formatParser";
import Spinner from "blikol-layouts/components/Spinner";
import { formatDate, countryToLocale } from "blikol-layouts/clinic/parsers/dateFormatParser";
import { useTranslation } from "react-i18next";
import "utils/i18n"

function CreditsUsed() {
    const transactionServiceHook = useTransactionHistoryService()
    const { user, token } = useSelector((state) => state.auth)
    const userCountry = user?.country
    const [clinicId, setClinicId] = useState(user?.id)
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [data, setData] = useState();
    const [search, setSearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [totalEntries, setTotalEntries] = useState();
    const [totalPages, setTotalPages] = useState();
    const [dateRange, setDateRange] = useState("")
    const [tabValueForService, setTabValueForService] = useState("used_credits")
    const exportCSVServiceHook = useExportCSVService()
    const [loading, setLoading] = useState(true)
    const [totalCreditsUsed, setTotalCreditsUsed] = useState()
    const { t } = useTranslation()

    useEffect(() => {
        transactionServiceHook
            .credits_used(pageNo, entriesPerPage, search, clinicId)
            .then((result) => {
                if (result && result?.meta && result?.used_credits_list) {
                    setData(result);
                    setLoading(false)
                    setTotalEntries(result?.meta?.total_count);
                    setTotalCreditsUsed(result?.meta?.total_used_credits)
                    setTotalPages(result?.meta?.total_pages);
                } else {
                    toast.error(t("Data is undefined or incomplete"));
                }
            })
            .catch((error) => {
                // Handle the error (e.g., display an error message)
                toast.error(t("Error fetching user data and attributes:"), error);
            });

    }, [
        entriesPerPage,
        pageNo,
        search,
        dateRange
    ]
    );

    const handleExportCSV = () => {
        const pathname = `/clinic_apis/clinics/${clinicId}/${tabValueForService}_export`;
        const queryParams = new URLSearchParams({ query: search });
        const fullPath = `${pathname}?${queryParams.toString()}`;
        const filename = `clinic_${clinicId}_bonus_credits`;

        exportCSVServiceHook.exportCSV(fullPath, filename);
    };


    const usedCreditsRowsData = data?.used_credits_list?.map((used_credit, index) => ({
        ...used_credit,
        name: formatName(used_credit?.full_name),
        date: formatDate(used_credit?.date, countryToLocale[userCountry])
    })) ?? [];

    const tableDataUsedCredits = {
        columns: [
            { Header: t("patient"), accessor: "name" },
            { Header: t("registration date"), accessor: "date" },
            { Header: t("credits used"), accessor: "used_credits" },
        ],
        rows: usedCreditsRowsData,
    };

    return (
        <>
            {loading && <Spinner />}
            {!loading && (
                <DataTable
                    canExportCSV={{
                        name: "Export as CSV",
                        action: handleExportCSV
                    }}
                    canSearch
                    setSearch={setSearch}
                    search={search}
                    dataTableName={`${t("Credits Used")}: ${totalCreditsUsed}`}
                    entriesPerPage={entriesPerPage}
                    totalPages={totalPages}
                    setEntriesPerPage={setEntriesPerPage}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                    totalEntries={totalEntries}
                    table={tableDataUsedCredits}
                    lastFixed={false}
                />

            )}
        </>
    )
}

export default CreditsUsed