import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "utils/i18n"
import { useTranslation } from "react-i18next";


function Header({ onTabChange, numTabs, tabNames, customStyle }) {
    const [tabValue, setTabValue] = useState(0);
    const { t } = useTranslation()

    const generateTabStyles = (index) => {
        const isSelected = tabValue === index;
        return {
            backgroundColor: isSelected ? "info.main" : "initial",
            color: isSelected ? "#fff !important" : "#555F61 !important",
            "&:hover": {
                backgroundColor: isSelected ? "info.main" : "initial",
            },
            "&.Mui-selected": {
                backgroundColor: isSelected ? "info.main" : "initial",
                color: isSelected ? "#fff !important" : "#555F61 !important",
            },
            ...customStyle, // Apply styles from customStyle prop

        };
    };

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        const selectedTabLabel = event.currentTarget.textContent;
        onTabChange(selectedTabLabel);
    };

    return (
        <AppBar position="static">
            <Tabs
                value={tabValue}
                onChange={handleSetTabValue}
                indicatorColor="info" // Color of the animated bar
                TabIndicatorProps={{ style: { transitionDuration: '0ms' } }}
            >
                {tabNames.map((label, index) => (
                    <Tab key={index} label={t(label)} sx={generateTabStyles(index)} />
                ))}
            </Tabs>
        </AppBar>
    );
}

Header.propTypes = {
    onTabChange: PropTypes.func.isRequired,
    numTabs: PropTypes.number.isRequired,
    tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    customStyle: PropTypes.object,
};

export default Header;
