import React from "react";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import { useSelector } from "react-redux";
import ArgonBox from "components/ArgonBox";
import useClinicService from "service-hooks/useClinicService";
import DataTable from "examples/Tables/DataTable";
import { useState, useEffect, useMemo } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import ActionMenu from "blikol-layouts/components/ActionMenu";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "blikol-layouts/components/Spinner";
import Header from "blikol-layouts/components/Header"
import useExportCSVService from "service-hooks/useExportCSVService";
import { useParams } from "react-router-dom";
import Patient from "blikol-layouts/clinics/components/View/components/Patients";
import Credits from "blikol-layouts/clinics/components/View/components/Credits"
import Detail from "blikol-layouts/clinics/components/View/components/Detail";
import useClinicRequestService from "service-hooks/useClinicRequestService";
import AcceptReject from "../AcceptReject";

function View() {
    const { user, token } = useSelector((state) => state.auth)
    const userRole = user?.role
    const [clinicId, setClinicId] = useState(useParams().id);
    const [patientCount, setPatientCount] = useState()
    const clinicHookService = useClinicService()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [clinicData, setClinicData] = useState({})
    const clinicRequestHookService = useClinicRequestService();
    const [clinicName, setClinicName] = useState("")
    useEffect(() => {
        clinicRequestHookService
            .clinic(clinicId)
            .then((result) => {
                if (result) {
                    const clinic = result.data;
                    setLoading(false);
                    setClinicId(clinic?.id);
                    setClinicName(result?.data?.name)
                    setClinicData(result.data);
                } else {
                    toast.error("No Clinic with this id");
                    navigate(`/${userRole}/clinics`);
                }
            })
            .catch((error) => {
                console.error("Error fetching clinic data:", error);
            });

    }, []);


    return (
        <DashboardLayout>
            <DashboardNavbar customRoutePath={"Clinics"} />
            {loading && <Spinner />}
            {!loading && (
                <ArgonBox>
                    <Card p={1}>
                        <ArgonBox>
                            <Detail
                                clinicInfo={clinicData}
                                htmlComponent={clinicData?.status !== "rejected" ? () => <AcceptReject id={clinicId} name={clinicName} /> : null}
                            />
                        </ArgonBox>
                    </Card>
                </ArgonBox>
            )}
        </DashboardLayout>
    );
}

export default View;
