import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useDownloadInvoice = () => {
    const axiosRequest = useBaseService().axiosRequest();

    const downloadInvoice = async (id) => {
        try {
            const response = await axiosRequest.get(`/users/${id}/invoice_url`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    return {
        downloadInvoice
    };
};

export default useDownloadInvoice;
