import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Spinner from "../../../components/Spinner";
import { useState, useEffect, useMemo } from "react";
import PaymentSetupForm from "./components/paymentSetupForm";
import usePaymentMethodService from "service-hooks/clinic/usePaymentMethodService";
import PropTypes from "prop-types";

const style = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "642px",
  padding: "24px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  margin: "auto",
  borderRadius: "15px",
  background: "#FFF",
};

function StripePaymentMethodModal({ setStripeModalOpen, stripeModalOpen }) {
  const [open, setOpen] = useState(stripeModalOpen || false);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [publishableKey, setPublishableKey] = useState("");
  const paymentMethodHookService = usePaymentMethodService();
  const handleOpen = () => setOpen(true);
  const handleStripeClose = () => {
    setOpen(false);
  };
  const stripePromise = publishableKey ? loadStripe(publishableKey) : "";

  useEffect(() => {
    if (open) {
      paymentMethodHookService
        .setupIntent()
        .then((result) => {
          if (result) {
            setLoading(false);
            setPublishableKey(result.publishable_key);
            setClientSecret(result.client_secret);
          }
        })
        .catch((error) => {
          console.log(error.response.data.error);
        });
    } else {
      setStripeModalOpen(false);
    }
  }, [open]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleStripeClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {loading && <Spinner />}
            {processing && <Spinner height={"7vh"} />}
            {!loading && (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: clientSecret,
                  appearance: {
                    theme: "minimal",
                    variables: {
                      fontFamily: "Open Sans, system-ui, sans-serif",
                    },
                  },
                }}
              >
                <PaymentSetupForm
                  heading={"Add Payment Method"}
                  setOpen={setOpen}
                  setProcessing={setProcessing}
                  saveButtonText={"Save"}
                />
              </Elements>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

StripePaymentMethodModal.propTypes = {
  stripeModalOpen: PropTypes.bool,
  setStripeModalOpen: PropTypes.func,
};
export default StripePaymentMethodModal;
