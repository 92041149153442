import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SpinnerElement = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 6px solid #3498db; /* Adjust the color as needed */
  border-top: 6px solid transparent;
  animation: ${spinAnimation} 1s linear infinite;
`;

const SpinnerContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Adjust the z-index to ensure it's above modals */

`;

const Spinner = ({ height }) => {
  return (
    <SpinnerContainer style={{ height: height }}>
      <SpinnerElement />
    </SpinnerContainer>
  );
};

Spinner.propTypes = {
  height: PropTypes.string,
};

export default Spinner;
