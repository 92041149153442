import React from "react";
// Argon Dashboard 2 PRO MUI example components
import { useSelector } from "react-redux";
import ArgonBox from "components/ArgonBox";
import useClinicRequestService from "service-hooks/useClinicRequestService";
import DataTable from "examples/Tables/DataTable";
import { useState, useEffect, useMemo } from "react";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import "../../../../style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "blikol-layouts/components/Spinner";
import TextAreaModal from "blikol-layouts/components/TextAreaModal";
import ConfirmationModal from "examples/ConfirmationModal";
import PropTypes from "prop-types";

function AcceptReject({ id, name }) {
    const userRole = useSelector((state) => state.auth?.user?.role);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [pageRefresh, setPageRefresh] = useState(false);
    const [rejectReason, setRejectReason] = useState("");
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [inputRequiredTextDisplay, setInputRequiredTextDisplay] = useState("none");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [subHeaderContent, setSubHeaderContent] = useState("");
    const [selectedRequestId, setSelectedRequestId] = useState("");
    const clinicRequestHookService = useClinicRequestService();

    const handleApproveRequest = (id, name) => {
        setSubHeaderContent(`Are you sure you want to approve this clinic “${name}"`);
        setSelectedRequestId(id);
        setShowConfirmationModal(true);
    };

    const handleRejectRequest = (id, name) => {
        setModalOpen(true);
        setSubHeaderContent(`Are you sure you want to reject this clinic “${name}"`);
        setSelectedRequestId(id);
    };

    const handleApprove = () => {
        clinicRequestHookService
            .approve(selectedRequestId)
            .then((result) => {
                setPageRefresh(!pageRefresh);
                toast.success("Clinic request approved successfully");
                navigate(`/${userRole}/clinics`)
            })
            .catch((error) => {
                toast.error(error.response.data.error);
            });
    };

    const handleReject = () => {
        if (rejectReason) {
            setModalOpen(false);
            clinicRequestHookService
                .reject(selectedRequestId, rejectReason)
                .then((result) => {
                    if (result) {
                        toast.success("Clinic Request rejected successfully");
                        setInputRequiredTextDisplay("none");
                        setPageRefresh(!pageRefresh);
                        navigate(`/${userRole}/clinics`)

                    }
                })
                .catch((error) => {
                    toast.error(error.response.data.error);
                });
        } else {
            setInputRequiredTextDisplay("block");
        }
    };

    return (
        <>
            <div>
                <button
                    className={"button-approved"}
                    onClick={() => {
                        handleApproveRequest(id, name);
                    }}
                >
                    Approve
                </button>
                <button
                    className={"button-rejected"}
                    onClick={() => {
                        handleRejectRequest(id, name);
                    }}
                >
                    Reject
                </button>
            </div>
            <TextAreaModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                headerContent={"Reject"}
                subHeaderContent={subHeaderContent}
                placeholder={"Type here ..."}
                inputLabel={"Reason Of rejection"}
                confirmationBtnText={"Reject"}
                confirmationColor={"error"}
                onConfirm={() => {
                    handleReject();
                }}
                inputRequiredText={"Please enter a reason of rejection"}
                setInputValue={setRejectReason}
                inputRequiredTextDisplay={inputRequiredTextDisplay}
            />
            <ConfirmationModal
                open={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={() => {
                    handleApprove();
                    setShowConfirmationModal(false);
                }}
                cancelButtonText={"Cancel"}
                confirmButtonText={"Approve"}
                modalHeadingText={"Approve"}
                confirmationText={subHeaderContent}
                confirmButtonColor={"#0FB957"}
            />

        </>
    );
}


AcceptReject.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string, // Validate htmlComponent prop as a React element
};

export default AcceptReject;
