import ArgonButton from "components/ArgonButton";
import PropTypes from "prop-types";
import ArgonBox from "components/ArgonBox";
import Card from "@mui/material/Card";

import planCardIcon from "assets/images/icons/blikol/plan-card-icon.svg";
import ArgonTypography from "components/ArgonTypography";
import { useState } from "react";
import PaymentModal from "../PaymentModal";
import "utils/i18n"
import { useTranslation } from "react-i18next";

function PlanCardSingleCredit({ plan, isVatPayer }) {
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);

    const handleClose = () => {
        setPaymentModalOpen(false);
    };

    const handleOpenPaymentModal = () => {
        setPaymentModalOpen(true);
    };
    const { t } = useTranslation()

    return (
        <Card>
            <ArgonBox
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                }}
            >
                <ArgonBox
                    component="img"
                    src={plan?.thumbnail_url || planCardIcon}
                    alt="thumbnail"
                    width="3rem"
                    mt={3}
                />
                <ArgonTypography mt={3} fontWeight="bold" opacity={0.6}>
                    {plan?.name}
                </ArgonTypography>
                <ArgonTypography mt={6.75} fontWeight="bold" fontSize="1.8rem">
                    €{plan.price}
                </ArgonTypography>
                <ArgonTypography sx={{ marginBottom: isVatPayer ? "" : "102px", fontWeight: "600" }} fontSize="16px">
                    {t("Buy 1 Credit")}
                </ArgonTypography>
                <ArgonBox mt={0.25}><br /></ArgonBox>
                {isVatPayer && (<ArgonBox
                    sx={{
                        backgroundColor: "#F4F2F2",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "75%",
                        borderRadius: "10px",
                        padding: "10px 20px 10px 20px",
                        marginTop: "17px",
                        marginBottom: "20px"

                    }}>
                    <ArgonBox
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                        }}
                    >
                        <ArgonTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                            {t("VAT")} ({plan?.tax_price_detail?.vat_tax_rate}%)

                        </ArgonTypography>
                        <ArgonTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                            €{plan?.tax_price_detail?.vat_amount.toFixed(2)}
                        </ArgonTypography>

                    </ArgonBox>
                    <ArgonBox
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                        }}
                    >
                        <ArgonTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                            {t("Total (inc VAT)")}
                        </ArgonTypography>
                        <ArgonTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                            €{plan?.tax_price_detail?.price_with_vat.toFixed(2)}
                        </ArgonTypography>
                    </ArgonBox>
                </ArgonBox>)}
                <ArgonBox width="100%" display="flex" flexDirection="column" alignItems="center">
                    <ArgonBox mt={3} width="75%">
                        <ArgonButton color="info" fullWidth onClick={handleOpenPaymentModal}>
                            {t("Buy")}
                        </ArgonButton>
                    </ArgonBox>
                </ArgonBox>
            </ArgonBox>
            {paymentModalOpen && (
                <PaymentModal
                    plan={plan}
                    setPaymentModalOpen={setPaymentModalOpen}
                    paymentModalOpen={paymentModalOpen}
                />
            )}
        </Card>
    );
}

PlanCardSingleCredit.defaultProps = {
    plan: "",
    isVatPayer: false
};

PlanCardSingleCredit.propTypes = {
    plan: PropTypes.object,
    isVatPayer: PropTypes.bool,
};

export default PlanCardSingleCredit;
