import { useDispatch } from "react-redux";
import useBaseService from "../useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useFeedbackService = () => {
    const dispatch = useDispatch();
    const axiosRequest = useBaseService().axiosRequest();

    const feedbacks = async (id) => {
        try {
            const response = await axiosRequest.get(`/clinic_apis/patients/sessions/${id}/feedbacks`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    return {
        feedbacks
    };
};

export default useFeedbackService;
