// @mui material components
import Card from "@mui/material/Card";
import ArgonImage from "components/ArgonImage";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Authentication layout components
import BasicLayout from "blikol-layouts/components/BasicLayout";
import mailLogo from "../../../../assets/images/icons/blikol/mail.png";
import { useState } from "react";
import useAuthService from "service-hooks/useAuthService";

function successCover() {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [email, setEmail] = useState(queryParams.get("email"));
  const authHookService = useAuthService();

  const handleResendPassword = async () => {
    if (email) {
      const userResendPassword = await authHookService.forgotPassword(email);
    }
  };

  return (
    <BasicLayout>
      <ArgonBox
        pt={3}
        px={3}
        mb={1}
        sx={{ width: "446px", display: "flex", justifyContent: "center" }}
      >
        <ArgonImage source="/BLIKOL_logo.png" width="163.746px" height="73.037px" mb="20px" />
      </ArgonBox>
      <Card
        sx={{
          width: "446px",
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "10px",
          paddingBottom: "40px",
        }}
      >
        <ArgonBox
          mt={5}
          px={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <ArgonBox component="img" src={mailLogo} alt="user" width="15%" mb={2} mt={5} />
          <ArgonBox>
            <ArgonTypography
              textAlign="center"
              variant="h3"
              mb={2}
              color="dark"
              sx={{
                width: "auto",
                height: "auto",
                fontSize: "20px",
                letterSpacing: "-0.8px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "137%",
              }}
            >
              Reset your password
            </ArgonTypography>
            <ArgonTypography
              variant="h3"
              mb={4}
              sx={{
                width: "auto",
                height: "auto",
                fontFamily: "Open Sans",
                fontSize: "14px",
                letterSpacing: "-0.8px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "22px",
                color: "#373D3F",
                textAlign: "center"
              }}
            >
              If an account exists for {email} we&apos;ll send instructions for resetting
              your password.
            </ArgonTypography>
            <ArgonButton color="info" fullWidth onClick={() => navigate("user/login")}>
              Back to login
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>

        <ArgonBox p={3}>
          <ArgonBox mt={3} mb={1} textAlign="center">
            <ArgonBox
              px={1}
              mt={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
              textAlign="center"
            >
              <ArgonTypography
                variant="h3"
                sx={{
                  width: "auto",
                  height: "auto",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  letterSpacing: "-0.8px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "22px",
                  color: "#373D3F",
                }}
              >
                If you have not received the instructions, please check your junk or spam folder. If you still can not find them there, click on {" "}
                <ArgonTypography
                  component={Link}
                  variant="button"
                  color="info"
                  onClick={handleResendPassword}
                  sx={{
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                >
                  Resend
                </ArgonTypography>
              </ArgonTypography>
            </ArgonBox>

          </ArgonBox>
        </ArgonBox>
      </Card>
    </BasicLayout>
  );
}

export default successCover;
