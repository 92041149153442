import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import UserForm from "blikol-layouts/users/components/userForm";
import useUserService from "service-hooks/useUserService";
import Spinner from "../../components/Spinner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

function EditUser() {
    const navigate = useNavigate();
    const userHookService = useUserService();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({});
    const userRole = useSelector((state) => state.auth?.user?.role);
    const [userId, setUserId] = useState(useParams().id);

    const handleSubmit = (values) => {
        userHookService.update(values, userId).then((result) => {
            if (result) {
                toast.success("User updated successfully.");
                navigate(`/${userRole}/users`);
            }
        });

    };

    useEffect(() => {
        userHookService
            .user(userId)
            .then((result) => {
                if (result) {
                    const user = result.data;
                    setLoading(false);
                    setUserId(user?.id);
                    setUserData(result?.data);
                } else {
                    toast.error("No User with this id");
                    navigate(`/${userRole}/users`);
                }
            })
            .catch((error) => {
                console.error("Error fetching user data and attributes:", error);
            });
    }, []);


    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar customRoutePath={"Users / Edit User"} />
            {loading && <Spinner />}
            {!loading && (
                <UserForm
                    userId={userId}
                    userData={userData}
                    heading={"Edit User"}
                    buttonText={"Update"}
                    handleSubmit={handleSubmit}
                />
            )}
        </DashboardLayout>
    );
}

export default EditUser;
