import ArgonButton from "components/ArgonButton";
import PropTypes from "prop-types";
import ArgonBox from "components/ArgonBox";
import Card from "@mui/material/Card";

import planCardIcon from "../../../../assets/images/icons/blikol/plan-card-icon.svg";
import ArgonTypography from "components/ArgonTypography";
import { useState } from "react";
import PaymentModal from "../PaymentModal";
import "utils/i18n"
import { useTranslation } from "react-i18next";

function PlanCard({ plan }) {
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const handleClose = () => {
    setPaymentModalOpen(false);
  };
  const handleOpenPaymentModal = () => {
    setPaymentModalOpen(true)
  };
  const { t } = useTranslation()
  return (
    <ArgonBox>
      {(plan?.saved_credits) > 0 ? <ArgonBox sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%"
      }}>
        <ArgonBox sx={{
          backgroundColor: "#FB757A",
          display: "flex",
          fontSize: "15px",
          justifyContent: "center",
          padding: "5px 30px 5px 30px",
          borderRadius: "25px",
          color: "#FFFF",
          position: "relative",
          marginTop: "-17.5px",
          zIndex: 10

        }}>
          {t("Save")} €{plan?.saved_credits}
        </ArgonBox>
      </ArgonBox> : <></>}
      <Card sx={{
        marginTop: plan?.saved_credits > 0 ? "-17.5px" : "0px"
      }}>
        <ArgonBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: "30px",
            paddingBottom: "30px",
          }}
        >
          <ArgonBox
            component="img"
            src={plan?.thumbnail_url || planCardIcon}
            alt="thumbnail"
            width="3rem"
            mt={3}
          />
          <ArgonTypography mt={3} fontWeight="bold" opacity={0.6}>
            {plan?.name}
          </ArgonTypography>
          <ArgonBox mt={6} display="flex" flexDirection="row" alignItems="center" gap={1}>
            {(plan?.saved_credits) > 0 ? <ArgonTypography sx={{ textDecoration: "line-through", color: "#8b8b8b", fontWeight: "600" }}>
              €{(plan.price + ((plan.price / plan.credits) * plan.free_credits))}
            </ArgonTypography> : <></>}
            <ArgonTypography sx={{ fontWeight: "700", fontSize: "32px" }}>
              €{plan.price}
            </ArgonTypography>
          </ArgonBox>
          <ArgonTypography sx={{ marginBottom: "102px", fontWeight: "600" }} fontSize="16px">
            {plan.credits} {t("Credits Get")} {plan.free_credits} {t("Credits For Free")}
          </ArgonTypography>
          <ArgonBox mt={0.25}><br /></ArgonBox>
          <ArgonBox width="100%" display="flex" flexDirection="column" alignItems="center">
            <ArgonBox mt={3} width="75%">
              <ArgonButton color="info" fullWidth onClick={handleOpenPaymentModal}>
                {t("Buy")}
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Card>
      {
        paymentModalOpen && (
          <PaymentModal
            plan={plan}
            setPaymentModalOpen={setPaymentModalOpen}
            paymentModalOpen={paymentModalOpen}
          />
        )
      }

    </ArgonBox >
  );
}

PlanCard.defaultProps = {
  plan: "",
};

PlanCard.propTypes = {
  plan: PropTypes.object,
};

export default PlanCard;
