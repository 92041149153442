import Login from "blikol-layouts/authentication/sign-in/cover";
import ForgotPassword from "blikol-layouts/authentication/forgot-password/cover";
import ForgotPasswordSuccess from "blikol-layouts/authentication/forgot-password/successCover";
import ResetPassword from "blikol-layouts/authentication/reset-password/cover";
import AdminDashboard from "blikol-layouts/dashboard";
import ClinicDashboard from "blikol-layouts/clinic/dashboard";
import Settings from "blikol-layouts/settings";
import Clinic from "blikol-layouts/clinics";
import ViewClinic from "blikol-layouts/clinics/components/View";
import Musics from "blikol-layouts/musics";
import ClinicPatients from "blikol-layouts/clinic/patients";
import AddPaient from "blikol-layouts/clinic/patients/components/Form/addPatient";
import NoCredits from "blikol-layouts/clinic/patients/components/NoCredits";
import EditPaient from "blikol-layouts/clinic/patients/components/Form/editPatient";
import ClinicViewPatient from "blikol-layouts/clinic/patients/components/View/viewDetails";
import AddPackage from "blikol-layouts/settings/components/Package/components/addPackage";
import EditPackage from "blikol-layouts/settings/components/Package/components/editPackage";
import Plans from "blikol-layouts/clinic/Plans";
import PaymentMethods from "blikol-layouts/clinic/PaymentMethods";
import AddPaymentMethod from "blikol-layouts/clinic/PaymentMethods/components/addPaymentMethod";
import FrequencyDiagnosis from "blikol-layouts/clinic/FrequencyDiagnosis";
import Statistics from "blikol-layouts/clinic/Statistics";
import TransactionHistory from "blikol-layouts/clinic/TransactionHistory";
import Patients from "blikol-layouts/patients";
import ViewPatient from "blikol-layouts/patients/components/View";
import Users from "blikol-layouts/users"
import AddUser from "blikol-layouts/users/components/addUser"
import EditUser from "blikol-layouts/users/components/editUser"
import Financials from "blikol-layouts/financials"
import ClinicProfile from "blikol-layouts/clinic/Profile"
import ClinicChangePassword from "blikol-layouts/clinic/ResetPassword"
import ClinicRegistration from "blikol-layouts/landing/ClinicRegistration";
import ClinicRequestView from "blikol-layouts/clinics/components/Requests/components/View"
import ClinicChangeLanguage from "blikol-layouts/clinic/ChangeLanguage"
// icons
import DashboardIcon from "@mui/icons-material/HomeOutlined";
import OtherHousesOutlinedIcon from "@mui/icons-material/OtherHousesOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AddClinic from "blikol-layouts/clinics/components/addClinic";
import EditClinic from "blikol-layouts/clinics/components/editClinic";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AddchartIcon from '@mui/icons-material/Addchart';// All routes other than sidebar routes
export const routes = {
  authentication: [
    {
      key: "login",
      route: "/user/login",
      component: <Login />,
    },
    {
      key: "forgot-password",
      route: "/user/forgot-password",
      component: <ForgotPassword />,
    },
    {
      key: "forgot-password-success",
      route: "/user/forgot-password-success",
      component: <ForgotPasswordSuccess />,
    },
    {
      key: "reset-password",
      route: "/user/reset-password",
      component: <ResetPassword />,
    },
    {
      key: "register-clinic",
      route: "/clinic/register",
      component: <ClinicRegistration />,
    },
  ],
  super_admin: [
    {
      key: "superadmin-clinics-new",
      route: "/super_admin/clinics/new",
      component: <AddClinic />,
    },
    {
      key: "superadmin-clinics-edit",
      route: "/super_admin/clinics/edit/:id",
      component: <EditClinic />,
    },
    {
      key: "superadmin-clinics-view",
      route: "/super_admin/clinics/view/:id",
      component: <ViewClinic />,
    },
    {
      key: "superadmin-clinic-patients-view",
      route: "/super_admin/clinics/patients/view/:id",
      component: <ViewPatient />,
    },
    {
      key: "superadmin-settings-package-add-form",
      route: "/super_admin/settings/package/new",
      component: <AddPackage />,
    },
    {
      key: "superadmin-settings-package-edit-form",
      route: "/super_admin/settings/package/edit/:id",
      component: <EditPackage />,
    },
    {
      key: "superadmin-patients-view",
      route: "/super_admin/patients/view/:id",
      component: <ViewPatient />,
    },
    {
      key: "superadmin-user-new",
      route: "/super_admin/users/new",
      component: <AddUser />,
    },
    {
      key: "superadmin-user-edit",
      route: "/super_admin/users/edit/:id",
      component: <EditUser />,
    },
    {
      key: "superadmin-financials",
      route: "/super_admin/financials",
      component: <Financials />,
    },
    {
      key: "superadmin-clinic-requests-view",
      route: "/super_admin/clinics/requests/view/:id",
      component: <ClinicRequestView />,
    },

  ],
  admin: [
    {
      key: "admin-clinics-new",
      route: "/admin/clinics/new",
      component: <AddClinic />,
    },
    {
      key: "admin-clinics-edit",
      route: "/admin/clinics/edit/:id",
      component: <EditClinic />,
    },
    {
      key: "admin-clinics-view",
      route: "/admin/clinics/view/:id",
      component: <ViewClinic />,
    },
    {
      key: "admin-clinic-patients-view",
      route: "/admin/clinics/patients/view/:id",
      component: <ViewPatient />,
    },
    // {
    //   key: "admin-change-password",
    //   route: "/admin/change-password",
    //   component: <ResetPassword />,
    // },
    {
      key: "admin-settings-package-add-form",
      route: "/admin/settings/package/new",
      component: <AddPackage />,
    },
    {
      key: "admin-settings-package-edit-form",
      route: "/admin/settings/package/edit/:id",
      component: <EditPackage />,
    },
    {
      key: "admin-patients-view",
      route: "/admin/patients/view/:id",
      component: <ViewPatient />,
    },
    {
      key: "admin-financials",
      route: "/admin/financials",
      component: <Financials />,
    },
    {
      key: "admin-clinic-requests-view",
      route: "/admin/clinics/requests/view/:id",
      component: <ClinicRequestView />,
    },
  ],
  secretary: [
    {
      key: "secretary-clinics-view",
      route: "/secretary/clinics/view/:id",
      component: <ViewClinic />,
    },
    {
      key: "secretary-clinic-patients-view",
      route: "/secretary/clinics/patients/view/:id",
      component: <ViewPatient />,
    },
    // {
    //   key: "admin-change-password",
    //   route: "/admin/change-password",
    //   component: <ResetPassword />,
    // },
    {
      key: "secretary-patients-view",
      route: "/secretary/patients/view/:id",
      component: <ViewPatient />,
    },
    {
      key: "secretary-financials",
      route: "/secretary/financials",
      component: <Financials />,
    },
  ],
  agent: [
    {
      key: "agent-clinics-view",
      route: "/agent/clinics/view/:id",
      component: <ViewClinic />,
    },
    {
      key: "agent-clinic-patients-view",
      route: "/agent/clinics/patients/view/:id",
      component: <ViewPatient />,
    },
    {
      key: "agent-financials",
      route: "/agent/financials",
      component: <Financials />,
    },
    {
      key: "agent-clinics-new",
      route: "/agent/clinics/new",
      component: <AddClinic />,
    },
    {
      key: "agent-clinics-edit",
      route: "/agent/clinics/edit/:id",
      component: <EditClinic />,
    },
    {
      key: "agent-financials",
      route: "/agent/financials",
      component: <Financials />,
    },
  ],
  clinic: [
    {
      key: "clinic-plans",
      route: "/clinic/plans",
      component: <Plans />,
    },
    {
      key: "clinic-patients-new",
      route: "/clinic/patients/new",
      component: <AddPaient />,
    },
    {
      key: "clinic-patients-edit",
      route: "/clinic/patients/edit/:id",
      component: <EditPaient />,
    },
    {
      key: "clinic-patients-view",
      route: "/clinic/patients/view/:id",
      component: <ClinicViewPatient />,
    },
    {
      key: "clinic-no-credits-view",
      route: "/clinic/patients/no_credits",
      component: <NoCredits />,
    },
    {
      key: "frequency-test",
      route: "/clinic/patients/:patient_id/frequency-test",
      component: <FrequencyDiagnosis />,
    },
    {
      key: "clinic-payment-methods",
      route: "/clinic/payment-methods",
      component: <PaymentMethods />,
    },
    {
      key: "clinic-payment-methods-new",
      route: "/clinic/payment-methods/new",
      component: <AddPaymentMethod />,
    },
    {
      key: "clinic-statistics",
      route: "/clinic/statistics",
      component: <Statistics />,
    },
    {
      key: "clinic-profile",
      route: "/clinic/profile",
      component: <ClinicProfile />,
    },
    {
      key: "clinic-change-password",
      route: "/clinic/change-password",
      component: <ClinicChangePassword />,
    },
    {
      key: "clinic-change-language",
      route: "/clinic/change-language",
      component: <ClinicChangeLanguage />,
    },
  ],
};

export const sidebarRoutes = {
  super_admin: [
    {
      type: "collapse",
      noCollapse: true,
      name: "Dashboard",
      key: "superadmin-dashboard",
      layout: "dashboard",
      icon: <DashboardIcon fontSize="medium" />,
      route: "/super_admin/dashboard",
      component: <AdminDashboard />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Clinics",
      key: "superadmin-clinics",
      layout: "dashboard",
      icon: <OtherHousesOutlinedIcon fontSize="medium" />,
      route: "/super_admin/clinics",
      component: <Clinic />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Patients",
      key: "superadmin-patients",
      layout: "dashboard",
      icon: <PeopleOutlineIcon fontSize="medium" />,
      route: "/super_admin/patients",
      component: <Patients />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Financials",
      key: "superadmin-financials",
      layout: "dashboard",
      icon: <AddchartIcon fontSize="medium" />,
      route: "/super_admin/financials",
      component: <Financials />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Musics",
      key: "superadmin-musics",
      layout: "dashboard",
      icon: <GraphicEqIcon fontSize="medium" />,
      route: "/admin/musics",
      component: <Musics />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Settings",
      key: "superadmin-settings",
      layout: "dashboard",
      icon: <SettingsOutlinedIcon fontSize="medium" />,
      route: "/super_admin/settings",
      component: <Settings />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Users",
      key: "superadmin-users",
      layout: "dashboard",
      icon: <PersonOutlineOutlinedIcon fontSize="medium" />,
      route: "/super_admin/users",
      component: <Users />,
    },
  ],
  admin: [
    {
      type: "collapse",
      noCollapse: true,
      name: "Dashboard",
      key: "admin-dashboard",
      layout: "dashboard",
      icon: <DashboardIcon fontSize="medium" />,
      route: "/admin/dashboard",
      component: <AdminDashboard />,
      handleClick: () => Navigate(route)
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Clinics",
      key: "admin-clinics",
      layout: "dashboard",
      icon: <OtherHousesOutlinedIcon fontSize="medium" />,
      route: "/admin/clinics",
      component: <Clinic />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Patients",
      key: "admin-patients",
      layout: "dashboard",
      icon: <PeopleOutlineIcon fontSize="medium" />,
      route: "/admin/patients",
      component: <Patients />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Financials",
      key: "admin-financials",
      layout: "dashboard",
      icon: <AddchartIcon fontSize="medium" />,
      route: "/admin/financials",
      component: <Financials />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Music List",
      key: "admin-musics",
      layout: "dashboard",
      icon: <GraphicEqIcon fontSize="medium" />,
      route: "/admin/musics",
      component: <Musics />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Settings",
      key: "admin-settings",
      layout: "dashboard",
      icon: <SettingsOutlinedIcon fontSize="medium" />,
      route: "/admin/settings",
      component: <Settings />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Users",
      key: "admin-users",
      layout: "dashboard",
      icon: <PersonOutlineOutlinedIcon fontSize="medium" />,
      route: "/admin/users",
      component: <Users />,
    },
  ],
  agent: [
    {
      type: "collapse",
      noCollapse: true,
      name: "Dashboard",
      key: "agent-dashboard",
      layout: "dashboard",
      icon: <DashboardIcon fontSize="medium" />,
      route: "/agent/dashboard",
      component: <AdminDashboard />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Clinics",
      key: "agent-clinics",
      layout: "dashboard",
      icon: <OtherHousesOutlinedIcon fontSize="medium" />,
      route: "/agent/clinics",
      component: <Clinic />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Financials",
      key: "agent-financials",
      layout: "dashboard",
      icon: <AddchartIcon fontSize="medium" />,
      route: "/agent/financials",
      component: <Financials />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Settings",
      key: "agent-settings",
      layout: "dashboard",
      icon: <SettingsOutlinedIcon fontSize="medium" />,
      route: "/agent/settings",
      component: <Settings />,
    },
  ],
  secretary: [
    {
      type: "collapse",
      noCollapse: true,
      name: "Dashboard",
      key: "secretary-dashboard",
      layout: "dashboard",
      icon: <DashboardIcon fontSize="medium" />,
      route: "/secretary/dashboard",
      component: <AdminDashboard />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Clinics",
      key: "secretary-clinics",
      layout: "dashboard",
      icon: <OtherHousesOutlinedIcon fontSize="medium" />,
      route: "/secretary/clinics",
      component: <Clinic />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Patients",
      key: "secretary-patients",
      layout: "dashboard",
      icon: <PeopleOutlineIcon fontSize="medium" />,
      route: "/secretary/patients",
      component: <Patients />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Financials",
      key: "secretary-financials",
      layout: "dashboard",
      icon: <AddchartIcon fontSize="medium" />,
      route: "/secretary/financials",
      component: <Financials />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Music List",
      key: "secretary-musics",
      layout: "dashboard",
      icon: <GraphicEqIcon fontSize="medium" />,
      route: "/secretary/musics",
      component: <Musics />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Settings",
      key: "secretary-settings",
      layout: "dashboard",
      icon: <SettingsOutlinedIcon fontSize="medium" />,
      route: "/secretary/settings",
      component: <Settings />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Users",
      key: "secretary-users",
      layout: "dashboard",
      icon: <PersonOutlineOutlinedIcon fontSize="medium" />,
      route: "/secretary/users",
      component: <Users />,
    },
  ],
  clinic: [
    {
      type: "collapse",
      noCollapse: true,
      name: "Dashboard",
      key: "clinic-dashboard",
      layout: "dashboard",
      icon: <DashboardIcon fontSize="medium" />,
      route: "/clinic/dashboard",
      component: <ClinicDashboard />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Patients",
      key: "clinic-patients",
      layout: "dashboard",
      icon: <PeopleOutlineIcon fontSize="medium" />,
      route: "/clinic/patients",
      component: <ClinicPatients />,
    },
    {
      type: "collapse",
      noCollapse: true,
      name: "Transaction History",
      key: "clinic-transaction",
      layout: "dashboard",
      icon: <AutoGraphIcon fontSize="medium" />,
      route: "/clinic/transaction",
      component: <TransactionHistory />,
    },
  ],
};
