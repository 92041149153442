import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PersonIcon from "../../../../../assets/images/icons/patients/person-icon.svg"
import usePatientsService from "service-hooks/clinic/usePatientsService";
import Spinner from "../../../../components/Spinner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import ArgonButton from "components/ArgonButton";
import "../../style.css"
import ResetConfirmModal from "../ResetConfirmModal";
import { formatDate, formatTimestamp } from "parsers/formatParser";
import useFeedbackService from "service-hooks/clinic/useFeedbackService";
import PersonalInfo from "blikol-layouts/components/PatientDetail/PersonalInfo"
import PatientDiagnosis from "blikol-layouts/components/PatientDetail/PatientDiagnosis"
import SessionHistory from "blikol-layouts/components/PatientDetail/SessionHistory";
import "utils/i18n"
import { useTranslation } from "react-i18next";

function ViewPatient() {
    const navigate = useNavigate();
    const patientHookService = usePatientsService();
    const [loading, setLoading] = useState(true);
    const [pageRefresh, setPageRefresh] = useState(false)
    const [patientData, setPatientData] = useState();
    const userRole = useSelector((state) => state.auth?.user?.role);
    const [patientId, setPatientId] = useState(useParams().id);
    const [showResetConfirmation, setShowResetConfirmation] = useState(false);
    const [resetItemId, setResetItemId] = useState(null);
    const [currentFrequency, setCurrentFrequency] = useState()
    const feedbackHookService = useFeedbackService()
    const [sessionClick, setSessionClick] = useState(false);
    const [viewPatientDignosis, setviewPatientDignosis] = useState(true);
    const [sessionFrequency, setSessionFrequency] = useState()
    const [sessionDuration, setSessionDuration] = useState()
    const [feedbacks, setFeedbacks] = useState()
    const [resetButtonDisabledState, setresetButtonDisabledState] = useState(false)
    const { t } = useTranslation()



    useEffect(() => {
        patientHookService
            .patient(patientId)
            .then((result) => {
                if (result) {
                    const patient = result.data;
                    setLoading(false);
                    setPatientId(patient?.id);
                    setPatientData(
                        result.data
                    );
                    setCurrentFrequency(patient.current_frequency)
                    setresetButtonDisabledState(patient.current_frequency === null ? true : false)
                } else {
                    toast.error(t("No Patient with this id"));
                    navigate(`/${userRole}/patients`);
                }
            })
            .catch((error) => {
                console.error("Error fetching user data and attributes:", error);
            });
    }, [pageRefresh]);

    function frequencyFormat(value) {
        return value !== null ? `${value} Hz` : t("Pending Diagnosis");
    }


    const handleResetDiagnosis = () => {
        if (resetItemId) {
            patientHookService
                .resetDiagnosis(resetItemId)
                .then(() => {
                    setCurrentFrequency("")
                    toast.success(t('Diagnosis reset successfully.'));
                    setPageRefresh(!pageRefresh)
                })
                .catch((error) => {
                    toast.error(error);
                });
        }
        setShowResetConfirmation(false);
    };

    const handleReset = () => {
        if (patientData?.sessions.length > 0) {
            setResetItemId(patientData?.id);
            setShowResetConfirmation(true);
        } else {
            toast.error(t('No diagnosis available to reset.'));
        }
    };

    const handleSessionHistoryClick = (id, duration, frequency) => {
        setviewPatientDignosis(false)
        setSessionClick(true);
        setSessionDuration(duration)
        setSessionFrequency(frequency)
        feedbackHookService
            .feedbacks(id)
            .then((result) => {
                if (result) {
                    setFeedbacks(result)
                } else {
                    toast.error(t("No feedbacks found with this id"));
                    navigate(`/${userRole}/patients`);
                }
            })
            .catch((error) => {
                console.error("Error fetching user data and attributes:", error);
            });
    };

    const handleBackToPatientDiagnosisClick = () => {
        setviewPatientDignosis(true)
        setSessionClick(false);
    };

    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar customRoutePath={"Patients / Patient Detail"} />
            <ArgonBox px={3} pt={3}>
                <ArgonBox sx={{ display: "flex", justifyContent: "space-between" }}>
                    <ArgonTypography
                        mb={3}
                        mt={4}
                        sx={{
                            color: "var(--Default, #172B4D)",
                            fontSize: "25px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "137%",
                            letterSpacing: "-0.8px",
                        }}
                    >
                        {t("Patient Details")}
                    </ArgonTypography>
                    <ArgonTypography
                        mb={3}
                        mt={4}
                        sx={{
                            color: 'var(--Label, #8392AB)',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                        }}
                    >
                        {t("Current Frequency")}:
                        <span className="current-frequency-value">
                            {frequencyFormat(currentFrequency)}
                        </span>
                    </ArgonTypography>
                </ArgonBox>
                <Grid container spacing={3}>
                    <PersonalInfo patientData={patientData} />
                    <Grid item xs={12} sm={8} >
                        <Card >
                            {viewPatientDignosis && <ArgonBox
                                py={5}
                                px={4}
                                className="parent"
                            >
                                <ArgonTypography
                                    sx={{
                                        color: 'var(--Default, #172B4D)',
                                        fontFamily: 'Open Sans',
                                        fontSize: '25px',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        lineHeight: '137%',
                                        letterSpacing: '-0.8px',
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    {t("Patient Diagnosis")}
                                </ArgonTypography>
                                <ArgonBox className="button-size">
                                    <ArgonButton
                                        variant="outlined"
                                        color="info"
                                        sx={{
                                            borderRadius: "4px",
                                            border: "1px solid #0A9CC4",
                                            marginRight: "16px",
                                            marginLeft: "16px",
                                            width: "fit-content",
                                        }}
                                        onClick={handleReset}
                                        disabled={resetButtonDisabledState}
                                    >
                                        {t("Reset Diagnosis")}
                                    </ArgonButton>
                                    <ArgonButton
                                        variant="contained"
                                        color="info"
                                        sx={{
                                            display: 'inline-flex',
                                            padding: '10px 20px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '8px',
                                            borderRadius: '4px',
                                        }}
                                        onClick={() =>
                                            navigate(`/${userRole}/patients/${patientData?.id}/frequency-test`, {
                                                state: { patientName: patientData?.full_name }, // Pass the patient's name
                                            })
                                        }
                                    >
                                        {t("Set Tinnitus Frequency")}
                                    </ArgonButton>

                                </ArgonBox>

                            </ArgonBox>}
                            {viewPatientDignosis && (<PatientDiagnosis
                                sessionClick={sessionClick}
                                onSessionHistoryClick={handleSessionHistoryClick}
                                patientData={patientData}
                            />)}
                            {sessionClick && (<SessionHistory
                                onBackButtonClick={handleBackToPatientDiagnosisClick}
                                duration={sessionDuration}
                                frequency={sessionFrequency}
                                feedbacks={feedbacks}
                            />)}
                        </Card>

                    </Grid>
                </Grid>
                <ResetConfirmModal
                    open={showResetConfirmation}
                    onClose={() => setShowResetConfirmation(false)}
                    onConfirm={handleResetDiagnosis}
                />

            </ArgonBox>

        </DashboardLayout >
    )
}

export default ViewPatient