export const countryToLocale = {
    "United States": "en-US",
    "Taiwan": "en-CA",
    "Japan": "en-CA",
    "China": "en-CA",
    "South Korea": "en-CA",
};

export const countryToDateFormat = {
    "United States": "m/d/Y",
    "Taiwan": "Y-m-d",
    "Japan": "Y-m-d",
    "China": "Y-m-d",
    "South Korea": "Y-m-d"
};

export function formatDate(inputDate, locationExt) {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    return date.toLocaleDateString(locationExt ? locationExt : "en-GB", options);
}


export function formatTimestamp(timestamp, locationExt) {
    const date = new Date(timestamp);

    const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };
    const dateFormatted = new Intl.DateTimeFormat(locationExt ? locationExt : "en-GB", options).format(date);
    return dateFormatted
}

