import React, { useState, useEffect } from "react";
import Package from "./components/package";
import ArgonBox from "components/ArgonBox";
import usePackageService from 'service-hooks/usePackageService'

const PackageList=() => {
  const [packages, setPackages] = useState([]);
  const packageService = usePackageService()

  useEffect(() => {
    const fetchData = async () => {
    const response =  await packageService.packages()
    setPackages(response)
  }
  fetchData();    
  }, []);

  return (    
    packages?.map((packageObject) => (
      <ArgonBox   key={packageObject.id} mb={5}>
        <Package  packageObject={packageObject}  />
      </ArgonBox  >
    ))      
  )
}

export default PackageList