import { createSlice } from "@reduxjs/toolkit";

const generalSlice = createSlice({
  name: "general",
  initialState: {
    stepper: {
      activeStep: 0,
    },
  },

  reducers: {
    setActiveStep: (state, action) => {
      state.stepper.activeStep = Number(action.payload);
    },
    setNextStep: (state, action) => {
      state.stepper.activeStep = state.stepper.activeStep + 1;
    },
  },
});

export const { setActiveStep, setNextStep } = generalSlice.actions;

export const generalReducer = generalSlice.reducer;
