import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useClinicRequestService = () => {
  const axiosRequest = useBaseService().axiosRequest();

  const requests = async (currentPage, itemsPerPage, country = "", query = "", city = "") => {
    try {
      const encodedQuery = encodeURIComponent(query)
      const response = await axiosRequest.get(
        `administrator_apis/clinic_requests?page=${currentPage}&items_per_page=${itemsPerPage}&country=${country}&query=${encodedQuery}&city=${city}`
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);

      return false;
    }
  };

  const countries = async () => {
    try {
      const response = await axiosRequest.get(`administrator_apis/clinic_requests/countries`);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const cities = async () => {
    try {
      const response = await axiosRequest.get(`administrator_apis/clinic_requests/cities`);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const create = async (clinicRequestData) => {
    try {
      const response = await axiosRequest.post(`clinic_requests`, clinicRequestData);
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const reject = async (id, reason) => {
    try {
      const response = await axiosRequest.put(`administrator_apis/clinic_requests/${id}/reject`, {
        reason: reason,
      });
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false; // You can handle the error as needed in the calling code.
    }
  };

  const approve = async (id) => {
    try {
      const response = await axiosRequest.put(`administrator_apis/clinic_requests/${id}/approve`);
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false; // You can handle the error as needed in the calling code.
    }
  };

  const clinic = async (id) => {
    try {
      const response = await axiosRequest.get(`administrator_apis/clinic_requests/${id}`);
      return response
    } catch (error) {
      toast.error(error.response.data.error);
      return false; // You can handle the error as needed in the calling code.
    }
  };

  return {
    requests,
    cities,
    countries,
    reject,
    approve,
    create,
    clinic
  };
};

export default useClinicRequestService;
