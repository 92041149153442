/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PackageForm from "./packageForm"
import usePackageService from "service-hooks/usePackageService";
import { useParams } from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";

function EditPackage() {
  const [packageData, setPackageData] = useState({});
  const [packageId, setPackageId] = useState(useParams().id);
  const packageService = usePackageService()
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const userRole = useSelector((state) => state.auth?.user?.role);

  useEffect(() => {
    packageService.getPackage(packageId)
      .then((response) => {
        if (response) {
          setLoading(false);
          setPackageData({
            id: response?.id,
            credits: response?.credits,
            freeCredits: response?.free_credits,
            name: response?.name,
            price: response?.price,
            thumbnail_url: response?.thumbnail_url
          })
        } else {
          navigate(`/${userRole}/settings`);
        }
      })
  }, [])

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <DashboardLayout colorBgHeight={"110px"}>
          <DashboardNavbar customRoutePath={"Packages / Edit Package"} />
          <PackageForm
            packageId={packageId}
            heading={"Edit Package"}
            buttonText={"Update"}
            packageData={packageData}
          />
        </DashboardLayout>
      )}
    </>
  )
}

export default EditPackage;