import Grid from "@mui/material/Grid";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Spinner from "../../components/Spinner";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import { useState, useEffect, useMemo } from "react";
import LandingPageImg from "assets/images/icons/clinic/manager-desk.png";
import React from "react";

function Default() {
  const [loading, setLoading] = useState(false);

  return (
    <DashboardLayout colorBgHeight={"100px"}>
      <DashboardNavbar customRoutePath={"Dashboard"} />
      <ArgonBox
        sx={{
          marginLeft: "-26px !important",
          marginRight: "-24px !important",
        }}
      >
        <img
          src={LandingPageImg}
          style={{
            width: "100%",
            objectFit: "fill !important",
            height: `calc(100vh - 128px)`,
          }}
        />
      </ArgonBox>
    </DashboardLayout>
  );
}

export default Default;
