import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import Sidenav from "examples/Sidenav";

// Argon Dashboard 2 PRO MUI themes
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

import { applicationMappedRoutes, sidebarMappedRoutes, getRoutes } from "parsers/routeParser";

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController, setMiniSidenav } from "context";

// Images
import brand from "../src/assets/images/icons/sidenav/logo.png";

// Icon Fonts
import "assets/css/nucleo-icons.css";
import "assets/css/nucleo-svg.css";

import "utils/i18n"
import { useTranslation } from "react-i18next";
import ExternalRedirect from "blikol-layouts/redirection"

export default function App() {
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const userRole = useSelector((state) => state.auth?.user?.role);
  const user = useSelector((state) => state.auth?.user);
  const { pathname } = useLocation();
  const roleBasedSideBarRoutes = sidebarMappedRoutes(userRole);
  const { t, i18n } = useTranslation();

  // Determine if the current route should hide the sidenav
  const shouldHideSidenav = ["/iphone", "/android"].includes(pathname);

  // Open sidenav when mouse enters mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const queryParams = new URLSearchParams(location.search);
  const paymentSuccess = queryParams.get("redirect_status");
  if (paymentSuccess === "succeeded") {
    const updatedUrl = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, updatedUrl);
    i18n.changeLanguage(user?._language);
    toast.success(t("Payment Method added successfully"));
  }

  // Close sidenav when mouse leaves mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    i18n.changeLanguage(user?._language);
  }, [user?._language]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ width: "27vw" }}
      />
      {/* Conditionally render the Sidenav */}
      {!shouldHideSidenav && roleBasedSideBarRoutes.length > 0 && (
        <Sidenav
          color={controller.sidenavColor}
          brand={brand}
          brandName="Blikol"
          routes={roleBasedSideBarRoutes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <Routes>
        {getRoutes(applicationMappedRoutes(userRole))}
        {getRoutes(roleBasedSideBarRoutes)}
        <Route
          path="/iphone"
          element={<ExternalRedirect url="https://testflight.apple.com/join/68l2jTa1" userRole />}
        />
        <Route
          path="/android"
          element={<ExternalRedirect url="https://play.google.com/store/apps/details?id=com.brainx.tinnitus&hl=en" userRole />}
        />
      </Routes>
    </ThemeProvider>
  );
}