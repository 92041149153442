import React from 'react';
import PropTypes from 'prop-types';
import visaCard from "assets/images/cards/visa-light-large (1).svg"
import masterCard from "assets/images/cards/mastercard-light-large.svg"
import maestroCard from "assets/images/cards/maestro-light-large.svg"
import amexCard from "assets/images/cards/americanexpress-light-large.svg"
import circusCard from "assets/images/cards/cirrus-light-large.svg"
import dinnerClubCard from "assets/images/cards/dinnerclub-light-large.svg"
import discoverCard from "assets/images/cards/discover-light-large.svg"
import jsbCard from "assets/images/cards/jcb-light-large.svg"
import payPal from "assets/images/cards/paypal-light-large.svg"
import ArgonBox from 'components/ArgonBox';

// Import your SVG icons or any other icons here

const cardBrands = {
    visa: visaCard,
    mastercard: masterCard,
    amex: amexCard,
    discover: discoverCard,
    diners: dinnerClubCard,
    jcb: jsbCard,
    // unionpay: "UnionPay",
    maestro: maestroCard,
    // elo: "Elo",
    // hipercard: "Hipercard"
};

function BrandIcon({ brandName }) {
    const Icon = cardBrands[brandName.toLowerCase()];
    return Icon ? <ArgonBox component="img" src={Icon} height="25px" alt={`${brandName} icon`} ml={2} /> : null;
}

BrandIcon.propTypes = {
    brandName: PropTypes.string.isRequired,
};

export default BrandIcon;
