import { useDispatch } from "react-redux";
import useBaseService from "../useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useClinicService = () => {
    const dispatch = useDispatch();
    const axiosRequest = useBaseService().axiosRequest();

    const clinic = async (id) => {
        try {
            const response = await axiosRequest.get(`clinic_apis/clinics/${id}`);
            return response;
        } catch (error) {
            toast.error(error.response.data.error);
            return false; // You can handle the error as needed in the calling code.
        }
    };

    const update = async (clinicData, id) => {
        try {
            const response = await axiosRequest.put(`clinic_apis/clinics/${id}`, clinicData);
            return response;
        } catch (error) {
            toast.error(error.response.data.error);
            return false; // You can handle the error as needed in the calling code.
        }
    };





    return {
        clinic,
        update,
    };
};

export default useClinicService;
