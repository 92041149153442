import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PropTypes from "prop-types";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import Tooltip from '@mui/material/Tooltip';

function ActionMenu({ threeDots, row, customIcons }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e?.stopPropagation()
    setAnchorEl(null);
  };

  return (
    <div className="fixed-col">
      <ArgonBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {customIcons.map((icon, index) => (
          <Tooltip key={index} title={icon.tooltipText ? icon.tooltipText : ""} >
            <Button
              key={index}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: "1.75rem !important",
                },
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                minWidth: 40,
              }}
              onClick={(event) => {
                event.stopPropagation()
                icon.action(row.id);
              }}
            >
              {icon.icon}
            </Button>
          </Tooltip>
        ))}

        {/* Last Column */}
        {threeDots && threeDots.length > 0 && (
          <>
            <Button
              sx={{
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                minWidth: 40,
                "& .MuiSvgIcon-root": {
                  fontSize: "1.25rem !important",
                },
              }}
              aria-controls="dropdown-menu"
              aria-haspopup="true"
              onClick={(e) => {
                handleClick(e)
              }}
            >
              <MoreVertIcon />
            </Button>

            <Menu
              id="dropdown-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {threeDots.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={(event) => {
                    event.stopPropagation()
                    item.action(row.id);
                    handleClose();
                  }}
                  sx={{ color: item.style?.color }}
                >
                  {item.icon}
                  {item.type}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </ArgonBox>
    </div >
  );
}

ActionMenu.defaultProps = {
  threeDots: [],
  row: null,
  customIcons: [],
};

ActionMenu.propTypes = {
  threeDots: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      icon: PropTypes.node.isRequired,
      style: PropTypes.object,
    })
  ),
  row: PropTypes.any,
  customIcons: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      icon: PropTypes.node.isRequired,
      tooltipText: PropTypes.string
    })
  ),
};

export default ActionMenu;
