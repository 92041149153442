import { forwardRef } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
// import Select from "react-select";
import CreatableSelect, { components } from "react-select"; // Correctly importing components from react-select

// Argon Dashboard 2 PRO MUI base styles
import colors from "assets/theme/base/colors";

// Argon Dashboard 2 PRO MUI context
import { useArgonController } from "context";

// Custom styles for CustomCreateableSelect
import styles from "components/CustomCreateableSelect/styles";

const CustomInput = (props) => (
    <components.Input
        {...props}
        autoCapitalize="none"
        autoComplete="none"
        autoCorrect="off"
        spellCheck="false"
    />
);

const CustomCreateableSelect = forwardRef(({ size, error, success, ...rest }, ref) => {
    const [controller] = useArgonController();
    const { darkMode } = controller;
    const { light } = colors;

    const handleKeyDown = (event) => {
        const input = event.target;
        if (event.key === 'Home') {
            input.setSelectionRange(0, 0);
        } else if (event.key === 'End') {
            const endPosition = input.value.length;
            input.setSelectionRange(endPosition, endPosition);
        }
    };

    return (
        <CreatableSelect
            onKeyDown={handleKeyDown}
            ref={ref}
            styles={styles(size, error, success, darkMode)}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary25: light.main,
                    primary: light.main,
                },
            })}
            components={{ Input: CustomInput }} // Pass custom Input component
            {...rest}
        />
    );
});

// Setting default values for the props of CustomCreateableSelect
CustomCreateableSelect.defaultProps = {
    size: "medium",
    error: false,
    success: false,

};

// Typechecking props for the CustomCreateableSelect
CustomCreateableSelect.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    error: PropTypes.bool,
    success: PropTypes.bool,
    color: PropTypes.string
};

export default CustomCreateableSelect;
