/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import usePackageService from 'service-hooks/usePackageService'
import { useNavigate } from "react-router-dom";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonBadge from "components/ArgonBadge";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";


function Package({ packageObject }) {
  const [openMenu, setOpenMenu] = useState(null);
  const [status, setStatus] = useState(packageObject.status);

  const packageService = usePackageService()
  const userRole = useSelector((state) => state.auth?.user?.role);


  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const navigate = useNavigate();

  async function handleEdit() {
    navigate(`/${userRole}/settings/package/edit/${packageObject.id}`);
    setOpenMenu(null);
  }

  async function handleUpdate() {
    const formData = new FormData()
    formData.append("status", !status)

    const response = await packageService.update(packageObject.id, formData)
    if (response) setStatus(!status);
    setOpenMenu(null);
  }


  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
      <MenuItem onClick={handleUpdate} style={{ color: status ? "red" : "green" }}>
        {status ? "Deactive" : "Active"}</MenuItem>
    </Menu>
  );
  return (
    <Grid item >
      <Card sx={{ overflow: "hidden" }}>
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"

          py={2}
          px={3}>
          <ArgonBox py={3}>
            <ArgonTypography variant="h5">
              {packageObject.name}
            </ArgonTypography>
          </ArgonBox>

          <ArgonBox ml={{ xs: 0, sm: "auto" }} >
            <ArgonBadge color={status ? "info" : "secondary"} badgeContent={status ? "Active" : "Inactive"} size="sm" container />
          </ArgonBox>
          {renderMenu()}
        </ArgonBox>

        <ArgonBox
          display="flex"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}

          ml={3}
          pl={0.5}
          pb={5} pr={3}
        >
          <ArgonBox lineHeight={1} mb={{ xs: 1, sm: 0 }}>
            <ArgonTypography
              display="block"
              variant="caption"
              fontWeight="medium"
              color="secondary"
            >
              Price
            </ArgonTypography>
            <ArgonTypography variant="caption" fontWeight="bold" color="text">
              €{packageObject.price}
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox ml={{ xs: 0, sm: 5 }} mb={{ xs: 1, sm: 0 }} lineHeight={1}>
            <ArgonTypography
              display="block"
              variant="caption"
              fontWeight="medium"
              color="secondary"
            >
              Number of Credits
            </ArgonTypography>
            <ArgonTypography variant="caption" fontWeight="bold" color="text">
              {packageObject.credits}
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox ml={{ xs: 0, sm: 5 }} lineHeight={1}>
            <ArgonTypography
              display="block"
              variant="caption"
              fontWeight="medium"
              color="secondary"
            >
              Free Credits
            </ArgonTypography>
            <ArgonTypography variant="caption" fontWeight="bold" color="text">
              {packageObject.free_credits}
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox ml="auto" color="secondary" lineHeight={0}>
            <Icon fontSize="default" sx={{ cursor: "pointer" }} onClick={handleOpenMenu}>
              more_horiz
            </Icon>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </Grid>
  );
}

Package.propTypes = {
  packageObject: PropTypes.object
};

export default Package;