import { useNavigate } from "react-router-dom";
import ClinicForm from "blikol-layouts/clinics/components/clinicForm";

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonImage from "components/ArgonImage";
// Authentication layout components
import BasicLayout from "blikol-layouts/components/BasicLayout";
import useClinicRequestService from "service-hooks/useClinicRequestService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Images

function Registration() {
  const navigate = useNavigate();
  const clinicRequestHookService = useClinicRequestService();

  const handleSubmit = (values) => {
    clinicRequestHookService
      .create(values)
      .then((result) => {
        if (result) {
          toast.success("Clinic Request Submitted successfully.");
          navigate(`/user/login`);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.error);
        navigate(`/user/login`);
      });
  };

  return (
    <BasicLayout xl={10} lg={10}>
      <ArgonBox
        sx={{
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <ArgonImage source="/BLIKOL_logo.png" width="163.746px" mb="20px" />
        <ArgonBox
          sx={{
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <ArgonTypography
            sx={{
              color: "var(--Default, #172B4D)",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "137%",
              letterSpacing: "-0.8px",
              marginLeft: "20px",
            }}
          >
            Register Clinic
          </ArgonTypography>
        </ArgonBox>

        <Card>
          <ClinicForm buttonText={"Submit"} justifyContent={"center"} handleSubmit={handleSubmit} />
        </Card>
      </ArgonBox>
    </BasicLayout>
  );
}

export default Registration;
