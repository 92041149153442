import React from "react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

function ArgonImage({ source, width, mb }) {
  const imgContainerStyles = {
    textAlign: "center",
    marginBottom: mb,
  };

  const imgStyles = {
    width: width,
  };

  return (
    <div style={imgContainerStyles}>
      <img
        src={source} // Replace with the URL of your image
        alt="Centered Image"
        style={imgStyles}
      />
    </div>
  );
}

// Typechecking props for the ArgonBox
ArgonImage.propTypes = {
  source: PropTypes.string,
  mb: PropTypes.string,
  width: PropTypes.string,
};
export default ArgonImage;
