import useBaseService from "./useBaseService";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const usePackageService = () => {
  const axiosRequest = useBaseService().axiosRequest();

  const packages = async () => {
    try {
      const response = await axiosRequest.get("administrator_apis/packages");
      return response.data;

    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const create = async (formData) => {
    try {
      const response = await axiosRequest.post("administrator_apis/packages", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success(response.data);
      return true
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const getPackage = async (packageId) => {
    try {
      const response = await axiosRequest.get(`administrator_apis/packages/${packageId}`);
      return response.data
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const update = async (packageId, formData) => {
    try {
      const response = await axiosRequest.patch(`administrator_apis/packages/${packageId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success(response.data?.message);
      return true

    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  return {
    packages,
    create,
    getPackage,
    update
  };
};

export default usePackageService;
