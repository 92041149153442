import { useDispatch } from "react-redux";
import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useQuestionService = () => {
    const dispatch = useDispatch();
    const axiosRequest = useBaseService().axiosRequest();

    const questions = async () => {
        try {
            const response = await axiosRequest.get("/administrator_apis/feedback_questions");
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error[0]);
            return false;
        }
    };

    const create = async (questionData) => {
        try {
            const response = await axiosRequest.post(`administrator_apis/feedback_questions`, { "question": questionData });
            return response;
        } catch (error) {
            toast.error(error.response.data.error[0]);
            return false;
        }
    };

    const destroy = async (id) => {
        try {

            const response = await axiosRequest.delete(`administrator_apis/feedback_questions/${id}`);
            return response;
        } catch (error) {
            return error.response.data.error;
        }
    };

    const update = async ({ id, questionData }) => {
        try {
            const response = await axiosRequest.put(`administrator_apis/feedback_questions/${id}`, { "question": questionData });
            return response;
        } catch (error) {
            toast.error(error.response.data.error[0]);
            return false; // You can handle the error as needed in the calling code.
        }
    };

    return {
        questions,
        destroy,
        create,
        update,
    };
};

export default useQuestionService;
