import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PersonIcon from "assets/images/icons/patients/person-icon.svg"
import { formatDate, countryToLocale } from "blikol-layouts/clinic/parsers/dateFormatParser";
import PropTypes from "prop-types";
import { languageMapping } from "parsers/formatParser";
import "utils/i18n"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";


function PersonalInfo({ patientData }) {
    const { t } = useTranslation()
    const userCountry = useSelector((state) => state.auth?.user?.country);

    const fieldNameStyle = {
        color: 'var(--Label, #8392AB)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }
    const fieldValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        textTransform: "capitalize",

    }
    const fieldEmailValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }

    return (

        <Grid item xs={12} sm={4} >
            <Card
                sx={{
                    borderRadius: '20px',
                    background: '#FFF',
                    boxShadow: '0px 5px 14px 0px rgba(0, 0, 0, 0.05)',
                }}
            >
                <ArgonBox
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}
                >
                    <ArgonBox sx={{ padding: "30px" }}>
                        <img src={PersonIcon} alt="PersonIcon" />
                    </ArgonBox>
                    <ArgonBox sx={{ display: "flex", flexDirection: "column" }}>
                        <ArgonTypography
                            sx={{
                                color: "#000",
                                fontSize: "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                            }}
                            mb={0.5}
                        >
                            {patientData?.full_name}
                        </ArgonTypography>
                        <ArgonTypography
                            sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                                textTransform: "capitalize"
                            }}
                        >
                            {patientData?.gender}

                        </ArgonTypography>

                    </ArgonBox>

                </ArgonBox>
                <Grid item xs={12} sm={12} p={3}>
                    <ArgonTypography
                        sx={{
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '137%',
                            letterSpacing: '-0.8px',
                        }}
                        mb={2}
                    >
                        {t("Tinnitus Info")}
                    </ArgonTypography>

                    <Card
                        sx={{
                            padding: '30px',
                            gap: '10px',
                            borderRadius: '20px',
                            background: '#F4F2F2',
                        }}
                    >
                        <ArgonBox
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: '16px',
                            }}
                        >
                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {t("Tinnitus Type")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldValueStyle}
                                    my={1}
                                >
                                    {patientData?.tinnitus_type?.replace(/_/g, ' ')}
                                </ArgonTypography>
                            </ArgonBox>

                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {t("Ears")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldValueStyle}
                                    my={1}
                                >
                                    {patientData?.ears}
                                </ArgonTypography>
                            </ArgonBox>

                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {t("Start date of tinnitus")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldValueStyle}
                                    my={1}
                                >
                                    {formatDate(patientData?.tinnitus_start_date, countryToLocale[userCountry])}
                                </ArgonTypography>
                            </ArgonBox>


                        </ArgonBox>


                    </Card>
                    <ArgonTypography
                        sx={{
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '137%',
                            letterSpacing: '-0.8px',
                        }}
                        my={2}
                    >
                        {t("Basic Info")}
                    </ArgonTypography>

                    <Card
                        sx={{
                            padding: '30px',
                            gap: '10px',
                            borderRadius: '20px',
                            background: '#F4F2F2',
                        }}
                    >
                        <ArgonBox
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: '16px',
                            }}
                        >
                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {t("Phone")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldValueStyle}
                                    my={1}
                                >
                                    {patientData?.phone_number}
                                </ArgonTypography>
                            </ArgonBox>

                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {t("Email")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldEmailValueStyle}
                                    my={1}
                                >
                                    {patientData?.email}
                                </ArgonTypography>
                            </ArgonBox>

                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {t("Date of Birth")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldValueStyle}
                                    my={1}
                                >
                                    {formatDate(patientData?.dob, countryToLocale[userCountry])} ({t("Age")}: {patientData?.age})
                                </ArgonTypography>
                            </ArgonBox>
                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {("Address")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldValueStyle}
                                    my={1}
                                >
                                    {patientData?.address1}
                                </ArgonTypography>
                            </ArgonBox>

                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {t("City")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldValueStyle}
                                    my={1}
                                >
                                    {patientData?.city}
                                </ArgonTypography>
                            </ArgonBox>

                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {t("Postal Code")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldValueStyle}
                                    my={1}
                                >
                                    {patientData?.postal_code}
                                </ArgonTypography>
                            </ArgonBox>

                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {t("State/Province")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldValueStyle}
                                    my={1}
                                >
                                    {patientData?.state}
                                </ArgonTypography>
                            </ArgonBox>

                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {t("Country")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldValueStyle}
                                    my={1}
                                >
                                    {patientData?.country}
                                </ArgonTypography>
                            </ArgonBox>

                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {t("Language")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldValueStyle}
                                    my={1}
                                >
                                    {languageMapping[patientData?._language]}
                                </ArgonTypography>
                            </ArgonBox>

                        </ArgonBox>
                    </Card>
                    <ArgonTypography
                        sx={{
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '137%',
                            letterSpacing: '-0.8px',
                        }}
                        my={2}
                    >
                        {t("Notes")}
                    </ArgonTypography>

                    <Card
                        sx={{
                            padding: '30px',
                            gap: '10px',
                            borderRadius: '20px',
                            background: '#F4F2F2',
                        }}
                    >
                        <ArgonBox
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: '16px',
                            }}
                        >
                            <ArgonBox>
                                <ArgonTypography
                                    sx={fieldNameStyle}
                                >
                                    {t("Note")}
                                </ArgonTypography>
                                <ArgonTypography
                                    sx={fieldValueStyle}
                                    my={1}
                                >
                                    {patientData?.note}
                                </ArgonTypography>
                            </ArgonBox>
                        </ArgonBox>


                    </Card>

                </Grid>

            </Card>

        </Grid>
    )
}
PersonalInfo.propTypes = {
    patientData: PropTypes.object
};

export default PersonalInfo