import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Spinner from "../../../components/Spinner";

import PropTypes from "prop-types";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import "utils/i18n";
import { useTranslation } from "react-i18next";

const style = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  padding: "24px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  margin: "auto",
  borderRadius: "15px",
  background: "#FFF",
};

function ConfirmDialogue({ handleClose, handleConfirm }) {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  const handleCloseModal = () => {
    setOpen(false);
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <ArgonBox display="flex" width="100%" flexDirection="column" gap="1rem" p="1rem">
              <ArgonTypography sx={{ fontWeight: "600" }}>
                {t("Are you sure you want to save this frequency?")}
              </ArgonTypography>

              <ArgonBox display="flex" justifyContent="flex-end" gap="1rem">
                <ArgonButton color="info" variant="outlined" onClick={handleClose}>
                  {t("Cancel")}
                </ArgonButton>
                <ArgonButton sx={{ px: "2rem" }} color="info" onClick={handleConfirm}>
                  {t("Yes")}
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
ConfirmDialogue.propTypes = {
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
};
export default ConfirmDialogue;
