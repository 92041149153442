import { useDispatch } from "react-redux";
import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useClinicService = () => {
  const dispatch = useDispatch();
  const axiosRequest = useBaseService().axiosRequest();
  const multiPartAxiosRequest = useBaseService().multiPartAxiosRequest();

  const musics = async (currentPage, itemsPerPage, type = "", query = "") => {
    try {
      const encodedQuery = encodeURIComponent(query)
      const response = await axiosRequest.get(
        `administrator_apis/musics?page=${currentPage}&items_per_page=${itemsPerPage}&_type=${type}&query=${encodedQuery}`
      );
      const musicData = response.data.musics;
      const musicMeta = response.data.meta;
      return { musicData, musicMeta };
    } catch (error) {
      console.error("Failed to retrieve clinics:", error);
      return null;
    }
  };

  const types = async () => {
    try {
      const response = await axiosRequest.get(`administrator_apis/musics/types`);

      const types = response;
      return { types };
    } catch (error) {
      toast.error(error.response.data.error[0]);
      return false;
    }
  };

  const create = async (formData) => {
    try {
      const response = await multiPartAxiosRequest.post(`administrator_apis/musics`, formData);
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.error[0]);
      return false;
    }
  };

  const destroy = async (id) => {
    try {
      const response = await axiosRequest.delete(`administrator_apis/musics/${id}`);
      return response;
    } catch (error) {
      return error.response.data.error;
    }
  };

  return {
    musics,
    types,
    destroy,
    create,
  };
};

export default useClinicService;
