import { useState, useEffect, useMemo } from "react";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTransactionHistoryService from '../../../../../service-hooks/clinic/useTransactionHistoryService'
import DownloadIcon from "assets/images/icons/clinic/download.svg"
import ActionMenu from "blikol-layouts/components/ActionMenu";
import React from 'react'
import useBaseService from "../../../../../service-hooks/useBaseService";
import useExportCSVService from "../../../../../service-hooks/useExportCSVService";
import Spinner from "blikol-layouts/components/Spinner";
import useDownloadReceipt from "service-hooks/useDownloadReceipt";
import { formatDate, countryToLocale } from "blikol-layouts/clinic/parsers/dateFormatParser";
import useDownloadInvoice from "service-hooks/useDownloadInvoice";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ReceiptIcon from "assets/images/icons/blikol/Receipt-Alt--Streamline-Unicons.svg"
import InvoiceIcon from "assets/images/icons/blikol/Invoice--Streamline-Unicons.svg"
import { useTranslation } from "react-i18next";
import "utils/i18n"
function History() {
    const transactionServiceHook = useTransactionHistoryService()
    const exportCSVServiceHook = useExportCSVService()
    const { user, token } = useSelector((state) => state.auth)
    const userCountry = user?.country
    const [clinicId, setClinicId] = useState(user?.id)
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [data, setData] = useState();
    const [search, setSearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [totalEntries, setTotalEntries] = useState();
    const [totalPages, setTotalPages] = useState();
    const [dateRange, setDateRange] = useState("")
    const [tabValueForService, setTabValueForService] = useState("credits_history")
    const [loading, setLoading] = useState(true)
    const downloadReceiptHook = useDownloadReceipt()
    const downloadInvoiceHook = useDownloadInvoice()
    const { t, i18n } = useTranslation()

    useEffect(() => {
        transactionServiceHook
            .history(pageNo, entriesPerPage, search, dateRange, clinicId)
            .then((result) => {
                if (result && result?.meta && result?.payments) {
                    setData(result);
                    setLoading(false)
                    setTotalEntries(result?.meta?.total_count);
                    setTotalPages(result?.meta?.total_pages);
                } else {
                    toast.error(t("Data is undefined or incomplete"));
                }
            })
            .catch((error) => {
                // Handle the error (e.g., display an error message)
                toast.error(t("Error fetching user data and attributes:"), error);
            });

    }, [
        entriesPerPage,
        pageNo,
        search,
        dateRange
    ]
    );

    const handleDateRangeChange = (newDateRange) => {
        setDateRange(newDateRange);
    };

    const downloadFile = (url, toastMessage, fileName) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setTimeout(() => {
            setLoading(false);
            toast.success(toastMessage);
        }, 3000);
    };

    const handleReceiptDownload = (id) => {
        downloadReceiptHook
            .downloadReceipt(id)
            .then((result) => {
                setLoading(true)
                if (result) {
                    const url = result.receipt_url;
                    downloadFile(url, t("Receipt downloaded successfully"), "Receipt")
                } else {
                    toast.error(t('Error downloading receipt'));
                }
            })
            .catch((error) => {
                toast.error(t('Error fetching user data and attributes:'), error);
            });
    };

    const handleInvoiceDownload = (id) => {
        downloadInvoiceHook
            .downloadInvoice(id)
            .then((result) => {
                setLoading(true)
                if (result) {
                    const url = result.invoice_url;
                    downloadFile(url, t("Invoice downloaded successfully"), "Invoice")
                } else {
                    toast.error(t('Error downloading receipt'));
                }
            })
            .catch((error) => {
                toast.error(t('Error fetching user data and attributes:'), error);
            });
    };


    const handleExportCSV = () => {
        const pathname = `/clinic_apis/clinics/${clinicId}/${tabValueForService}_export`;
        const queryParams = new URLSearchParams({ query: search });
        const fullPath = `${pathname}?${queryParams.toString()}`;
        const filename = `clinic_${clinicId}_transaction_history`;

        exportCSVServiceHook.exportCSV(fullPath, filename);
    };


    const customIcons = [

        {
            action: handleReceiptDownload,
            icon: <img src={ReceiptIcon} height={"20px"} alt="Download-Receipt" />,
            tooltipText: t("Receipt")
        },
        {
            action: handleInvoiceDownload,
            icon: <img src={InvoiceIcon} height={"20px"} alt="Download-Invoice" />,
            tooltipText: t("Invoice")
        },
    ];

    function amountColumn(value) {
        const parsedValue = parseFloat(value);

        if (!isNaN(parsedValue)) {
            const formattedValue = parsedValue.toFixed(2);
            return `€${formattedValue}`;
        } else {
            return value;
        }
    }

    const paymentsHistoryRowsData = data?.payments?.map((payment, index) => ({
        ...payment,
        amount: amountColumn(payment?.amount),
        date: formatDate(payment?.date, countryToLocale[userCountry]),
        action: (
            <ActionMenu
                row={payment}
                customIcons={customIcons}
                key={index + 1}
            />
        ),
    })) ?? [];

    const tableDataHistory = {
        columns: [
            { Header: t("date"), accessor: "date" },
            { Header: t("order number"), accessor: "registration_id" },
            { Header: t("package"), accessor: "package_name" },
            { Header: t("amount"), accessor: "amount" },
            { Header: t("credits purchased"), accessor: "credits" },
            { Header: t("free credits"), accessor: "free_credits" },
            { Header: t("action"), accessor: "action" }
        ],
        rows: paymentsHistoryRowsData,
    };

    return (
        <>
            {loading && <Spinner />}
            {!loading && (<DataTable
                canExportCSV={{
                    name: "Export as CSV",
                    action: handleExportCSV
                }}
                canSearch
                canFilter
                dateFilter
                onDateRangeChange={handleDateRangeChange}
                dataTableName={t("Transaction History")}
                entriesPerPage={entriesPerPage}
                setSearch={setSearch}
                search={search}
                totalPages={totalPages}
                setEntriesPerPage={setEntriesPerPage}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalEntries={totalEntries}
                table={tableDataHistory}
            />)}
        </>
    )
}

export default History