import React from "react";
import { CircularProgress, Box } from "@mui/material";
const ProgressBar = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "1rem" }}>
      <CircularProgress color="info" />
    </Box>
  );
};

export default ProgressBar;
