import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { toast, ToastContainer } from "react-toastify";
import PropTypes from 'prop-types';
import ProgressBar from "components/ProgressBar/ProgressBar";
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArgonTypography from "components/ArgonTypography";
import ConfirmModal from "./ConfirmModal";


const style = {
    display: "flex",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "575px",
    height: "414px",
    padding: "24px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    margin: "auto",
    borderRadius: "15px",
    background: "#FFF",

};

export default function UploadModal({ open, handleClose, setData, data }) {
    const [audioFile, setAudioFile] = useState();
    const [confirmModalState, setConfirmModalState] = useState(false)
    const [spinner, setSpinner] = useState(false);

    const approvedAudioTypes = ["audio/mp3", "audio/mpeg", "audio/ogg"];

    const handleFileChange = async (event) => {
        event.preventDefault();
        const selectedFile = event.target.files[0];

        if (selectedFile && approvedAudioTypes.includes(selectedFile.type)) {
            setSpinner(true);
            setAudioFile(selectedFile);
        } else {
            toast.error("Only MP3 files are allowed");
        }

        setSpinner(false);
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        const selectedFile = event.dataTransfer.files[0];

        if (selectedFile && approvedAudioTypes.includes(selectedFile.type)) {
            setSpinner(true);
            setAudioFile(selectedFile);
        } else {
            toast.error("Only MP3 files are allowed");
        }

        setSpinner(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleUpload = () => {
        if (audioFile) {
            setConfirmModalState(true)
            handleClose()

        }
        else {
            toast.error("Please upload an MP3 file first");
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {spinner && <ProgressBar />}
                    <ArgonTypography component="label" variant="caption" sx={{
                        fontSize: "20px",
                        lineHeight: "20px",
                        color: "var(--Wireframes-Primary, var(--Body, #373D3F)) !important",
                        fontStyle: "normal",
                        fontWeight: "600 !important"
                    }}>
                        Upload Music
                    </ArgonTypography>

                    <div
                        role="button"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        style={{
                            borderRadius: "5px",
                            padding: "25px",
                            textAlign: "center",
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                        }}
                        onClick={() => document.getElementById("file-input").click()}
                    >
                        <CloudUploadIcon color="info" fontSize="large" />
                        <div>
                            <Typography variant="h6" color={"grey"}>
                                {audioFile?.name}
                            </Typography>
                        </div>
                        <ArgonBox sx={{ padding: "0" }}>
                            <ArgonTypography sx={{
                                color: "var(--Text, #1A1D1F)",
                                textAlign: "center",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "150%",
                                paddingTop: "10px",
                                paddingBottom: "10px"


                            }}>
                                Drop MP3 File to Upload
                            </ArgonTypography>
                            <ArgonTypography sx={{
                                color: "#141416",
                                textAlign: "center",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "150%",
                                paddingBottom: "10px"
                            }}>
                                OR
                            </ArgonTypography>
                            <ArgonTypography sx={{
                                color: "var(--Primary-Color, #0A9CC4)",
                                textAlign: "center",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "22px",


                            }}>
                                Select from system
                            </ArgonTypography>
                            <input
                                type="file"
                                accept="audio/mp3"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                id="file-input"
                            />
                        </ArgonBox>
                    </div>

                    <ArgonBox>
                        <ArgonButton
                            variant="outlined"
                            color="info"
                            sx={{
                                borderRadius: "4px",
                                border: "1px solid #0A9CC4",
                                margin: "0px 16px 0px 16px",
                                width: "124px",
                                fontSize: "small"
                            }}
                            onClick={() => {
                                handleClose();
                                setAudioFile();
                            }}

                        >
                            Cancel
                        </ArgonButton>


                        <ArgonButton
                            variant="contained"
                            color="info"
                            sx={{
                                borderRadius: "4px",
                                width: "124px",
                                fontSize: "small",

                            }}
                            onClick={handleUpload}
                        >
                            Proceed
                        </ArgonButton>
                    </ArgonBox>
                </Box>
            </Modal>
            <ConfirmModal
                open={confirmModalState}
                handleConfirmClose={() => setConfirmModalState(false)}
                audio={audioFile}
                setAudio={setAudioFile}
                data={data}
                setData={setData}


            />

        </div>

    );
}
UploadModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    data: PropTypes.any,
    setData: PropTypes.any

};
