import React from "react";
import Grid from "@mui/material/Grid";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import { useState, useEffect, useMemo } from "react";
import useFinancialsService from "service-hooks/useFinancialsService";
import usePackageService from "service-hooks/usePackageService";
import DetailedStatisticsCard from "blikol-layouts/components/DetailedStatisticsCard";
import Card from "@mui/material/Card";
import useExportCSVService from "../../service-hooks/useExportCSVService";
import ArgonSelect from "components/ArgonSelect";
import { DateRangePicker, Stack } from "rsuite";
import useClinicService from "service-hooks/useClinicService";
import MoneyIcon from "assets/images/icons/admin/Icon.png";
import select from "assets/theme-dark/components/form/select";
import DataTable from "examples/Tables/DataTable";
import { formatName, formatDate } from "parsers/formatParser";
import Spinner from "blikol-layouts/components/Spinner";
function Financials() {
  const [loading, setLoading] = useState(true);
  const financialHookService = useFinancialsService();
  const packageHookService = usePackageService();
  const [selectedOption, setSelectedOption] = useState({ package: "", country: "", city: "" });
  const [stats, setStats] = useState();
  const [dateRange, setDateRange] = useState("");
  const [listOfCountries, setListOfCountries] = useState([]);
  const [listOfCities, setListOfCities] = useState([]);
  const clinicHookService = useClinicService();
  const [pageRefresh, setPageRefresh] = useState(false);
  const [packageList, setPackageList] = useState();
  const [data, setData] = useState({ clinicsData: [] });
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [totalEntries, setTotalEntries] = useState();
  const [totalPages, setTotalPages] = useState();
  const exportCSVServiceHook = useExportCSVService();
  const [dateRangeTable, setDateRangeTable] = useState("");

  useMemo(() => {
    clinicHookService.countries().then((result) => {
      if (result) {
        setListOfCountries(result);
      }
    });
    clinicHookService.cities().then((result) => {
      if (result) {
        setListOfCities(result);
      }
    });
    packageHookService.packages().then((result) => {
      if (result) {
        setPackageList(result);
      }
    });
  }, [pageRefresh]);

  useEffect(() => {
    financialHookService
      .financials(
        pageNo,
        entriesPerPage,
        dateRangeTable,
        search,
        selectedOption["country"],
        selectedOption["city"],
        selectedOption["package"]
      )
      .then((result) => {
        if (result && result?.financialData && result?.financialMeta) {
          setData(result);
          setLoading(false);
          setTotalEntries(result?.financialMeta?.total_count);
          setTotalPages(result?.financialMeta?.total_pages);
        } else {
          console.log("Data is undefined or incomplete.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    search,
    entriesPerPage,
    pageNo,
    pageRefresh,
    selectedOption["package"],
    selectedOption["country"],
    selectedOption["city"],
    dateRangeTable,
  ]);
  useEffect(() => {
    financialHookService
      .stats(
        dateRange,
        selectedOption["city"],
        selectedOption["country"],
        selectedOption["package"]
      )
      .then((result) => {
        if (result) {
          setStats(result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dateRange]);

  const extractPackageNames = (packagesData) => {
    return packagesData?.map((pack) => pack.name);
  };

  const handleDateRangeChange = (newDateRange) => {
    setDateRangeTable(newDateRange);
  };

  const imageIcon = {
    color: "info", // specify the color if needed
    component: <img src={MoneyIcon} alt="Financials" />,
  };

  const handleFilterChange = (filterName, selectedValue) => {
    setSelectedOption((prevOptions) => ({
      ...prevOptions,
      [filterName]: selectedValue,
    }));
  };

  const handleExportCSV = () => {
    const pathname = `/administrator_apis/financials/export?query=${search}&country=${selectedOption["country"]}&city=${selectedOption["city"]}&date_range=${dateRangeTable}&package=${selectedOption["package"]}`;
    const filename = `admin_clinics`;
    exportCSVServiceHook.exportCSV(pathname, filename);
  };

  const financialRowsData = data?.financialData?.map((financial, index) => ({
    ...financial,
    user: {
      ...financial.user,
      name: formatName(financial?.user?.name),
    },
    date: formatDate(financial?.date)
  }));
  // Data table
  const tableData = {
    columns: [
      // { Header: "clinic id", accessor: "user.registration_id" },
      { Header: "date", accessor: "date" },
      { Header: "clinic name", accessor: "user.name" },
      { Header: "country", accessor: "user.country" },
      { Header: "city", accessor: "user.city" },
      { Header: "package", accessor: "package_name" },
      { Header: "amount", accessor: "amount_credited" },
      { Header: "credits buy", accessor: "credits" },
      { Header: "free credits", accessor: "free_credits" },
    ],
    rows: financialRowsData || [],
  };

  const filters = [
    {
      name: "City",
      action: (selectedValue) => handleFilterChange("city", selectedValue),
      listOfOptions: listOfCities?.cities?.data,
    },
    {
      name: "Country",
      action: (selectedValue) => handleFilterChange("country", selectedValue),
      listOfOptions: listOfCountries?.countries?.data,
    },
    ,
    {
      name: "Package",
      action: (selectedValue) => handleFilterChange("package", selectedValue),
      listOfOptions: extractPackageNames(packageList),
    },
  ];

  function amount(value) {
    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue)) {
      const formattedValue = parsedValue.toFixed(2);
      return `€${formattedValue}`;
    } else {
      return value;
    }
  }

  return (
    <>
      <DashboardLayout colorBgHeight={"110px"}>
        <DashboardNavbar customRoutePath={"Financials"} />
        {loading && <Spinner />}
        {!loading && (
          <ArgonBox>
            <ArgonBox pt={3}>
              <ArgonBox
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <ArgonTypography
                  mb={3}
                  mt={4}
                  sx={{
                    color: "var(--Default, #172B4D)",
                    fontSize: "25px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "137%",
                    letterSpacing: "-0.8px",
                  }}
                >
                  Financials
                </ArgonTypography>
                <ArgonBox sx={{ alignItems: "center" }}>
                  <DateRangePicker
                    placeholder="Apply date filter"
                    showOneCalendar
                    style={{ width: 250 }}
                    size="lg"
                    format="dd.MM.yyyy"
                    onShortcutClick={(shortcut, event) => {
                      setDateRange(shortcut.value);
                    }}
                    onOk={(shortcut) => {
                      setDateRange(shortcut);
                    }}
                    onClean={(shortcut) => {
                      setDateRange("");
                    }}
                    ranges={[]}
                  />
                </ArgonBox>
              </ArgonBox>
              {!loading && (
                <ArgonBox py={3}>
                  <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} md={6} lg={6}>
                      <DetailedStatisticsCard
                        title="Total Revenue"
                        count={amount(stats?.revenue)}
                        icon={imageIcon}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <DetailedStatisticsCard
                        title="Total Credits Purchased"
                        count={stats?.credits_purchased}
                        icon={imageIcon}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <DetailedStatisticsCard
                        title="Total Credits Used"
                        count={stats?.used_credits}
                        icon={imageIcon}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <DetailedStatisticsCard
                        title="Total Credits Available"
                        count={stats?.credits_available}
                        icon={imageIcon}
                      />
                    </Grid>
                  </Grid>
                </ArgonBox>
              )}
            </ArgonBox>
            <Card>
              <DataTable
                canExportCSV={{
                  name: "Export as CSV",
                  action: handleExportCSV,
                }}
                dateFilter
                onDateRangeChange={handleDateRangeChange}
                dataTableName={"Financials"}
                canSearch
                canFilter
                filters={filters}
                entriesPerPage={entriesPerPage}
                setSearch={setSearch}
                search={search}
                totalPages={totalPages}
                setEntriesPerPage={setEntriesPerPage}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalEntries={totalEntries}
                table={tableData}
                lastFixed={false}
              />
            </Card>
          </ArgonBox>
        )}
      </DashboardLayout>
    </>
  );
}

export default Financials;
