/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui core components
import Card from "@mui/material/Card";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonTypography from "components/ArgonTypography";
import ArgonBox from "components/ArgonBox";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonButton from "components/ArgonButton";

// Settings page components
import FormField from "layouts/pages/users/new-user/components/FormField";

//validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

// import usePackageService from 'service-hooks/usePackageService'
import PackageThumbnail from "../../../../../assets/images/package-thumbnail.svg"
import usePackageService from "service-hooks/usePackageService";
import useSettingService from "service-hooks/useSettingService";
import { useSelector } from "react-redux";


const PackageForm = ({ packageId, heading, buttonText, packageData }) => {
  const [thumbnail, setThumbnail] = useState(PackageThumbnail)
  const [thumbnailError, setThumbnailError] = useState("")
  const fileInputRef = useRef(null);
  const [initialValues, setInitialValues] = useState(packageData);
  const packageService = usePackageService()
  const settingHookService = useSettingService();
  const [creditPrice, setCreditPrice] = useState()
  const userRole = useSelector((state) => state.auth?.user?.role);
  useEffect(() => {
    settingHookService.settings().then((result) => {
      if (result) {
        setCreditPrice(result.data.credit_price);
      } else {
        console.log("Unable to fetch settings");
      }
    });
  }, []);
  const location = useLocation();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    name: Yup.string().required("Package Name is required"),
    credits: Yup.number()
      .positive("Credits should be greater than 0")
      .integer("Credits must be an integer")
      .required('Credits is a required field'),
    freeCredits: Yup.number()
      .nullable()
      .integer("Credits must be an integer")
      .moreThan(-1, "Credits should be greater than or equal to 0"),


  });


  const handleSubmit = (values) => {
    const formData = new FormData()
    formData.append("name", values.name)
    formData.append("credits", values.credits)
    formData.append("free_credits", values.freeCredits ? values.freeCredits : 0)
    if (values.thumbnail) formData.append("thumbnail", values.thumbnail)
    if (packageId) {
      const fetchData = async () => {
        const response = await packageService.update(packageId, formData)
        if (response) navigate(`/${userRole}/settings`, { state: { value: "packages" } });
      }
      fetchData();

    } else {
      const fetchData = async () => {
        const response = await packageService.create(formData)
        if (response) navigate(`/${userRole}/settings`, { state: { value: "packages" } });
      }
      fetchData();
    }

  };

  const validateImage = async (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width === 100 && img.height === 100) {
          resolve(true);
        } else {
          reject(new Error("Image size must be 100x100 pixels"));
        }
      };
      img.onerror = () => {
        reject(new Error("Invalid image file"));
      };
      img.src = URL.createObjectURL(file);
    })
      .catch(error => {
        return false;
      });
  };

  const handleImageChange = async (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const validImage = await validateImage(file);

        if (validImage) {
          setThumbnailError("")
          const reader = new FileReader();
          reader.onloadend = () => {
            setThumbnail(reader.result);
            setFieldValue("thumbnail", file);
          };
          reader.readAsDataURL(file);
        } else {
          setThumbnailError("Image is not valid")
        }

      } catch (error) {
        setThumbnail(PackageThumbnail);
        setFieldValue("thumbnail", null);
      }
    }
  };

  function uploadThumbnail(e) {
    fileInputRef.current.click();
  }

  return (
    <>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ sm: "row" }}
        pr={{ lg: 8, xl: 10, xxl: 12 }}
        mt={5}
      >
        <ArgonTypography
          sx={{
            color: "var(--Default, #172B4D)",
            fontSize: "25px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "137%",
            letterSpacing: "-0.8px",
          }}
        >
          {heading}
        </ArgonTypography>

      </ArgonBox>

      <ArgonBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >

              {(props) => (
                <Form>
                  <Card sx={{ overflow: "visible" }}>
                    <ArgonBox p={3}>
                      <ArgonBox pb={3} px={3} >
                        <ArgonTypography
                          variant="h3"
                          color="dark"
                          sx={{
                            fontSize: "20px",
                            letterSpacing: "-0.8px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "50%",
                          }}
                          mb={2}
                        >
                          Package info
                        </ArgonTypography>

                        <Grid item sm={4} container >
                          <ArgonBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flexDirection={{ xs: "column", sm: "row" }}
                            py={2}
                          >

                            <ArgonBox position="relative" height="max-content" mb={2}>

                              <ArgonAvatar
                                src={props.values.thumbnail_url ? props.values.thumbnail_url : thumbnail}
                                alt="Package thumbnail"
                                size="xl"
                                variant="rounded" />
                              <input
                                id="thumbnail"
                                name="thumbnail"
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                accept="image/*"
                                onChange={(event) => {
                                  handleImageChange(event, props.setFieldValue);
                                }}
                              />
                              <ArgonBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                                <ArgonButton variant="gradient" color="light" size="small" iconOnly onClick={uploadThumbnail}>
                                  <Icon>edit</Icon>
                                </ArgonButton>
                              </ArgonBox>
                            </ArgonBox>

                            <ArgonBox mx={2} lineHeight={1}>
                              <ArgonTypography component="div" color="text" variant="button" fontWeight="bold">
                                Upload a 100 x 100
                              </ArgonTypography>
                              <ArgonTypography component="div" variant="button" color="text" fontWeight="bold">
                                pixel image
                              </ArgonTypography>
                              <ArgonTypography component="div" variant="caption" color="error">
                                {thumbnailError}
                              </ArgonTypography>
                            </ArgonBox>
                          </ArgonBox>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              label="Name"
                              placeholder="eg. Basic"
                              name="name"
                              value={props.values.name}
                              onChange={props.handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              label="Price"
                              name="price"
                              value={props.values.credits * creditPrice}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} mt={1}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              label="Number of Credits"
                              placeholder="eg. 23"
                              name="credits"
                              value={props.values.credits}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              label="Free Credits"
                              placeholder="eg. 23"
                              name="freeCredits"
                              value={props.values.freeCredits}
                            />
                          </Grid>
                        </Grid>
                      </ArgonBox>
                    </ArgonBox>
                  </Card>

                  <ArgonBox mt={3}>
                    <ArgonButton
                      color="info"
                      type="submit"
                      sx={{
                        display: "flex",
                        width: "124px",
                        padding: " 10px 20px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {buttonText}
                    </ArgonButton>
                  </ArgonBox>
                </Form>
              )}

            </Formik>
          </Grid>
        </Grid>
      </ArgonBox>
    </>
  )
}

PackageForm.defaultProps = {
  packageData: {
    credits: 0,
    freeCredits: 0,
    name: "",
    thumbnail_url: ""
  },
};


export default PackageForm;