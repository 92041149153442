import React from "react";
//components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import DataTable from "examples/Tables/DataTable";
import Card from "@mui/material/Card";
import Header from "blikol-layouts/components/Header";
import Spinner from "blikol-layouts/components/Spinner";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ActionMenu from "blikol-layouts/components/ActionMenu";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import usePatientsService from "../../service-hooks/usePatientsService";
import Grid from "@mui/material/Grid";
import useExportCSVService from "service-hooks/useExportCSVService";
import useClinicService from "service-hooks/useClinicService";
import { formatName, formatEmail } from "parsers/formatParser";
function Patients() {
  const { user, token } = useSelector((state) => state.auth);
  const userRole = user?.role;
  const userCredits = user?.credits;
  const [data, setData] = useState({ patientsData: [] });
  const navigate = useNavigate();
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [totalEntries, setTotalEntries] = useState();
  const [totalPages, setTotalPages] = useState();
  const [pageRefresh, setPageRefresh] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    country: "",
    city: "",
    gender: "",
    tinnitus_type: "",
    clinic: "",
  });
  const patientsHookService = usePatientsService();
  const [loading, setLoading] = useState(true);
  const [listTab, setListTab] = useState();
  const [status, setStatus] = useState("active");
  const [listOfCountries, setListOfCountries] = useState([]);
  const [listOfCities, setListOfCities] = useState([]);
  const exportCSVServiceHook = useExportCSVService();
  const [listOfClinics, setListOfClinics] = useState([]);
  const clinicHookService = useClinicService();
  useMemo(() => {
    patientsHookService.countries(status).then((result) => {
      if (result) {
        setListOfCountries(result);
      }
    });
    patientsHookService.cities(status).then((result) => {
      if (result) {
        setListOfCities(result);
      }
    });
    clinicHookService.allClinicIds().then((result) => {
      const clinics = result?.map((clinic) => ({
        value: clinic[0], // Clinic ID or value
        label: clinic[1], // Clinic name
      }));
      setListOfClinics(clinics);
    });
  }, [pageRefresh, status]);

  useEffect(() => {
    patientsHookService
      .patients(
        pageNo,
        entriesPerPage,
        selectedOptions["gender"],
        search,
        selectedOptions["tinnitus_type"],
        status,
        selectedOptions["country"],
        selectedOptions["city"],
        selectedOptions["clinic"]
      )
      .then((result) => {
        if (result && result.patientsData && result.patientsMeta) {
          setData(result);
          setLoading(false);
          setTotalEntries(result?.patientsMeta?.total_count);
          setTotalPages(result?.patientsMeta?.total_pages);
        } else {
          toast.error("Data is undefined or incomplete.");
        }
      })
      .catch((error) => {
        // Handle the error (e.g., display an error message)
        toast.error("Error fetching user data and attributes:", error);
      });
  }, [
    search,
    entriesPerPage,
    pageNo,
    pageRefresh,
    selectedOptions["gender"],
    selectedOptions["tinnitus_type"],
    selectedOptions["country"],
    selectedOptions["city"],
    selectedOptions["clinic"],

    status,
  ]);

  function lowerFirstLetter(word) {
    if (typeof word !== "string" || word.length === 0) {
      return word;
    }
    return word.charAt(0).toLowerCase() + word.slice(1);
  }

  const handleTabChange = (selectedTab) => {
    selectedTab = lowerFirstLetter(selectedTab);
    setStatus(selectedTab);
    setPageNo(1)
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      const updateResponse = await patientsHookService.updateStatus(newStatus, id);
      if (updateResponse) {
        const actionText = newStatus === "active" ? "re-activated" : "deactivated";
        toast.success(`Patient with ID ${id} ${actionText} successfully.`);
        setPageRefresh(!pageRefresh);
      } else {
        const actionText = newStatus === "active" ? "Error re-activating" : "Error deactivating";
        toast.error(`${actionText} patient with ID ${id}.`);
      }
    } catch (error) {
      const actionText = newStatus === "active" ? "Error re-activating" : "Error deactivating";
      toast.error(`${actionText} patient with ID ${id}:`, error);
    }
  };

  const handleView = (id) => {
    navigate(`/${userRole}/patients/view/${id}`);
  };


  const filters = [
    {
      name: "Gender",
      action: (selectedValue) => handleFilterChange("gender", selectedValue),
      listOfOptions: ["male", "female", "Other"],
    },
    {
      name: "Tinnitus Type",
      action: (selectedValue) => handleFilterChange("tinnitus_type", selectedValue),
      listOfOptions: ["tonal", "complex_noise", "other"],
    },
    {
      name: "City",
      action: (selectedValue) => handleFilterChange("city", selectedValue),
      listOfOptions: listOfCities?.cities?.data,
    },
    {
      name: "Country",
      action: (selectedValue) => handleFilterChange("country", selectedValue),
      listOfOptions: listOfCountries?.countries?.data,
    },
    {
      name: "Clinic",
      action: (selectedValue) => handleFilterChange("clinic", selectedValue),
      listOfOptions: listOfClinics,
    },
  ];

  const threeDotsActive = [
    {
      type: "Deactivate",
      action: (id) => handleStatusChange(id, "inactive"),
      style: {
        color: "red",
      },
    },
  ];

  const threeDotsInactive = [
    {
      type: "Reactivate",
      action: (id) => handleStatusChange(id, "active"),
    },
  ];

  const handleFilterChange = (filterName, selectedValue) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [filterName]: selectedValue,
    }));
  };

  const handleExportCSV = () => {
    const pathname = `/administrator_apis/patients/export?status=${status == "active" ? "" : status
      }&country=${selectedOptions["country"]}&city=${selectedOptions["city"]}&gender=${selectedOptions["gender"]
      }&tinnitus_type=${selectedOptions["tinnitus_type"]}&clinic_name=${selectedOptions["clinic"]}&query=${search}`;
    const filename = `admin_patients`;
    exportCSVServiceHook.exportCSV(pathname, filename);
  };

  const tableCell = (option) => {
    if (option && option.trim() !== "") {
      return option
        .replace(/[_-]/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else {
      return "";
    }
  };

  const patientsRowsData = data.patientsData.map((patient, index) => ({
    ...patient,
    full_name: formatName(patient?.full_name),
    email: formatEmail(patient?.email),
    gender: tableCell(patient?.gender),
    tinnitus_type: tableCell(patient?.tinnitus_type),
    clinic_name: formatName(patient?.clinic_name),
    action: (
      <ActionMenu
        threeDots={patient.status === "inactive" ? threeDotsInactive : threeDotsActive}
        row={patient}
        key={index + 1}
      />
    ),
  }));

  const tableData = {
    columns: [
      { Header: "name", accessor: "full_name" },
      { Header: "email", accessor: "email" },
      { Header: "clinic name", accessor: "clinic_name" },
      { Header: "gender", accessor: "gender" },
      { Header: "tinnitus type", accessor: "tinnitus_type" },
      { Header: "country", accessor: "country" },
      { Header: "action", accessor: "action" },
    ],
    rows: patientsRowsData,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar customRoutePath={"Patients"} />
      <Grid container spacing={3} alignItems="center" mb={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Header onTabChange={handleTabChange} numTabs={2} tabNames={["Active", "Pending", "Inactive"]} />
        </Grid>
      </Grid>
      {loading && <Spinner />}
      {!loading && (
        <Card p={1}>
          <ArgonBox>
            <DataTable
              canExportCSV={{
                name: "Export as CSV",
                action: handleExportCSV,
              }}
              canSearch
              canFilter
              filters={filters}
              dataTableName={"Patients"}
              entriesPerPage={entriesPerPage}
              setSearch={setSearch}
              search={search}
              totalPages={totalPages}
              setEntriesPerPage={setEntriesPerPage}
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalEntries={totalEntries}
              table={tableData}
              viewEntry={handleView}
              clickableRecords={true}
            />
          </ArgonBox>
        </Card>
      )}
    </DashboardLayout>
  );
}

export default Patients;
