import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "slices/authSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
const timeZoneAbbr = Intl.DateTimeFormat().resolvedOptions().timeZone;

const useBaseService = () => {
  const dispatch = useDispatch();
  const axiosBaseRequest = axios.create({
    baseURL: API_BASE_URL,
  });

  axiosBaseRequest.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        dispatch(setLogout());
        toast.success("Please login to continue");
      }
      return Promise.reject(error);
    }
  );

  const axiosRequest = () => {
    axiosBaseRequest.defaults.headers.common["Content-Type"] = "application/json";
    const token = useSelector((state) => state.auth?.token);
    if (token) {
      axiosBaseRequest.defaults.headers.common = {
        Authorization: token,
        Timezone: timeZoneAbbr ? timeZoneAbbr : "",
      };
    }
    return axiosBaseRequest;
  };

  const multiPartAxiosRequest = () => {
    axiosBaseRequest.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const token = useSelector((state) => state.auth?.token);
    if (token) {
      axiosBaseRequest.defaults.headers.common["Authorization"] = token;
    }
    return axiosBaseRequest;
  };

  return {
    axiosRequest,
    multiPartAxiosRequest,
  };
};
export default useBaseService;
