import useBaseService from "../useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useCreditPackageService = () => {
  const axiosRequest = useBaseService().axiosRequest();

  const packages = async () => {
    try {
      const response = await axiosRequest.get(`/clinic_apis/packages`);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const purchasePlanCredits = async (planId, paymentMethodId) => {
    try {
      const response = await axiosRequest.post(`/clinic_apis/payments`, {
        package_id: planId,
        payment_method_id: paymentMethodId,
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  return {
    packages,
    purchasePlanCredits,
  };
};

export default useCreditPackageService;
