import { useState, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import useUpdatePasswordService from "service-hooks/useUpdatePasswordService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import "utils/i18n"
import { useTranslation } from "react-i18next";


function ResetPassword() {
    const updatePasswordHookService = useUpdatePasswordService();
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [pageRefresh, setPageRefresh] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const { t } = useTranslation()

    useEffect(() => {
        setIsButtonDisabled(
            !currentPassword || !password || !confirmPassword
        );
    }, [currentPassword, password, confirmPassword]);

    useEffect(() => {
    }, [pageRefresh]);

    const handleSubmit = () => {
        updatePasswordHookService.updatePassword(currentPassword, password, confirmPassword)
            .then((result) => {
                if (result) {
                    setPageRefresh(true);
                    setCurrentPassword("");
                    setPassword("");
                    setConfirmPassword("");
                    toast.success(t("Password updated successfully"));
                }
            })
            .catch((error) => {
                toast.error(t(error.message) || t(error));
            });
    };

    return (
        <Grid item xs={6}>
            <Card sx={{ overflow: "hidden" }}>
                <ArgonBox px={3} pt={3} pb={2} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <ArgonTypography variant="h5">{t("Change Password")}</ArgonTypography>
                    <ArgonTypography
                        sx={{
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "150%"
                        }}
                    >
                        {t("You can update your password below. If you forgot your current password please contact support for assistance.")}
                    </ArgonTypography>
                </ArgonBox>

                <ArgonBox component="form" pb={3} px={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormField
                                label={t("Current password")}
                                placeholder={t("Enter your current password")}
                                name="current-password"
                                onChange={(event) => setCurrentPassword(event.target.value)}
                                required={true}
                                type="password"
                                value={currentPassword}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormField
                                label={t("New password")}
                                placeholder={t("Enter your new password")}
                                name="new-password"
                                onChange={(event) => setPassword(event.target.value)}
                                required={true}
                                type="password"
                                value={password}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormField
                                label={t("Confirm new password")}
                                placeholder={t("Enter your confirm new password")}
                                name="confirm-new-password"
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                required={true}
                                type="password"
                                value={confirmPassword}
                            />
                        </Grid>
                    </Grid>
                </ArgonBox>
            </Card>
            <ArgonBox mt={3}>
                <ArgonButton
                    onClick={handleSubmit}
                    color="info"
                    disabled={isButtonDisabled}
                >
                    {t("Update")}
                </ArgonButton>
            </ArgonBox>
        </Grid>
    );
}

export default ResetPassword;
