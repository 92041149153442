/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import DeleteIcon from "@mui/icons-material/Delete";


function FormField({ deleteAction, label, ...rest }) {
  return (
    <ArgonBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <ArgonTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox sx={{ display: "flex", alignItems: "center" }}>
        <ArgonInput {...rest} />
        <DeleteIcon sx={{ color: "red", height: "24px", width: "24px", margin: "0px 2px 0px 5px", cursor: "pointer" }} onClick={deleteAction} />
      </ArgonBox>
    </ArgonBox>
  );
}

// Setting default values for the props of FormField
FormField.defaultProps = {
  label: " ",
};

// Typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string,
  deleteAction: PropTypes.func
};

export default FormField;
