import "./style.css";
import PropTypes from "prop-types";

import PlanCard from "../components/PlanCard";
import { useEffect, useState } from "react";
import useCreditPackageService from "service-hooks/clinic/useCreditPackageService";
import Spinner from "../../components/Spinner";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import Grid from "@mui/material/Grid";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import FrequencyGenerator from "../components/FrequencyGenerator";
import "./style.css";
import { useLocation } from "react-router-dom";
import CustomStepper from "blikol-layouts/components/Stepper/CustomeStepper";
import { useDispatch } from "react-redux";
import { setActiveStep } from "slices/generalSlice";
import "utils/i18n"
import { useTranslation } from "react-i18next";
import { display } from "@mui/system";


const stepperStyles = {
  connector: {
    active: { backgroundColor: "#172B4D" },
    inactive: { backgroundColor: "#CED4DA" },
  },
  circle: {
    active: { backgroundColor: "#172b4d", border: "1px solid #172b4d" },
    inactive: { backgroundColor: "white", border: "3px solid #ced4da" },
  },
};


function FrequencyDiagnosis() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { patientName } = location.state || {}; // Destructure patientName from state
  const { t } = useTranslation()
  const stepperData = [{ label: t("Test 1") }, { label: t("Test 2") }, { label: t("Result") }];
  dispatch(setActiveStep(0));
  return (
    <DashboardLayout colorBgHeight={"110px"}>
      <DashboardNavbar customRoutePath={"Diagnosis Frequency Test"} />
      <ArgonBox px={3} pt={3}>
        <ArgonTypography
          sx={{
            color: "var(--Default, #172B4D)",
            fontSize: "25px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "137%",
            letterSpacing: "-0.8px",
            mb: "1.5rem",
          }}
        >
          {t("Frequency Diagnosis")}
        </ArgonTypography>
        <ArgonBox sx={{ display: "flex", justifyContent: "center" }}>
          <ArgonTypography
            sx={{
              color: "var(--Default, #172B4D)",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "137%",
              textTransform: "capitalize",
              letterSpacing: "-0.8px",
              mb: "1.5rem",
            }}
          >
            {t("Patient Name")}: <span> {patientName} </span>
          </ArgonTypography>
        </ArgonBox>

        <CustomStepper data={stepperData} stepperStyles={stepperStyles} />

        <ArgonBox
          className="frequency-container"
          sx={{
            backgroundColor: "#FFFFFF",
            p: "4rem",
            pb: "4rem",
            borderRadius: "20px",
            boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
          }}
        >
          <FrequencyGenerator />
        </ArgonBox>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default FrequencyDiagnosis;
