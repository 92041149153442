import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import Card from "@mui/material/Card";
import ActionMenu from "blikol-layouts/components/ActionMenu";
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AudioPlayer from "./components/AudioPlayer";
import useMusicService from "service-hooks/useMusicService";
import Spinner from "../components/Spinner";
import UploadModal from "./components/UploadModal";
import { toast } from 'react-toastify';
import DeleteConfirmationModal from "./components/DeleteModal"
import { formatDate } from "parsers/formatParser";

function music() {
    const [data, setData] = useState({ musicData: [] });
    const navigate = useNavigate();
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [search, setSearch] = useState();
    const [pageNo, setPageNo] = useState(1);
    const [totalEntries, setTotalEntries] = useState();
    const [totalPages, setTotalPages] = useState();
    const [pageRefresh, setPageRefresh] = useState(false);
    const [typesList, setTypesList] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const musicHookService = useMusicService();
    const userRole = useSelector((state) => state.auth?.user?.role);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [loading, setLoading] = useState(true)

    useMemo(() => {
        musicHookService.types().then((result) => {
            if (result) {
                setTypesList(result);

            }
        });
    }, [pageRefresh]);

    useEffect(() => {
        musicHookService
            .musics(pageNo, entriesPerPage, selectedOption, search)
            .then((result) => {
                if (result && result.musicData && result.musicMeta) {
                    setData(result);
                    setLoading(false)
                    setTotalEntries(result?.musicMeta?.total_count);
                    setTotalPages(result?.musicMeta?.total_pages);
                } else {
                    console.log("Data is undefined or incomplete.");
                }
            })
            .catch((error) => {
                console.error("Error fetching music data and attributes:", error);
            });
    }, [
        search,
        entriesPerPage,
        pageNo,
        pageRefresh,
        selectedOption
    ]);
    const handleDelete = (id) => {
        setDeleteItemId(id);
        setShowDeleteConfirmation(true);
    };

    const handleDeleteConfirmation = () => {
        if (deleteItemId) {
            musicHookService
                .destroy(deleteItemId)
                .then(() => {
                    toast.success('Music deleted successfully.');
                    setPageRefresh(!pageRefresh);
                })
                .catch((error) => {
                    toast.error(error);
                });
        }

        setShowDeleteConfirmation(false);
    };

    const customIcons = [
        {
            action: handleDelete,
            icon: (
                <DeleteIcon
                    fontSize="large"
                    sx={{
                        color: "red",
                    }}
                />
            ),
        },
    ];

    const handleAdd = () => {
        setIsModalOpen(true);
    };

    const dataTableName = "Music List";

    const filters = [
        {
            name: "Music Type",
            action: (selectedValue) => setSelectedOption(selectedValue),
            listOfOptions: typesList?.types?.data,
        },
    ];


    const handlePlayPause = (id) => {
        if (id === currentlyPlayingId) {
            setIsPlaying((prevIsPlaying) => !prevIsPlaying);
        } else {
            setIsPlaying(true);
            setCurrentlyPlayingId(id);
        }
    };

    const musicsRowsData = data.musicData.map((music, index) => ({
        ...music,
        created_at: formatDate(music?.created_at),
        file: (
            <AudioPlayer
                key={music?.id}
                song={music?.url}
                isPlaying={isPlaying && currentlyPlayingId === music.id}
                handlePlayPause={handlePlayPause}
                id={music?.id}
            />

        ),
        action:
            userRole === "admin" || userRole === "super_admin" ? (
                <ActionMenu row={music} customIcons={customIcons} key={index + 1} />
            ) : null,
    }));

    const canAdd =
        userRole === "admin" || userRole === "super_admin"
            ? {
                name: "Add Audio File",
                action: handleAdd,
            }
            : false;


    const dataTableData = {
        columns: [
            { Header: "created date", accessor: "created_at" },
            { Header: "file name", accessor: "title" },
            { Header: "music type", accessor: "type" },
            { Header: "file", accessor: "file" },
            userRole === "admin" || userRole === "super_admin"
                ? { Header: "action", accessor: "action" }
                : null,
        ].filter(Boolean), // Filter out null values
        rows: musicsRowsData,
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {loading && <Spinner />}
            {!loading && (
                <Card p={1}>
                    <ArgonBox>
                        <DataTable
                            canAdd={canAdd}
                            canSearch
                            canFilter
                            filters={filters}
                            dataTableName={dataTableName}
                            entriesPerPage={entriesPerPage}
                            setSearch={setSearch}
                            search={search}
                            totalPages={totalPages}
                            setEntriesPerPage={setEntriesPerPage}
                            pageNo={pageNo}
                            setPageNo={setPageNo}
                            totalEntries={totalEntries}
                            table={dataTableData}
                            lastFixed={true}

                        />
                        <UploadModal
                            open={isModalOpen}
                            handleClose={() => setIsModalOpen(false)}
                            data={data}
                            setData={setData}
                        />
                        <DeleteConfirmationModal
                            open={showDeleteConfirmation}
                            onClose={() => setShowDeleteConfirmation(false)}
                            onConfirm={handleDeleteConfirmation}
                        />


                    </ArgonBox>
                </Card>)}
        </DashboardLayout>
    );
}

export default music;