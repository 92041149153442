import React from 'react'
//components 
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import DataTable from "examples/Tables/DataTable";
import Card from "@mui/material/Card";
import Header from "blikol-layouts/components/Header"
import Spinner from 'blikol-layouts/components/Spinner';
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ActionMenu from "blikol-layouts/components/ActionMenu";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useUserService from "../../service-hooks/useUserService"
import Grid from '@mui/material/Grid';
import useExportCSVService from "service-hooks/useExportCSVService";
import "./style.css"
import { formatName, formatEmail, formatDate } from "parsers/formatParser"

function Users() {

    const { user, token } = useSelector((state) => state.auth)
    const userRole = user?.role
    const [data, setData] = useState({ usersData: [] });
    const navigate = useNavigate();
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [search, setSearch] = useState();
    const [pageNo, setPageNo] = useState(1);
    const [totalEntries, setTotalEntries] = useState();
    const [totalPages, setTotalPages] = useState();
    const [pageRefresh, setPageRefresh] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({ "role": "" });
    const userHookService = useUserService();
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState("")
    const exportCSVServiceHook = useExportCSVService()

    useEffect(() => {
        userHookService
            .users(pageNo, entriesPerPage, search, selectedOptions["role"], status)
            .then((result) => {
                if (result) {
                    setData(result);
                    setLoading(false);
                    setTotalEntries(result?.usersMeta?.total_count);
                    setTotalPages(result?.usersMeta?.total_pages);
                } else {
                    toast.error("Data is undefined or incomplete.");
                }
            })
            .catch((error) => {
                // Handle the error (e.g., display an error message)
                toast.error("Error fetching user data and attributes:", error);
            });
    }, [
        search,
        entriesPerPage,
        pageNo,
        pageRefresh,
        selectedOptions["role"],
        status
    ]);

    function lowerFirstLetter(word) {
        if (typeof word !== 'string' || word.length === 0) {
            return word;
        }
        return word.charAt(0).toLowerCase() + word.slice(1);
    }


    const handleTabChange = (selectedTab) => {
        selectedTab = lowerFirstLetter(selectedTab)
        setStatus(selectedTab == 'active' ? '' : selectedTab);
        setPageNo(1)
    };

    const handleStatusChange = async (id, newStatus) => {
        try {
            // Fetch clinic data
            const result = await userHookService.user(id);
            if (result) {
                const updatedValues = {
                    ...result.data,  // Copy the existing values
                    status: newStatus,  // Set the status to the desired value
                };

                const response = await userHookService.update(updatedValues, id);
                if (response) {
                    toast.success(`User ${newStatus === "inactive" ? "de-activated" : "activated"} successfully.`);
                    navigate(`/${userRole}/users`);
                } else {
                    toast.error(`Failed to update user status to ${newStatus}.`);
                }
            } else {
                toast.error("No user with this id");
                navigate(`/${userRole}/users`);
            }
        } catch (error) {
            console.error(`Error updating user status to ${newStatus}:`, error);
            toast.error(`Error updating user status to ${newStatus}.`);
        }
        setPageRefresh(!pageRefresh);
    };

    const handleEdit = (id) => navigate(`/${userRole}/users/edit/${id}`);

    const handleAddUser = () => { navigate(`/${userRole}/users/new`) }



    const handleResendCredntials = (id) => {
        userHookService
            .resendCredentails(id)
            .then((result) => {
                toast.success("Credentials resent successfully.");
            })
            .catch((error) => {
                toast.error(error);
            });
    };


    const filters = [
        {
            name: "Role",
            action: (selectedValue) => handleFilterChange("role", selectedValue),
            listOfOptions: ["secretary", "admin", "super_admin", "agent"],
        },
    ];

    const threeDotsActive = [
        {
            type: "Edit",
            action: handleEdit,

        },
        {
            type: "Deactivate",
            action: (id) => handleStatusChange(id, "inactive"),
            style: {
                color: "red"
            }
        },
    ];

    const threeDotsInactive = [
        {
            type: "Edit",
            action: handleEdit,
        },
        {
            type: "Reactivate",
            action: (id) => handleStatusChange(id, "active"),
        }
    ];

    const threeDotsPending = [
        {
            type: " Resend Credentials",
            action: handleResendCredntials,
        },
        {
            type: "Edit",
            action: handleEdit,
        }
    ];

    const handleFilterChange = (filterName, selectedValue) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [filterName]: selectedValue,
        }));
    };

    const handleExportCSV = () => {
        const pathname = `/administrator_apis/administrators/export_admins?role=${selectedOptions["role"]}&status=${status}&query=${search}`
        const filename = `administrators`
        exportCSVServiceHook.exportCSV(pathname, filename)
    };

    const statusColumn = (status) => {
        return (
            <>
                <div className={status === "active" ? "status-active" : (status === "pending" ? "status-pending" : "status-inactive")}>
                    {status}
                </div>
            </>
        );
    };

    function formatString(inputString) {
        return inputString.replace(/_/g, ' ');
    }


    const roleColumn = (role) => {
        return (
            <>
                <div className={"role"}>
                    {formatString(role)}
                </div>
            </>
        );
    };


    const usersRowsData = data.usersData.map((user, index) => ({
        ...user,
        role: roleColumn(user?.role),
        rawStatus: user?.status, // Store raw status text for sorting
        status: statusColumn(user?.status),
        name: formatName(user?.name),
        email: formatEmail(user?.email),
        created_at: formatDate(user?.created_at),


        action: (
            <ActionMenu
                threeDots={user.status === "active" ? threeDotsActive : (user.status === "pending" ? threeDotsPending : threeDotsInactive)}
                row={user}
                key={index + 1}
            />
        ),
    }));

    const tableData = {
        columns: [
            { Header: "name", accessor: "name" },
            { Header: "email", accessor: "email" },
            { Header: "role", accessor: "role" },
            {
                Header: "Status",
                accessor: "rawStatus", // Use raw status text for sorting
                Cell: ({ value }) => statusColumn(value) // Use statusColumn for rendering
            },
            { Header: "date", accessor: "created_at" },
            ...(user?.role === "super_admin" ? [{ Header: "action", accessor: "action" }] : []),
        ],
        rows: usersRowsData,
    };

    return (
        <DashboardLayout>
            <DashboardNavbar customRoutePath={"Users"} />
            <Grid container spacing={3} alignItems="center" mb={2}>
                <Grid item xs={6} md={6} lg={4}>
                    <Header
                        onTabChange={handleTabChange}
                        numTabs={2}
                        tabNames={["Active", "Inactive"]}
                    />
                </Grid>
            </Grid>
            {loading && <Spinner />}
            {!loading && (
                <Card p={1}>
                    <ArgonBox>
                        <DataTable
                            canExportCSV={{
                                name: "Export as CSV",
                                action: handleExportCSV,
                            }}
                            canAdd={
                                user?.role === "super_admin"
                                    ? { name: "Add User", action: handleAddUser }
                                    : false
                            }
                            canSearch
                            canFilter
                            filters={filters}
                            dataTableName={"Users"}
                            entriesPerPage={entriesPerPage}
                            setSearch={setSearch}
                            search={search}
                            totalPages={totalPages}
                            setEntriesPerPage={setEntriesPerPage}
                            pageNo={pageNo}
                            setPageNo={setPageNo}
                            totalEntries={totalEntries}
                            table={tableData}
                            lastFixed={userRole === "super_admin" ? true : false}
                        />
                    </ArgonBox>
                </Card>
            )}
        </DashboardLayout>)
}

export default Users