import useBaseService from "../useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useTransactionHistoryService = () => {
    const axiosRequest = useBaseService().axiosRequest();
    const history = async (currentPage, itemsPerPage, query = "", selectedOption = "", clinicId) => {
        try {
            const encodedQuery = encodeURIComponent(query)
            const response = await axiosRequest.get(`/clinic_apis/clinics/${clinicId}/credits_history?page=${currentPage}&items_per_page=${itemsPerPage}&query=${encodedQuery}&date_range=${selectedOption}`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    const credit_bonus = async (currentPage, itemsPerPage, clinicId) => {
        try {
            const response = await axiosRequest.get(`/clinic_apis/clinics/${clinicId}/bonus_credits?page=${currentPage}&items_per_page=${itemsPerPage}`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    const credits_used = async (currentPage, itemsPerPage, query = "", clinicId) => {
        try {
            const encodedQuery = encodeURIComponent(query)
            const response = await axiosRequest.get(`/clinic_apis/clinics/${clinicId}/used_credits?page=${currentPage}&items_per_page=${itemsPerPage}&query=${encodedQuery}`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    const downloadReceipt = async (id) => {
        try {
            const response = await axiosRequest.get(`/users/${id}/receipt_url`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    return {
        history,
        credit_bonus,
        credits_used,
        downloadReceipt
    };
};

export default useTransactionHistoryService;
