import React from 'react'
//components 
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import Card from "@mui/material/Card";
import Header from "./components/Header"
import { useState, useEffect } from "react";
import History from "./components/History"
import CreditBonus from "./components/CreditBonus"
import CreditsUsed from './components/CreditsUsed';
import "utils/i18n"
import { useTranslation } from "react-i18next";

function TransactionHistory() {
    const { t } = useTranslation()
    const translatedHistory = t("History");
    const [tabValue, setTabValue] = useState("");
    useEffect(() => {
        setTabValue(translatedHistory); // Set the default tab value
    }, [translatedHistory]); // Depend on translatedHistory to ensure it's updated

    function formatString(inputString) {
        const words = inputString.split(' ');
        const formattedWords = words.map((word, index) => {
            if (index === 0) {
                return word.toLowerCase();
            } else {
                return word.charAt(0).toLowerCase() + word.slice(1);
            }
        });
        const formattedString = formattedWords.join('_');
        return formattedString;
    }

    const handleTabChange = (selectedTab) => {
        const translatedValue = t(selectedTab)
        setTabValue(translatedValue);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar customRoutePath={"Transaction History"} />
            <Header onTabChange={handleTabChange} />
            <Card p={1}>
                <ArgonBox>
                    {tabValue === t("History") && (
                        <History />
                    )}
                    {tabValue === t("Credits Bonus") && (
                        <CreditBonus />
                    )}
                    {tabValue === t("Credits Used") && (
                        <CreditsUsed />
                    )}
                </ArgonBox>
            </Card>
        </DashboardLayout >
    )
}

export default TransactionHistory