import { forwardRef } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select"; // Import components from react-select
import colors from "assets/theme/base/colors";
import { useArgonController } from "context";
import styles from "components/ArgonSelect/styles";

// Custom Input component to modify the input field attributes
const CustomInput = (props) => (
  <components.Input
    {...props}
    autoCapitalize="none"
    autoComplete="none"
    autoCorrect="off"
    spellCheck="false"
  />
);

const ArgonSelect = forwardRef(({ size, error, success, ...rest }, ref) => {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { light } = colors;

  const handleKeyDown = (event) => {
    const input = event.target;
    if (event.key === 'Home') {
      input.setSelectionRange(0, 0);
    } else if (event.key === 'End') {
      const endPosition = input.value.length;
      input.setSelectionRange(endPosition, endPosition);
    }
  };

  return (
    <Select
      onKeyDown={handleKeyDown}
      ref={ref}
      styles={styles(size, error, success, darkMode)}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: light.main,
          primary: light.main,
        },
      })}
      components={{ Input: CustomInput }} // Pass custom Input component
      {...rest}
    />
  );
});

// Setting default values for the props of ArgonSelect
ArgonSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
};

// Typechecking props for the ArgonSelect
ArgonSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  color: PropTypes.string,
};

export default ArgonSelect;