import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.css'; // Import the CSS file

const ExternalRedirect = ({ url }) => {
    const [loading, setLoading] = useState(true); // State to manage loading

    useEffect(() => {
        const redirect = () => {
            setLoading(false); // Set loading to false before redirecting
            window.location.replace(url); // Redirect to the passed URL
        };

        const timer = setTimeout(redirect, 1000);

        return () => clearTimeout(timer);
    }, [url]);

    return (
        <>
            {loading && (
                <div className="loaderContainer">
                    <div className="loader"></div>
                    <p>Redirecting...</p>
                </div>
            )}
        </>
    );
};

// Define prop types for better validation and documentation
ExternalRedirect.propTypes = {
    url: PropTypes.string.isRequired, // Ensure url prop is provided
};

export default ExternalRedirect;
