import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Spinner from "../../../components/Spinner";
import { useState, useEffect } from "react";
import usePaymentMethodService from "service-hooks/clinic/usePaymentMethodService";
import useCreditPackageService from "service-hooks/clinic/useCreditPackageService";
import PropTypes from "prop-types";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import PaymentMethodSelect from "./components/PaymentMethodSelect";
import planCardIcon from "../../../../assets/images/icons/blikol/plan-card-icon.svg";
import StripePaymentMethodModal from "../StripePaymentMethodModal"; // Create this component next
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CREDIT_CARD_FORMAT } from "constants";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "slices/authSlice";
import { useNavigate } from "react-router-dom";
import "utils/i18n"
import { useTranslation } from "react-i18next";

const style = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  padding: "24px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  margin: "auto",
  borderRadius: "15px",
  background: "#FFF",
};

const paymentMethodsOptions = (paymentMethods) =>
  paymentMethods.map((paymentMethod) => {
    return {
      value: paymentMethod?.id,
      label: `${CREDIT_CARD_FORMAT} ${paymentMethod?.last4_digits}`,
    };
  });
function PaymentModal({ plan, paymentModalOpen, setPaymentModalOpen }) {
  const [stripeModalOpen, setStripeModalOpen] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(plan.id);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(paymentModalOpen);
  const [loading, setLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const paymentMethodHookService = usePaymentMethodService();
  const creditPackageHookService = useCreditPackageService();
  const userRole = useSelector((state) => state.auth?.user?.role);
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPaymentModalOpen(false);
    navigate(`/${userRole}/plans`)
  };

  const handlePayNow = () => {
    if (selectedPaymentMethod && selectedPlanId) {
      setLoading(true);
      creditPackageHookService
        .purchasePlanCredits(selectedPlanId, selectedPaymentMethod.value)
        .then((result) => {
          if (result) {
            dispatch(setUser(result));
            toast.success(t("Credits purchased successfully"));
          } else {
            toast.error(t("Unable to purchase credits right now"));
          }
          handleClose();
        });
    } else {
      toast.error(t("No Payment Method Selected"));
    }
  };

  const handlePaymentMethodChange = (value) => {
    setSelectedPaymentMethod(value);
  };

  const handleAddNew = () => {
    setStripeModalOpen(true);
  };

  useEffect(() => {
    if (open) {
      paymentMethodHookService
        .paymentMethods()
        .then((result) => {
          if (result) {
            setLoading(false);
            const options = paymentMethodsOptions(result);
            setPaymentMethods(options);
            setSelectedPaymentMethod(options[0]);
          }
        })
        .catch((error) => {
          console.log(error.response.data.error);
        });
    }
  }, [open]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {loading && <Spinner />}
            {!loading && (
              <ArgonBox display="flex" width="100%" flexDirection="column" gap="1rem" p="1rem">
                <ArgonBox
                  component="img"
                  src={plan?.thumbnail_url || planCardIcon}
                  alt="price"
                  width="2.8rem"
                />
                <ArgonTypography fontWeight="bold" opacity={0.6}>
                  {plan?.name}
                </ArgonTypography>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
                  {plan.credits === 1 ?
                    (<ArgonTypography fontSize="1rem" fontWeight="medium">
                      {t("Buy 1 Credit")}
                    </ArgonTypography>) : (
                      <ArgonTypography fontSize="1rem" fontWeight="medium">
                        {t("Buy")} {plan?.credits} {t("Get")} {plan.free_credits} {t("For Free")}
                      </ArgonTypography>
                    )}

                  <ArgonTypography
                    color="info"
                    fontSize="medium"
                    fontWeight="medium"
                    style={{ cursor: "pointer" }}
                    onClick={handleAddNew}
                  >
                    {t("Add New")}
                  </ArgonTypography>
                </ArgonBox>
                <PaymentMethodSelect
                  paymentMethods={paymentMethods}
                  handlePaymentMethodChange={handlePaymentMethodChange}
                />

                {stripeModalOpen && (
                  <StripePaymentMethodModal
                    setStripeModalOpen={setStripeModalOpen}
                    stripeModalOpen={stripeModalOpen}
                  />
                )}

                <ArgonBox display="flex" justifyContent="flex-end" gap="1rem">
                  <ArgonButton color="info" variant="outlined" onClick={handleClose}>
                    {t("Cancel")}
                  </ArgonButton>
                  <ArgonButton color="info" onClick={handlePayNow}>
                    {t("Pay Now")}
                  </ArgonButton>
                </ArgonBox>
              </ArgonBox>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

PaymentModal.propTypes = {
  plan: PropTypes.object,
  paymentModalOpen: PropTypes.bool,
  setPaymentModalOpen: PropTypes.func,
};
export default PaymentModal;
