import { useDispatch } from "react-redux";
import useBaseService from "../useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const usePatientsService = () => {
    const dispatch = useDispatch();
    const axiosRequest = useBaseService().axiosRequest();

    const patients = async (currentPage, itemsPerPage, gender = "", query = "", tinnitus_type = "", status = "pending") => {
        try {
            const encodedQuery = encodeURIComponent(query)
            const response = await axiosRequest.get(
                `clinic_apis/patients?page=${currentPage}&status=${status}&items_per_page=${itemsPerPage}&gender=${gender}&query=${encodedQuery}&tinnitus_type=${tinnitus_type}`
            );
            const patientsData = response.data.patients;
            const patientsMeta = response.data.meta;
            return { patientsData, patientsMeta };
        } catch (error) {
            toast.error(error.response.data.error);

            return false;
        }
    };

    const create = async (patientData) => {
        try {
            const response = await axiosRequest.post(`clinic_apis/patients`, patientData);
            const clinic = response.data.clinic
            const patient = response.data.patient
            return { clinic, patient };

        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    const update = async (patientData, id) => {
        try {
            const response = await axiosRequest.put(`clinic_apis/patients/${id}`, patientData);
            return response;
        } catch (error) {
            toast.error(error.response.data.error);
            return false; // You can handle the error as needed in the calling code.
        }
    };

    const patient = async (id) => {
        try {
            const response = await axiosRequest.get(`clinic_apis/patients/${id}`);
            return response;
        } catch (error) {
            toast.error(error.response.data.error);
            return false; // You can handle the error as needed in the calling code.
        }
    };

    const resendInvitation = async (id) => {
        try {
            const response = await axiosRequest.get(
                `clinic_apis/patients/${id}/resend_login_credentials`
            );
            return response;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    const resetDiagnosis = async (id) => {
        try {
            const response = await axiosRequest.patch(
                `clinic_apis/patients/${id}/reset_frequency`
            );
            return response;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    return {
        patients,
        patient,
        create,
        update,
        resendInvitation,
        resetDiagnosis
    };
};

export default usePatientsService;
