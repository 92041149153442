import { useState, useEffect, useMemo } from "react";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTransactionHistoryService from 'service-hooks/useTransactionHistoryService'
import DownloadIcon from "assets/images/icons/clinic/download.svg"
import ActionMenu from "blikol-layouts/components/ActionMenu";
import React from 'react'
import useBaseService from "service-hooks/useBaseService";
import useExportCSVService from "service-hooks/useExportCSVService";
import { useParams } from "react-router-dom";
import useDownloadReceipt from "service-hooks/useDownloadReceipt";
import useDownloadInvoice from "service-hooks/useDownloadInvoice";
import Spinner from "blikol-layouts/components/Spinner";
import { formatName, formatDate } from "parsers/formatParser";
import ReceiptIcon from "assets/images/icons/blikol/Receipt-Alt--Streamline-Unicons.svg"
import InvoiceIcon from "assets/images/icons/blikol/Invoice--Streamline-Unicons.svg"

function History() {
    const transactionServiceHook = useTransactionHistoryService()
    const exportCSVServiceHook = useExportCSVService()
    const { user, token } = useSelector((state) => state.auth)
    const [clinicId, setClinicId] = useState(useParams().id);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [data, setData] = useState();
    const [search, setSearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [totalEntries, setTotalEntries] = useState();
    const [totalPages, setTotalPages] = useState();
    const [dateRange, setDateRange] = useState()
    const [tabValue, setTabValue] = useState("credits_history")
    const [totalPurchased, setTotalPurchased] = useState()
    const [totalBonus, setTotalBonus] = useState()
    const [totalUsed, setTotalUsed] = useState()
    const [tabValueForExport, setTabValueForExport] = useState()
    const downloadReceiptHook = useDownloadReceipt()
    const downloadInvoiceHook = useDownloadInvoice()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (tabValue === "credits_history") {
            setTabValueForExport(tabValue)
            transactionServiceHook
                .history(pageNo, entriesPerPage, dateRange, clinicId)
                .then((result) => {
                    if (result && result?.meta && result?.payments) {
                        setData(result);
                        setLoading(false)
                        setTotalEntries(result?.meta?.total_count);
                        setTotalPages(result?.meta?.total_pages);
                        setTotalPurchased(result?.meta?.total_credits)
                    } else {
                        toast.error("Data is undefined or incomplete.");
                    }
                })
                .catch((error) => {
                    // Handle the error (e.g., display an error message)
                    toast.error("Error fetching user data and attributes:", error);
                });
        } else if (tabValue === "credits_used") {
            setTabValueForExport("used_credits")
            transactionServiceHook
                .credits_used(pageNo, entriesPerPage, search, dateRange, clinicId)
                .then((result) => {
                    if (result && result?.meta && result?.used_credits_list) {
                        setData(result);
                        setLoading(false)
                        setTotalEntries(result?.meta?.total_count);
                        setTotalPages(result?.meta?.total_pages);
                        setTotalUsed(result?.meta?.total_used_credits)
                    } else {
                        toast.error("Data is undefined or incomplete.");
                    }
                })
                .catch((error) => {
                    // Handle the error (e.g., display an error message)
                    toast.error("Error fetching user data and attributes:", error);
                });
        } else if (tabValue === "credits_bonus") {
            setTabValueForExport("bonus_credits")
            transactionServiceHook
                .credit_bonus(pageNo, entriesPerPage, dateRange, clinicId)
                .then((result) => {
                    if (result && result?.meta && result?.bonus_credits) {
                        setData(result);
                        setLoading(false)
                        setTotalEntries(result?.meta?.total_count);
                        setTotalPages(result?.meta?.total_pages);
                        setTotalBonus(result?.meta?.total_bonus_credits)
                    } else {
                        toast.error("Data is undefined or incomplete.");
                    }
                })
                .catch((error) => {
                    // Handle the error (e.g., display an error message)
                    toast.error("Error fetching user data and attributes:", error);
                });
        }
    }, [
        entriesPerPage,
        pageNo,
        search,
        dateRange,
        tabValue
    ]
    );

    useEffect(() => {
        setSearch("")
        setDateRange("")
    }, [
        tabValue
    ]
    );

    const handleDateRangeChange = (newDateRange) => {
        setDateRange(newDateRange);
    };

    const downloadFile = (url, toastMessage, fileName) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setTimeout(() => {
            setLoading(false);
            toast.success(toastMessage);
        }, 3000);
    };

    const handleReceiptDownload = (id) => {
        downloadReceiptHook
            .downloadReceipt(id)
            .then((result) => {
                setLoading(true)
                if (result) {
                    const url = result.receipt_url;
                    downloadFile(url, "Receipt downloaded successfully", "Receipt")
                } else {
                    toast.error('Error downloading receipt');
                }
            })
            .catch((error) => {
                toast.error('Error fetching user data and attributes:', error);
            });
    };

    const handleInvoiceDownload = (id) => {
        downloadInvoiceHook
            .downloadInvoice(id)
            .then((result) => {
                setLoading(true)
                if (result) {
                    const url = result.invoice_url;
                    downloadFile(url, "Invoice downloaded successfully", "Invoice")
                } else {
                    toast.error('Error downloading receipt');
                }
            })
            .catch((error) => {
                toast.error('Error fetching user data and attributes:', error);
            });
    };


    const handleExportCSV = () => {
        const pathname = `/administrator_apis/clinics/${clinicId}/${tabValueForExport}_export?date_range=${dateRange}`
        const filename = `admin_clinic_${clinicId}_${tabValueForExport}`
        exportCSVServiceHook.exportCSV(pathname, filename)
    };

    const customIcons = [

        {
            action: handleReceiptDownload,
            icon: <img src={ReceiptIcon} height={"20px"} alt="Download-Receipt" />,
            tooltipText: "Receipt"
        },
        {
            action: handleInvoiceDownload,
            icon: <img src={InvoiceIcon} height={"20px"} alt="Download-Invoice" />,
            tooltipText: "Invoice"
        },
    ];

    function formatString(inputString) {
        const words = inputString.split(' ');
        const formattedWords = words.map((word, index) => {
            if (index === 0) {
                return word.toLowerCase();
            } else {
                return word.charAt(0).toLowerCase() + word.slice(1);
            }
        });
        const formattedString = formattedWords.join('_');
        return formattedString;
    }

    const handleTabChange = (selectedTab) => {
        const tabValue = formatString(selectedTab)
        setTabValue(tabValue);
    };

    function amountColumn(value) {
        const parsedValue = parseFloat(value);

        if (!isNaN(parsedValue)) {
            const formattedValue = parsedValue.toFixed(2);
            return `€${formattedValue}`;
        } else {
            return value;
        }
    }

    const usedCreditsRowsData = data?.used_credits_list?.map((used_credit, index) => ({
        ...used_credit,
        name: formatName(used_credit.full_name),
        date: formatDate(used_credit.date)
    })) ?? [];
    const tableDataUsedCredits = {
        columns: [
            { Header: "patient", accessor: "name" },
            { Header: "registration date", accessor: "date" },
            // { Header: "time", accessor: "time" },
            { Header: "credits used", accessor: "used_credits" },
        ],
        rows: usedCreditsRowsData,
    };

    const bonusRowsData = data?.bonus_credits?.map((bonus, index) => ({
        ...bonus,
        date: formatDate(bonus?.date)

    })) ?? [];

    const tableDataBonus = {
        columns: [
            { Header: "date", accessor: "date" },
            { Header: "credits bonus", accessor: "credits" },
        ],
        rows: bonusRowsData,
    };

    const paymentsHistoryRowsData = data?.payments?.map((payment, index) => ({
        ...payment,
        amount: amountColumn(payment?.amount),
        date: formatDate(payment?.date),

        action: (
            <ActionMenu
                row={payment}
                customIcons={customIcons}
                key={index + 1}
            />
        ),
    })) ?? [];

    const tableDataHistory = {
        columns: [
            { Header: "date", accessor: "date" },
            { Header: "order number", accessor: "registration_id" },
            { Header: "package", accessor: "package_name" },
            { Header: "amount", accessor: "amount" },
            { Header: "credits purchased", accessor: "credits" },
            { Header: "free credits", accessor: "free_credits" },
            { Header: "action", accessor: "action" }
        ],
        rows: paymentsHistoryRowsData,
    };

    const getTableData = (tabValue) => {
        switch (tabValue) {
            case "credits_history":
                return tableDataHistory;
            case "credits_bonus":
                return tableDataBonus;
            case "credits_used":
                return tableDataUsedCredits;
        }
    };


    const getStatValues = (tabValue) => {
        switch (tabValue) {
            case "credits_history":
                return {
                    label: "Total Credits Purchase: ",
                    value: `${totalPurchased}`
                };
            case "credits_bonus":
                return {
                    label: "Total Credits Bonus: ",
                    value: `${totalBonus}`
                };
            case "credits_used":
                return {
                    label: "Total Credits Used: ",
                    value: `${totalUsed}`
                };
        }
    };


    return (
        <>
            {loading && <Spinner />}
            {!loading && (<DataTable
                canExportCSV={{
                    name: "Export as CSV",
                    action: handleExportCSV
                }}
                showEntriesPerPage={false}
                canSearch={tabValue === "credits_used" ? true : false}
                hasStatistics
                statValues={getStatValues(tabValue)}
                canFilter
                dateFilter
                onDateRangeChange={handleDateRangeChange}
                hasTabHeader={true}
                onTabChangeValue={handleTabChange}
                tabList={["Credits History", "Credits Bonus", "Credits Used"]}
                dataTableName={"Credits Used"}
                entriesPerPage={entriesPerPage}
                setSearch={setSearch}
                search={search}
                totalPages={totalPages}
                setEntriesPerPage={setEntriesPerPage}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalEntries={totalEntries}
                table={getTableData(tabValue)}
            />)}
        </>
    )
}

export default History